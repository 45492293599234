.otpContainer {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.otpInput {
  width: 40px;
  height: 50px;
  text-align: center;
  font-size: 24px;
  border: 2px solid #ccc;
  border-radius: 5px;
  outline: none;
  transition: border-color 0.2s;
}

.otpInput:focus {
  border-color: #007bff;
}
