.navigation {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  z-index: 14;
  width: 88px;
  height: 100%;
  background-color: var(--other-background-color);
  color: var(--text-color);
  overflow: hidden;
  -webkit-transition: width 0.3s;
  transition: width 0.3s;
  @media (min-width: 640px) {
    border-right: 1px solid rgba(216, 211, 219, 0.52);
  }

  @media (max-width: 640px) {
    flex-direction: row;
    width: 100%;
    padding: 0 8px;
    box-sizing: border-box;
    height: 65px;
    box-shadow: inset 0px 2px 0px 0px rgba(216, 211, 219, 0.52);
  }
  @media (min-width: 641px) {
    width: 100px;
    padding: 40px;
  }
}

.navigationBody {
  width: 100%;
  box-sizing: border-box;
  @media (max-width: 640px) {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }
}

.logoWrapper {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0 8px;
  fill: var(--grey-text-color)
}

.blockPanelImg {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  padding: 12px 0 20px;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  @media (max-width: 640px) {
    padding: 13px 0 0;
  }
}

.blockPanelSvg {
  width: inherit;
  height: inherit;
  vertical-align: middle;
}
.blockPanelTextMain {
  color: var(--grey-text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  font-weight: 300;
  font-size: 13px;
  font-family: Roboto, sans-serif;
  text-align: center;
  line-height: 16px;
  padding: 4px 8px 8px;
  transition: color 0.3s, background-color 0.3s;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}
.linkFirst {
  fill: var(--grey-text-color);
  color: #FFFFFF;
  color: rgba(255, 255, 255, 0.6);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 300;
  font-size: 13px;
  font-family: Roboto, sans-serif;
  text-align: center;
  line-height: 16px;
  -webkit-transition: color 0.3s, background-color 0.3s;
  transition: color 0.3s, background-color 0.3s;
  cursor: pointer;
  width: 100%;
  max-width: 16.5%;
  box-sizing: border-box;
  @media (max-width: 640px) {
    padding-top: 4px;
  }

}
.linkFirstText {
  position: relative;
  width: 45px;
  height: 45px;
  margin-bottom: 4px;
  cursor: pointer;
  color: var(--grey-text-color);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  font-size: 0.7rem;
  @media (min-width: 640px) {
    grid-gap: 20px;
    height: auto;
  }
}
.linkTwo {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 300;
  font-size: 13px;
  font-family: Roboto, sans-serif;
  text-align: center;
  line-height: 16px;
  padding: 12px 8px 8px;
  -webkit-transition: color 0.3s, background-color 0.3s;
  transition: color 0.3s, background-color 0.3s;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}
.linkTwoText {
  position: relative;
  width: 24px;
  height: 24px;
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-bottom: 4px;
  cursor: pointer;
}
.linkTree {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 300;
  font-size: 13px;
  font-family: Roboto, sans-serif;
  text-align: center;
  line-height: 16px;
  padding: 12px 8px 8px;
  -webkit-transition: color 0.3s, background-color 0.3s;
  transition: color 0.3s, background-color 0.3s;
  cursor: pointer;
  width: 100%;
  box-sizing: border-box;
}
.linkTreeTest {
  width: 32px;
  height: 32px;
  margin-bottom: 4px;
  cursor: pointer;
  position: relative;
  width: 24px;
  height: 24px;
  display: inline-block;
}

.navigateFooter {
  width: 100%;
  margin-top: auto;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  padding-bottom: 8px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  @media (max-width: 640px) {
    flex-direction: inherit;
    width: 50%;
  }
}
.navigateFooterLink {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: rgba(255, 255, 255, 0.6);
  font-weight: 300;
  font-size: 13px;
  font-family: Roboto, sans-serif;
  text-align: center;
  line-height: 16px;
  padding: 12px 8px 8px;
  -webkit-transition: color 0.3s, background-color 0.3s;
  transition: color 0.3s, background-color 0.3s;
  cursor: pointer;
  width: 100%;
}
.navigateFooterSpan {
  position: relative;
  width: 24px;
  height: 24px;
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-bottom: 4px;
  cursor: pointer;
}
.navigateFooterBlock {
  fill: var(--grey-text-color);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--grey-text-color);
  font-weight: 300;
  font-size: 13px;
  font-family: Roboto, sans-serif;
  text-align: center;
  -webkit-transition: color 0.3s, background-color 0.3s;
  transition: color 0.3s, background-color 0.3s;
  cursor: pointer;
  width: 100%;
  height: 45px;
  box-sizing: border-box;
}
.navigateFooterText {
  position: relative;
  width: 24px;
  height: 24px;
  display: inline-block;
  width: 32px;
  height: 32px;
  margin-bottom: 4px;
  cursor: pointer;
}

.linkFirst:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.linkTwo:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.linkTree:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.navigateFooterLink:hover {
  background-color: rgba(255, 255, 255, 0.2);
}
.navigateFooterBlock:hover {
  background-color: rgba(255, 255, 255, 0.2);
}


@media screen and (max-width: 830px) {
  .pageContent {
    height: calc(100vh - 123px);
  }
}

@media screen and (max-width: 640px) {
  .pageContent {
    height: calc(100vh - 110px);
  }
}
.activBtn {
  fill: #1b5e3b;
  color: #1b5e3b;
}