.mainConteiner {
  align-self: start;
  width: 100%;
  position: sticky;
  top: 6.375rem;
  height: calc(-10.375rem + 100vh);
}

.mainBlock {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.fakeBlock {
  position: absolute;
  top: 2.5rem;
  height: 3rem;
  width: 100%;
  background: linear-gradient(rgb(251, 251, 251) 25%, rgba(251, 251, 251, 0) 100%);
  pointer-events: none;
  z-index: 1;
  transition: opacity 150ms ease-in-out 0s;
}

.categoryBlock {
  position: relative;
  z-index: 0;
  height: 100%;
  padding-block-start: 4rem;
  box-sizing: border-box;
}

.categoryMain {
  width: 100%;
  min-height: 100%;
  overflow: visible;
  height: 1120px;
}

.categoryConteiner {

}

.downFakeBlock {
  position: absolute;
  bottom: 0;
  height: 3rem;
  width: 100%;
  background: linear-gradient(0deg, rgb(251, 251, 251) 25%, rgba(251, 251, 251, 0) 100%);
  pointer-events: none;
  z-index: 1;
  transition: opacity 150ms ease-in-out 0s;
}

@media screen and (min-width: 640px) {
  .categoryBlock {
    padding-block-start: 3.5rem;
    width: calc(100% + 11px + 0.5rem);
    padding-inline-end: 0.5rem;
    overflow-y: scroll;
    scrollbar-width: thin;
    scrollbar-color: var(--cb-color-border) transparent;
  }
}


