.textUpBlock {
  color: black;
  font-variant-ligatures: common-ligatures;
  font-size: 3.75rem;
  line-height: 4.75rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  width: 475px;
  margin: 40px 0;
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
}

.mainContainer {
  display: flex;
  flex-direction: column;
}

.btnContainer {
  display: flex;
}
.titleBlock{
  height: 16.75rem;
  position: relative;
  display: flex;
  align-items: flex-end;
}
.title{
  font-variant-ligatures: common-ligatures;
  font-size: 3.75rem;
  line-height: 4.75rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin: 0px 0px 1.5rem;
  bottom: 0px;
}


@media screen and (max-width: 639px) {
  .btnContainer {
    display: flex;
    margin-bottom: 42px;
  }
  .titleBlock{
    color: white;
  }
  .title {
    font-variant-ligatures: common-ligatures;
    font-size: 2.875rem;
    line-height: 3.5rem;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
  }
}