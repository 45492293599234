.lvl1 {
  max-width: 900px;
  padding: 1.1875rem 1rem;
  position: relative;
  @media (min-width: 641px) {
    max-width: initial;
  }
}
.lvl2 {

  position: relative;
}
.lvl3 {
  opacity: 1;
  padding: 0.4rem 0;
  border-bottom: 0.5px solid #f2f2f2;
}

.emptyTabState {

}

.content {
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.stateTitle {
  font-size: 1.5rem;
  font-weight: 700;
}

.description {
  color: var(--grey-text-color);
  font-size: 1.2rem;
}

.left {
  display: flex;
  font-size: 1.15rem;
  align-items: center;
  color: var(--order-text-color);
  font-weight: 500;
  width: 100%;
}
.titleName {
  font-size: 1.15rem;
  font-weight: 500;
  overflow-wrap: break-word;
  text-align: left;
}

.date {
  color: var(--order-grey-text-color);
  text-align: justify;
}
.middle {
  width: 33%;
  display: flex;
  align-items: center;
  color: var(--grey-text-color);
}
.right {
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--order-grey-text-color);
}

@media screen and (max-width: 641px) {
  .stateTitle {
    font-size: 1.3rem;
  }

  .description {
    margin-top: 1rem;
    font-size: 0.8rem;
  }
}

.goBackButton {
  display: none;
  position: fixed;
  width: 32px;
  height: 32px;
  z-index: 9999999;
  top: 1%;
  left: 3%;
}

@media screen and (max-width: 640px) {
  .goBackButton {
    display: block;
  }
}

.IconNextBlock {
  margin-left: 1rem;
  height: 100%;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.IconNext {
  rotate: (270deg);
  margin-top: 4px;
  fill: var(--order-grey-text-color);
}

.title {
  font-size: 1.5rem;
  font-weight: 700;
  margin-top: 1rem;
  position: relative;
  text-align: center;
}

