.container {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.videoWrapper {
  position: relative;
  width: 300px;
  height: 300px;
  border-radius: 50%; /* Круглая форма видео */
  overflow: hidden;
}

.video {
  width: 100%;
  height: 100%;
  object-fit: cover; /* Видео заполняет круг */
}

.controls {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
}

.recordButton, .stopButton, .restartButton {
  background-color: #6200ea; /* Основной цвет для кнопок */
  border: none;
  padding: 16px;
  color: white;
  font-size: 18px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease-in-out;
  width: 60vw;
  border-radius: 1rem;
  height: 50px;
  margin: 10px;
}

.recordButton:hover, .stopButton:hover, .restartButton:hover {
  background-color: #3700b3; /* Темнее при наведении */
  transform: translateY(-4px); /* Легкий подъем кнопки */
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15); /* Тень для эффекта */
}

.recordIcon, .stopIcon, .restartIcon {
  width: 30px; /* Увеличим иконки для лучшего визуального восприятия */
  height: 30px;
  background-color: white;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.75rem;
}

.recordIcon:before, .stopIcon:before, .restartIcon:before {
  content: "";
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background-color: #6200ea;
}

/* Для каждой кнопки может быть своя иконка */
.recordButton .recordIcon:before {
  background-color: #ff4081; /* Для записи используется розовый */
}

.stopButton .stopIcon:before {
  background-color: #e91e63; /* Для остановки используется красный */
}

.restartButton .restartIcon:before {
  background-color: #03dac6; /* Для перезаписи используем бирюзовый */
}

/* Добавляем анимацию для эффектов */
.recordButton, .stopButton, .restartButton {
  transition: all 0.3s ease-in-out;
}

.footerBtn {
  border: none;
  position: fixed;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: 92%;
  cursor: pointer;
  min-height: 3.375rem;
  border-radius: 0.5rem;
  color: var(--blue-btn-text-color);
  bottom: 1rem;
  box-sizing: border-box;
  background-color: var(--blue-btn-background-color);
}

.placeholderImage {
  width: 50%; /* Размер изображения */
  height: 50%; /* Размер изображения */
  border-radius: 50%; /* Круглая форма */
  object-fit: cover; /* Обрезка изображения, чтобы оно заполнило круг */
}

.imageWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  background: black;
  width: 100%; /* или по вашему усмотрению */
  height: 100%; /* или по вашему усмотрению */
}
