.contentMain {
  height: 100%;
  padding: 24px 24px 5px;
  overflow-x: auto;
  overflow-y: hidden;
  @media (max-width: 640px) {
    padding-left: inherit;
    display: flex;
    flex-direction: column;
    height: fit-content;
    padding: 0;
    overflow: auto;
  }
}
.contentMain {overflow: auto;}


.searchBlockMain {
  position: fixed;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: calc(100% - 64px);
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  background-color: #FFFFFF;
  margin-bottom: 16px;
  position: relative;
  top: 0;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  width: 100%;
  z-index: 1;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  @media (max-width: 640px) {
    margin-bottom: 0;
  }
}
.searchBlockContent {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  @media (max-width: 640px) {
    width: inherit;
  }
}
.searchBlock {
  display: grid;
  grid-template-columns: 1fr;
  background-color: rgba(225, 221, 227, 0.3);
  border-radius: 4px;
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  width: 100%;
  min-width: 260px;
  padding: 8px;
  width: 350px;
  border: 1px solid #e2e3e7;
  @media (max-width: 640px) {
    width: inherit;
  }
}
.searchConteiner {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-column: 1 / 3;
}
.searchConteinerText {
  position: relative;
  width: 24px;
  height: 24px;
  display: inline-block;
  margin-right: 6px;
}
.searchConteinerInput {
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  font-size: 16px;
  line-height: 18px;
  color: #0E1F40;
  padding: 0;
}
.createProductBtnBlock {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  justify-content: end;
  padding: 10px 0;
  background-color: rgba(225, 221, 227, 0.3);
  @media (max-width: 640px) {
    order: -1;
  }
}
.createCategoryBtn {
  font-family: Roboto, sans-serif;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 16px;
  text-align: center;
  background-color: transparent;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  color: #0E1F40;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  border-radius: 4px;
  padding: 8px 12px 8px 6px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
  line-height: 15px;
  color: #1b5e3b;
  padding-left: 17px;
  width: 100%;
  @media (max-width: 640px) {
    padding: 0;
    margin: 0;
  }
}
.createProductBtn {
  font-family: Roboto, sans-serif;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 16px;
  text-align: center;
  background-color: transparent;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  color: #0E1F40;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  position: relative;
  color: #FFFFFF;
  background: #1b5e3b;
  border-radius: 4px;
  padding: 11px 16px;
  margin-left: 16px;
  white-space: nowrap;
  border: 1px solid #1b5e3b;
  padding: 12px 16px;
}

//TABLE

.tableBodyMain {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  background: var(--background-color);
}

.tableBodyConteiner {
  position: relative;
  height: 100%;
  table-layout: fixed;
  width: 100%;
  height: calc(100% - 100px);
  -webkit-transition: height 0.3s;
  transition: height 0.3s;
  @media (max-width: 640px) {
    height: auto;
    min-height: 150px;
  }
}
.MuiDataGrid-cell {
  white-space: normal !important;
}


.button {
  width: 150px;
  height: 80px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  cursor: pointer;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  border-radius: 4px;
  background: rgba(18, 0, 30, 0.84);
  -webkit-box-shadow: 0 2px 12px 0 rgba(22, 0, 101, 0.55);
  box-shadow: 0 2px 12px 0 rgba(22, 0, 101, 0.55);
  padding: 8px 7px;
  &:hover {
    transform: scale(1.02) rotate(0.02deg);
  }

  p {
    color: #fff;

  }
}