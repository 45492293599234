.button-container {
  display: flex;
  justify-content: space-around;
  grid-gap: 10px;
  margin-top: 20px;
  @media (min-width: 641px) {
    width: 40%;
    justify-content: flex-start;
  }
}

.add-button {
  border: none;
  position: relative;
  z-index: 0;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: 100%;
  height: 40px;
  max-width: 60%;
  cursor: pointer;
  padding: 0px 1rem;
  border-radius: 0.5rem;
  background: var(--blue-btn-background-color);
  justify-content: center;
  color: #FFFFFF;

}
.removeButton {
  border: none;
  position: relative;
  z-index: 0;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: 100%;
  height: 40px;
  max-width: 60%;
  cursor: pointer;
  padding: 0px 1rem;
  border-radius: 0.5rem;
  background: red;
  justify-content: center;
  color: #FFFFFF;
}

.fieldInp {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  appearance: none;
  box-shadow: none;
  box-sizing: border-box;
  width: 100%;
  color: var(--text-color);
  transition: border 0.2s linear 0s;
  border-radius: 0.5rem;
  border: 2px solid var(--light-grey-color);
  padding: 0.75rem 0.875rem;
  background: transparent;

  @media (max-width: 640px) {
    line-height: 1rem;
    font-size: 0.7rem;
  }

}