.modalOverlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(0, 0, 0, 0.35);
  display: flex;
  justify-content: center;
  align-items: flex-end;
  z-index: 9999;

  @media (min-width: 641px) {
    align-items: center;
  }
}

.modalContent {
  width: 100%;
  max-width: 520px;
  background: var(--background-color);
  border-radius: 15px 15px 0 0;
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.2);
  display: flex;
  flex-direction: column;
  max-height: 90vh;
  overflow-y: auto;

  @media (min-width: 641px) {
    border-radius: 15px;
    margin: 20px;
    max-height: 80vh;
  }
}

.imageContainer {
  position: relative;
  aspect-ratio: 16/9;
}

.productImage {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 15px 15px 0 0;
}

.productInfo {
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}

.productTitle {
  font-size: clamp(1.5rem, 4vw, 2rem);
  font-weight: 700;
  margin: 0;
}

.productPrice {
  color: var(--primary-color);
  font-weight: 700;
  font-size: 1.25rem;
  margin: 0;
}

.productDescription {
  color: var(--text-secondary);
  margin: 0;
  line-height: 1.5;
}

.shopInfo {
  padding: 0 1rem;
  margin-bottom: 1rem;
}

.shopLabel {
  display: block;
  font-size: 0.875rem;
  color: var(--text-secondary);
  margin-bottom: 0.5rem;
}

.orderButton {
  margin: 0 1rem 1rem;
  padding: 1rem;
  background: var(--blue-btn-background-color);
  color: white;
  border: none;
  border-radius: 8px;
  font-weight: 700;
  font-size: 1rem;
  cursor: pointer;
  transition: background 0.2s;

  &:hover {
    background: var(--primary-dark);
  }
}