.mainConteiner {
  max-width: calc(1540px + 2* 30px);
  margin: 0 auto;
}

.mainBlock {
  margin: 0.5rem 30px 1rem;
}

.headerBlock {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  gap: 0.5rem;
  background-color: #1b5e3b14;
  border-radius: 0.5rem;
  margin-bottom: 21px;
}
.descriptionHeader {
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  margin: 0;
}

.headerBtnBlock {
  display: flex;
  gap: 1rem;
}

.headerBtn {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  color: #1b5e3b;
  background: none;
  border: none;
}

.mainMenu {

}