.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
  grid-gap: 16px;
}

.edit  {
  font-size: 16px;
  color:#1b5e3b;
  text-transform: none;
}
.title {
  padding-left: 1rem;
  font-size: 32px;
  line-height: 3.5rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin: 0;
  overflow-wrap: break-word;
  min-width: 0;
  display: flex;
  align-items: center;
}

.basket {
  z-index: 9999999;
  background-color: var(--dark-bold-grey-color);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.headerContent {
  z-index: 99999999;
  padding: 1rem 1rem 0.5rem 1rem;
  display: flex;
  align-items: center;
}

.mapBlock {
  width: 100%;
  transform: translateY(5px);
  z-index: 50;
  top: -5px;
  position: absolute;
}

.blockedBlock {
  position: absolute;
  height: 100%;
  width: 100%;
  opacity: 0;
  background-color: white;
  top: 0;
}

@media screen and (max-width: 640px) {
  .title {
    font-size: 1.5rem;
  }
}


.mainStyle {
  background-color: var(--background-color);;
  border-radius: 60px;
  height: 34px;
  width: 34px;
  position: absolute;
  top: 2%;
  left: 2%;
  z-index: 9999999;
  font-size: 1rem;
  font-weight: 700;
  color: var(--text-color);
}

.mainSvg {
  margin-top: 4px;
  fill: var(--text-color) !important;
  rotate: (90deg);
  height: auto;
  width: auto;
}

.blockContent {
  width: 100%;
  height: 60%;
  z-index: 9999999999;
  box-sizing: border-box;
  background-image: url("./gip.png");
  background-size: cover;
  position: relative;
  bottom: 0;
  &__dark {
    background-image: url("./gip-dark.png");
  }
}
.blockContent__dark {
  width: 100%;
  height: 60%;
  z-index: 9999999999;
  box-sizing: border-box;

  background-size: cover;
  position: relative;
  bottom: 0;
  background-image: url("./gip-dark.png");

}


.svgWave {
  display: block;
  width: 150%;
  height: 250px;
  position: absolute;
  bottom: 85%;
  z-index: 98;
}
.shapeFill {
  fill: white;
}


.back {
  z-index: 1;
}

.buttonsPanel {
  position: absolute;
  bottom: 45%;
  z-index: 9999999;
  display: flex;
  width: 100%;
  height: 3rem;
  padding: 0 2rem;
  justify-content: space-between;
  @media screen and (max-height: 935px) {
    bottom: 30%;
  }
  @media screen and (max-height: 750px) {
    bottom: 24%;
  }
  @media screen and (max-height: 700px) {
    bottom: 20%;
  }
}
.backGraoundBtn {
  background-color: var(--order-agine-btn-background);
  border-radius: 100%;
  width: 2.5rem;
  height: 2.5rem;

}
.text {
  max-width: 15rem;
  position: absolute;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}
.conteinerForText {
  display: flex;
  justify-content: center;
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  @media screen and (max-height: 950px) {
    bottom: 28%;
  }
  @media screen and (max-height: 750px) {
    bottom: 24%;
  }
  @media screen and (max-height: 700px) {
    bottom: 22%;
  }
}
.timer {
  font-weight: bold;
  font-size: 1.1rem;
  margin-top: 5px;
}