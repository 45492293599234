.contentMain {
  width: 100%;
  padding: 0;
  position: relative;
  overflow-x: hidden;
  height: 100%;
  background-color: var(--background-color);
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  z-index: 15;
  @media (max-width: 640px) {
    height: 100vh;
  }
}
.kill {
    display: none !important;
}
.menuPanelBar {
  display: grid;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  grid-auto-flow: column;
  grid-column-gap: 24px;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-transition: 0.3s;
  transition: 0.3s;
  max-height: 56px;
  -webkit-box-shadow: 0 1px 0 rgba(216, 211, 219, 0.52);
  box-shadow: 0 1px 0 rgba(216, 211, 219, 0.52);
  z-index: 15;
  background-color: var(--background-color);
  padding-left: 0.5rem;
}
.menuPanelBlock {
  display: grid;
  grid-auto-flow: column;
  grid-column-gap: 24px;
}
.menuPanelItem {
  pointer-events: auto;
  font-size: 16px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 24px;
  margin-top: 16px;
  padding-bottom: 13px;
  -webkit-transition: color 0.3s;
  transition: color 0.3s;
  white-space: nowrap;
  cursor: pointer;
}
.activePanel {
  color: #2990c3;
  border-bottom: 3px solid #2990c3;
}

.menuPanelItem:hover {
  color: #2990c3;
}

.curierBlock {
  display: flex;
  font-size: 1.3rem;
  font-weight: bold;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 88vw;
  color: #2990c3;
}
.ordersBlock {
  display: flex;
  font-size: 1.3rem;
  font-weight: bold;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 98vw;
  color: #2990c3;
}