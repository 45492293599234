.mainContainer {
  flex: 1 1 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainBlock {
  display: flex;
  width: 100%;
  justify-content: center;
  opacity: 1;
}

.fieldConteiner {
  position: relative;
  box-sizing: border-box;
  color: rgb(32, 33, 37);
  border-radius: 1.25rem;
  height: 2.5rem;
  backdrop-filter: none;
  width: 260px;
}

.lengthBlock {
  position: absolute;
  inset: 0;
  background: #fff;
  border-radius: 1.25rem;
}

.inputField {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  position: relative;
  display: block;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-inline: 3rem;
  box-sizing: border-box;
  border-radius: 1.25rem;
  color: inherit;
  height: 2.5rem;
  width: 100%;
  max-width: 100%;
  transition: background-color 150ms linear 0s;
  caret-color: rgba(32, 33, 37, 0.09);
  background: rgba(32, 33, 37, 0.05);
  border: none;
  outline-color: rgba(32, 33, 37, 0.12);
}
.inputField:focus {
  box-shadow: 2px 2px 2px rgba(34, 60, 80, 0.1);
  outline:none;
}
.inputField::placeholder {
  color: #707174;
  font-size: .8em;
  font-weight: 450;
}

.lengthBlockDark {
  position: absolute;
  inset: 0;
  background: var(--dark-bold-grey-color);
  border-radius: 1.25rem;
}

.inputFieldDark {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.25rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  position: relative;
  display: block;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-inline: 3rem;
  box-sizing: border-box;
  border-radius: 1.25rem;
  color: #f0f0f0;
  height: 2.5rem;
  width: 100%;
  max-width: 100%;
  transition: background-color 150ms linear 0s;
  caret-color: var(--text-color);
  box-shadow: transparent 0 0 0 3px;
  background: var(--dark-bold-grey-color);
  background-color: var(--dark-bold-grey-color);
  border: none;
  outline-color: var(--dark-bold-grey-color);
}


.fantomBlock {
  position: absolute;
  inset-inline-end: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 2rem;
  height: 2rem;
}

.fantomBtn {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: inherit;
  transition: transform 100ms ease-in 0s;
  transform: scale(0);
}

.fantomImg {
  width: 1rem;
  fill: currentcolor;
}

.findImg {
  color: #707174;
  width: 1rem;
  position: absolute;
  inset-inline-start: 1rem;
  top: 50%;
  transform: translateY(-50%);
  fill: currentcolor;
  transition: color 150ms linear 0s;
}
.arrowImg {
  position: absolute;
  top: 70%;
  left: 0.5rem;
  transform: translateY(-50%);
  fill: none;
  opacity: 0.7;
  transition: color 150ms linear 0s;
  @media (min-width: 640px) {
    top: 50%;
  }
}
.findImgWhite {
  color: white !important;
}

.backUpHeaderInput {
  background: rgba(242, 242, 242, 0.24);
  backdrop-filter: blur(5px);
}
.backUpHeaderInput::placeholder {
  color: var(--dark-bold-grey-color);
}

.clearText {
  z-index: 9999999;
  background-color: rgba(142, 142, 142, 0.8);
  color: white;
  width: 1.56rem;
  height: 1.56rem;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  inset-inline-end: 1rem;
  font-size: 1.2rem;
  top: 69.5%;
  transform: translateY(-50%);
  cursor: pointer;
  @media (min-width: 641px) {
    top: 50%;
  }
}
.closeBt {
  padding-bottom: 1px;
}

@media screen and (max-width: 639px) {
  .inputField, .inputFieldDark {
    width: 100%;
    height: 45px;
  }
  .fieldConteiner {
    width: 100%;
    height: 32px;
  }
  .findImg {
    // left: 10px;
    width: 1.25rem;
    height: 1.25rem;
    top: 75%;
  }
}

.backIcon {
  background-color: rgba(142, 142, 142, 0.25);
  border-radius: 50%;
  width: 3.2rem;
  height: 3rem;
  margin-right: 0.5rem;
}