.mainStyle {
  background-color:var(--dark-grey-color);
  border-radius: 60px;
  height: 34px;
  width: 34px;
}
.mainSvg {
  margin-top: 4px;
  fill: var(--text-color) !important;
  rotate: (90deg);
  height: auto;
  width: auto;
}
