.mainConteiner {
  box-sizing: border-box;
  height: 360px;
}

@media screen and (max-width: 1024px) {
  .mainConteiner {
    height: 40vw;
  }
}
@media screen and (max-width: 641px) {
  .mainConteiner {
    height: 220px;
  }
  .slick-dots {
    display: flex;
  }
  .customDots li {
    margin: 0 5px;
  }

  .customDots li button {
    border-radius: 50%;
    width: 20px;
    height: 20px;
    padding: 0;
    cursor: pointer;
    border: 2px solid #000;
    background-color: #fff;
    outline: none;
  }

  .customDots li.slick-active button {
    background-color: #000;
    border-color: #000;
    color: #fff;
  }
}