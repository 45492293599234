.mainConteiner {
  display: block;
  text-align: start;
  border-radius: 0.5rem;
  background: rgba(0, 157, 224, 0.075);
  color: rgb(32, 33, 37);
  cursor: pointer;
}

.mainBlock {
  display: flex;
  position: relative;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  overflow: hidden;
}

.upBlock {
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex: 1 1 0;
  padding: 0.8125rem 1rem 1rem;
}

.downBlock {
  width: 5rem;
  height: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: end;
}

.description {
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}

.fakeBlock {
  width: 7.5rem;
  height: 7.5rem;
  position: absolute;
  inset-inline-start: 0;
  inset-block-start: 50%;
  transform: translate(0px, -50%);
  border-radius: 50%;
  fill: #1b5e3b14;
  box-shadow: rgba(0, 157, 224, 0.16) 0 0.25rem 2.5rem 0 inset;
}

.img {
  width: 4.5rem;
  height: 4.5rem;
  object-fit: contain;
}

@media screen and (min-width: 840px) {
  .mainConteiner {
    width: 380px !important;
  }
}

@media screen and (max-width: 640px) {
  .description {
    font-size: 0.7rem;
  }
}

.maxSize {
  margin-top: 2rem;
  height: 6.5rem;
  width: 100%;
}

.maxSizeText {
  font-size: 1rem;
  font-weight: 500;
}
