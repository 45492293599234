.mainConteiner {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
  transition: background 100ms linear 0s, color 100ms linear 0s;
  background: rgba(225, 225, 225, 0.3);
  fill: #fff;
  height: 40px;
  width: 40px;
  box-sizing: border-box;
  margin-right: 0.8rem;
  &__scrolled {
    background-color: rgba(225,225,225,0.3);
    fill: #fff;
  }
}