.mainBlock {
  margin-top: 5px;
}

@media screen and (max-width: 639px) {
  .mainBlock {
    background: white;
    padding: 20px 0;
    padding-top: 0;
    border-radius: 10px;
    margin-top: 0;
    //animation: waveIn 1s ease-out forwards;
    //transform: translateY(100%);
    //@keyframes waveIn {
    //  0% {
    //    transform: translateY(100%);
    //  }
    //  100% {
    //    transform: translateY(0);
    //  }
    //}
  }
}
.titlePage {
  font-size: 1.7rem;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  position: static;
}
.chats {
  height: 100%;
  padding-top: 0.5rem;
  width: 100%;
}
@media screen and (min-width: 1024px) {
  .chats {
    display: flex;
    flex-direction: column;
    justify-content: end;
    align-items: end;
  }
}