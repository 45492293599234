.mainPage {
  display: block;
  unicode-bidi: isolate;
  flex: 1 1 0;
  z-index: 0;
}

.firstBlock {
  z-index: 3;
  position: absolute;
}

.secondBlock {
  display: none;
}

.treeBlock {
  display: none;
}

.mainBlock {
  min-height: calc(100vh - 70px);
  position: relative;
  width: 100%;
}

.upBlock {
  width: 100%;
  background: #2021250a;
}

.downBlock {
  display: grid;
  display: -ms-grid;
  grid-template-columns: 50% 15% 35%;
  -ms-grid-columns: 50% 15% 35%;
  margin: 0 auto 11rem;
  max-width: 1200px;
  padding: 6rem 30px 0;
}

.upMainConteiner {
  height: clamp(320px, 20vh, 620px);
  position: relative;
  z-index: 0;
  width: 100%;
}

.upMainBlock {
  direction: ltr;
  overflow: hidden;
  -webkit-tap-highlight-color: transparent;
  outline-offset: 1px;
  font-family: Helvetica Neue, Arial, Helvetica, sans-serif;
  font-size: 12px;
  font-size: .75rem;
  line-height: 1.5;
  position: relative;
  touch-action: pan-x pan-y;
  background: #20212514;
  height: 100%;
  width: 100%;
}

.downMainBlock {
  pointer-events: none;
  position: absolute;
  top: 0;
  z-index: 500;
  height: 100%;
  width: 100%;
}

.downContentBlock {
  color: #202125;
  display: flex;
  flex-direction: column;
  height: 100%;
  justify-content: flex-end;
  margin: auto;
  max-width: 1200px;
  padding: 0 1.5rem 6rem;
  position: relative;
  box-sizing: border-box;
}

.downContentBtn {
  align-items: center;
  color: #202125;
  cursor: pointer;
  display: flex;
  gap: 1rem;
  left: 1.5rem;
  margin-left: .5rem;
  margin-right: 1.5rem;
  pointer-events: all;
  position: absolute;
  top: 2rem;
  z-index: 1000;
  direction: ltr;
}

.downContentTextBlock {
  transition: opacity .15s ease-in-out;
  color: var(--text-color);
}

.backBtnBlock {
  background: 0 0;
  border: none;
  border-radius: 50%;
  box-shadow: none;
  display: inline-flex;
  flex-shrink: 0;
  padding: 0;
  position: relative;
  z-index: 0;
  --bg: #20212514;
  --bgHover: #20212529;
  --bgActive: #2021253d;
  --bgDisabled: #2021250a;
  --iconFill: #202125;
  --iconFillHover: #202125;
  --iconFillDisabled: #20212566;
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
}

.backBtnFake {
  background-color: #20212514;
  border-radius: 50%;
  inset: 0;
  position: absolute;
  transition: background-color .12s ease-out;
}

.backBtnImgBlock {
  align-items: center;
  border-radius: 50%;
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;
  z-index: 1;
}

.backBtnImg {
  fill: #202125;
  height: 1.25rem;
  width: 1.25rem;
}

.backTextBtnBlock {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
}

.titleUpBlock {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-variant-ligatures: common-ligatures;
  font-size: 3.75rem;
  line-height: 4.75rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  max-width: 50%;
  font-feature-settings: "kern", "ss01", "ss05", "ss07";
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
}

.titleUpBlockText {

}

.downTitleUpBlock {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-variant-ligatures: common-ligatures;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-feature-settings: "kern", "ss01", "ss05", "ss07";
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  color: var(--text-color);
}

@media screen and (max-width: 1023px) {
  .downBlock {
    display: block;
    color: var(--text-color);
  }
}
