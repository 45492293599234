.mainConteiner {
  height: 100%;
  padding: 1rem 0.5rem;
  overflow-y: hidden;
}

.profitBlock {
  height: 110px;
  width: 100%;
  border: 1px solid rgba(169, 169, 169, 0.2);
  border-radius: 5px;
  padding: 1rem;
}
.profitBlock2 {
  margin-top: 1rem;
  height: 110px;
  width: 100%;
  border: 1px solid var(--dark-bold-grey-color);
  border-radius: 5px;
  padding: 1rem;
}

.topProfitBlock {
  display: flex;
  justify-content: space-between;
  height: 50%;
}
.downProfitBlock {
  display: flex;
  justify-content: space-between;
  height: 50%;
  align-items: end;
}
.downProfitBlock2 {
  display: flex;
  justify-content: space-between;
  height: 50%;
  align-items: end;
  flex-direction: column;
}
.leftContTop {
  font-weight: bold;
  font-size: 1.1rem;
}
.rightContTop {
  font-weight: bold;
  font-size: 1.3rem;
}
.leftContDown {
  font-size: 0.8rem;
  color: var(--grey-text-color);
}
.rightContDown {
  font-size: 0.8rem;
  color: var(--grey-text-color);
}
.row {
  font-size: 0.8rem;
  display: flex;
  width: 100%;
  justify-content: space-between;
}
.tableStats {
  position: relative;
  height: 83%;
  overflow: auto;
  margin-bottom: 4rem;
}
.headerTable {
  display: flex;
  padding: 0 1rem;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  position: fixed;
  height: 2rem;
  width: 95%;
  background-color: var(--background-color);
  margin-bottom: 3px;
}
.fantomCont {
  height: 1rem;
  background-color: var(--background-color);
  margin-bottom: 1rem;
}
.headBlock1 {
  font-weight: bold;
  width: 40%;
}
.headBlock2 {
  font-weight: bold;
  width: 50%;
}
.headBlock3 {
  font-weight: bold;
  width: 30%;
  padding-inline-start: 2rem;
}
.navigateHeader {
  border-bottom: 1px solid var(--dark-bold-grey-color);
  height: 2.5rem;
  display: flex;
  margin-bottom: 0.5rem;
  justify-content: space-between;
  align-items: flex-start;
}
.mainSvg {
  margin-top: 3px;
  height: 24px;
  width: 24px;
  fill: var(--text-color);
}
.shopName {
  font-weight: bold;
  font-size: 1.3rem;
  width: 100%;
  display: flex;
  justify-content: center;
}
.phaseFant {
  background-color: var(--background-color);
  height: 1rem;
  width: 100%;
  z-index: 9999999 !important;
}