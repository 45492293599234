.wrapper {
  margin-top: 5rem;
  padding: 1rem;

}

@media screen and (max-width: 640px) {
  .backer {
    position: fixed;
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0 0 1rem;
  }
}

.title {
  font-size: 1.5rem;
  font-weight: 300;
  color: var(--text-color);
  margin-bottom: 1rem;
}

.subTitle {
  color: var(--blue-color);
  font-size: 1.5rem;
  font-weight: 300;
}
.shopName {
  font-size: 1.4rem;
  font-weight: 300;
  color: var(--text-color);
  margin-bottom: 3rem;
}

.orderDetails {
  font-size: 1.1rem;
  font-weight: 300;
  color: var(--order-grey-text-color);
  margin-bottom: 1rem;
}

.orderDetailsDescription {
  font-size: 0.8rem;
  font-weight: 300;
  color: var(--order-grey-text-color);
  margin-left: 1rem;
}

.orderDetailsValue {
  font-size: 1.1rem;
  font-weight: 500;
  color: var(--text-color);
  margin-left: 1rem;
}

.orderProduct {
  padding: 1rem 1rem;
  background-color: rgba(213,223,232,0.4);
  display: flex;
  justify-content: space-between;
  margin-bottom: 2rem;
}

.orderProductValue {
  font-size: 0.7rem;
  font-weight: 300;
  color: var(--text-color);
  margin-left: 1rem;
}

.titleDelivery {
  margin-left: 1rem;
  font-size: 1rem;
  font-weight: 300;
  color: var(--text-color);
  margin-bottom: 2rem;
}

.supportButton {
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue-color);
  grid-gap: 1rem;
  background-color: rgba(213,223,232,0.4);
  border-radius: 0.5rem;
  padding: 0.3rem 0.5rem;
  margin-right: 1rem;
}