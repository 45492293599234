.mainConteiner {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  background-color: var(--other-background-color);
  box-shadow: rgba(0, 0, 0, 0.06) 0 0 0.125rem 0, rgba(0, 0, 0, 0.12) 0 0.125rem 0.125rem 0;
  transition: box-shadow 0.3s cubic-bezier(0.45, 0, 0.55, 1) 0s, background 100ms ease-out 0s;
  transform: scale(1) rotate(0.02deg);
}

.mainConteiner:hover {
  transform: scale(1.02) rotate(0.02deg);
}

.headerBlcok {
  position: relative;
  z-index: 0;
}

.mainBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  padding: 0.5rem 0.5rem 0.75rem 0.5rem;
  gap: 1rem;
}

.footerBlock {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  display: flex;
  align-items: center;
  height: 2rem;
  color: var(--cb-color-text-subdued);
  padding: 0px 0.5rem;
}

.imgBlock {
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 0.46;
  transform: scale(1) rotate(0.02deg);
}

.imgBlock:hover {
  transform: scale(1.02) rotate(0.02deg);
}

.imgConteiner {
  width: 100%;
  height: 100%;
  position: relative;
}

.img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
  border-style: none;
}

.leftConteiner {
  display: flex;
  min-width: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.rightConteiner {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkConteiner {
  display: block;
  color: #1b5e3b;
  line-height: 1.4;
  text-decoration: none;
}

.linkTitle {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 1rem !important;
  line-height: 1.5rem !important;
  font-style: normal;
  font-stretch: 100%;
  text-transform: none;
  font-weight: 500;
  text-align: start;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--text-color);
}

.linkTitle:after {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  box-sizing: inherit;
}

.description {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  margin: 0;
  color: #1b5e3b;
  text-align: start;
  text-overflow: ellipsis;
  overflow: hidden;
}

.upBlock {
  display: flex;
  min-width: 0px;
  gap: 0.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.downBlock {
  display: flex;
  min-height: 1.25rem;
}

.rightBoxCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  min-height: 2.5rem;
  min-width: 3.75rem;
  background-color: var(--grey-delivery-background-color);
  border-radius: 0.5rem;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.5rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: #1b5e3b;
}

.rightBoxContUp {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  width: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  color: inherit;
  font-weight: 500;
}

.rightBoxContDown {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  width: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  color: inherit;
}

.footerContentBlock {
  white-space: nowrap;
  display: flex;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
}

.footerTextBlock {
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
}

.textCost {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  overflow: hidden;
  text-overflow: ellipsis;
  font-size: 0.9rem;
  color: var(--grey-text-color);;
}

.textImg {
  min-width: 0.75rem;
  min-height: 0.75rem;
  width: 0.9rem;
  height: 0.9rem;
  margin-inline-end: 0.25rem;
  fill: var(--green-blue-color);
}
.footerTextBlockN {
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
}
.footerTextBlockN:before {
  content: "·";
  position: relative;
  display: inline-block;
  margin: 0 0.25rem;
  white-space: nowrap;
}

.footerTextBlockNContent {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.75rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  white-space: nowrap;
}

.baksImg {
  color: rgba(32, 33, 37, 0.15);
  font-size: 0.875rem;
}

.footerTextBlockR {
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.footerTextBlockR:before {
  content: "·";
  position: relative;
  display: inline-block;
  margin: 0 0.25rem;
  white-space: nowrap;
}
.headerTextBlock {
  position: absolute;
  left: 1rem;
  right: 1rem;
  top: 1rem;
  display: flex;
  flex-wrap: wrap;
  z-index: 3;
}

.smileImg {
  min-width: 0.75rem;
  min-height: 0.75rem;
  width: 0.9rem;
  height: 0.9rem;
  margin-inline-end: 0.25rem;
  fill: #202125a3;
}
.points {
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--grey-text-color);
  font-size: 0.9rem;
}

@media screen and (max-width: 640px) {
  .linkTitle {
    font-size: 0.9rem;
    line-height: 1.2rem;
    font-weight: 500;
  }
  .rightBoxCont {
    height: 36px;
    width: 48px;
    box-sizing: border-box;
  }
  .rightBoxContUp {
    font-size: 0.875rem;
    line-height: 1.2rem;
  }
  .description {
    font-size: 0.9rem;
    line-height: 1.2rem;
  }
  .footerBlock {
  }
}
