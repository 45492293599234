.addButton {
  font-weight: 600;
  font-size: 1.2rem;
  border: 1px solid #1b5e3b;
  border-radius: 10px;
  padding: 7px;
  margin: 4px;
  background-color: #1b5e3b;
  color: white;
}
