/* ProfileCard.module.css */

.profileCard {
  margin: 1rem auto;
  max-width: 900px;
  padding: 2.1875rem 0;
  position: relative;
}

.container {
  padding: 1.25rem 0;
  position: relative;
}

.mainBlock {
  margin-left: 30px;
  margin-right: 30px;
}

.imageContainer {
  display: flex;
  flex-direction: row;
}

.imageWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.profileImage {
  background: #fff;
  border: 4px solid #fff;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
  height: 80px;
  margin-bottom: .3rem;
  overflow: hidden;
  width: 80px;
}

.avatar {
  background-size: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}
.uploadInput {
  height: .1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  width: .1px;
  z-index: -1;
}

.uploadLabel {
  color: var(--cb-color-text-brand);
  cursor: pointer;
  font-size: 1.07rem;
  padding: .5rem 1rem;
}

.description {
  color: #1b5e3b;
}

.userInfo {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-left: 3rem;
  margin-inline-end: 0;
  margin-inline-start: 3rem;
}

.userName {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.userNameText {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin: 0px;
  @media (min-width: 640px) {
    font-weight: 500;
  }
}

.link {

  width: fit-content;
  font-size: 0.85rem;
  font-weight: bold;

  background: var(--blue-btn-background-color);
  padding: 20px;
  z-index: 1;
  border-radius: 10px;
  span{
    color: var(--background-color);
  }
}

.userDetails {
  display: flex;
  flex-direction: row;
  margin-top: 1.5rem;
}

.detail {
  margin-right: 4rem;
  margin-inline-end: 4rem;
  margin-inline-start: 0;
}

.upTitle {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  margin: 0;
  @media (min-width: 640px) {
    font-weight: 500;
  }
}

.upDescription {
  padding-top: .1rem;
  font-size: 1.07rem;
  @media (min-width: 640px) {
    font-weight: 400;
  }
}

.downTitle {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  margin: 0;
  @media (min-width: 640px) {
    font-weight: 500;
  }
}

.downDescription {
  padding-top: .1rem;
  font-size: 1.07rem;
  @media (min-width: 640px) {
    font-weight: 400;
  }
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 3rem;
}

.downConteiner {
  overflow: hidden;
}

.downBlock {
  margin-bottom: 2rem;
  margin-top: 2rem;
  overflow: hidden;
}

.downMainBlock {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.boxMain {
  flex: 3;
}

.downText {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  color: var(--text-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.downTextTwo {
  color: var(--grey-text-color)
}

.iconBlock {
  display: flex;
  flex: 2;
  justify-content: center;
  max-width: 164px;
  text-align: right;
}

@media screen and (max-width: 640px) {
  .downBlock {
    margin: 2rem 30px 0;
  }
  .iconBlock {

  }

  .userDetails {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  .detail {
    box-sizing: border-box;
    margin-right: 0rem;
    margin-bottom: 1rem;
  }

  .cardContainer {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
  }

  .downMainBlock {
    display: flex;
    flex-direction: column;;
  }
}

