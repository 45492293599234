.mainContainer {
  width: 100vw;
  inset: 0;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 2;
}

.mainBlock {
  transform: translateY(0rem) translateZ(0px);
}

.container {
  position: relative;
  z-index: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  height: 669px;
  transition: height 500ms cubic-bezier(0.33, 1, 0.68, 1) 0s;
}

@media screen and (min-width: 640px) {
  .mainBlock {
    border-start-start-radius: 1rem;
    border-start-end-radius: 1rem;
    border-end-start-radius: 1rem;
    border-end-end-radius: 1rem;
    max-height: min(50rem, -24px + 100svh);
  }
  .container {
    border-start-start-radius: 1rem;
    border-start-end-radius: 1rem;
    border-end-start-radius: 1rem;
    border-end-end-radius: 1rem;
    max-height: min(50rem, -24px + 100svh);
  }
}

.closeBtnConteiner {
  z-index: 100;
  position: absolute;
  top: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  inset-inline-end: 1rem;
}

.buttonBlock {
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
}

.fakeBtnBlock {
  background-color: var(--background-color);
  border-radius: 50%;
  inset: 0;
  position: absolute;
  transition: background-color .12s ease-out;
}

.closeImgBtn {
  align-items: center;
  border-radius: 50%;
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;
  z-index: 1;
}

.closeImg {
  height: 1.25rem;
  width: 1.25rem;
  fill: var(--dark-bold-grey-color);
}

.block {
  position: relative;
  z-index: 0;
  overflow-y: hidden;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  opacity: 1;
  transform: translateX(0%) translateZ(0px);
}

.blockHeader {
  z-index: 20;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 4.5rem;
  transition: box-shadow 120ms ease-in 0s, background-color 120ms ease-in 0s;
}

.downBlock {
  position: relative;
  z-index: 1;
  flex: 1 1 0;
  padding-top: 4.5rem;
  overflow-y: hidden;
  overflow: hidden !important;
  overflow-anchor: none;
  -ms-overflow-style: none;
  touch-action: auto;
  -ms-touch-action: auto;
  @media (max-width: 640px) {
    padding-top: 2.5rem;
  }
}

.parentBlock {
  padding: 1rem;
}

.contentBlock {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}

.upB {
  margin-bottom: 1.5rem;
}

.upD {

}

.title {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;

  @media (max-width: 640px) {
    font-size: 1.4rem;
  }
}

.description {
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
}

.info {
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: var(--grey-text-color)
}

.formaBlock {
  margin: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
}

.formaBlock > :not(:first-child) {
  margin-top: 0.5rem;
}

.submitBtn {
  border: none;
  position: relative;
  z-index: 0;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: auto;
  max-width: 100%;
  cursor: pointer;
  padding: 0px 1rem;
  min-height: 3.375rem;
  border-radius: 0.5rem;
  color: var(--blue-btn-text-color);
  @media (max-width: 640px) {
    font-size: 0.7rem;
    line-height: 1rem;
    min-height: 2.5rem;
  }
}

.fakeSub {
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
  transition: background-color 120ms ease-out 0s;
  background: var(--blue-btn-background-color);
}

.fakeSubTwo {
  position: absolute;
  inset: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textSub {
  flex: 1 1 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 150ms ease-in 0s, color 150ms linear 0s;
  border-radius: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

.fieldEmail {

}

.fieldCont {
  position: relative;
  width: 100%;
}

.fieldInp {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  appearance: none;
  box-shadow: none;
  box-sizing: border-box;
  width: 100%;
  color: var(--text-color);
  transition: border 0.2s linear 0s;
  border-radius: 0.5rem;
  border: 2px solid var(--dark-grey-color);
  padding: 0.75rem 0.875rem;
  background: var(--background-color);
  @media (max-width: 640px) {
    line-height: 1rem;
    font-size: 0.7rem;
  }
  outline: none;
}

.lable {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: var(--grey-text-color);
  position: absolute;
  top: 0.875rem;
  width: auto;
  right: 1rem;
  left: 1rem;
  pointer-events: none;
  transition: transform 0.15s ease-in-out 0s;
  transform-origin: left center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  @media (max-width: 640px) {
    line-height: 1rem;
    font-size: 0.7rem;
  }
}

@media screen and (min-width: 640px) {
  .mainBlock {
    --container-border-start-start-radius: 1rem;
    --container-border-start-end-radius: 1rem;
    --container-border-end-start-radius: 1rem;
    --container-border-end-end-radius: 1rem;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 1rem 0px, rgba(0, 0, 0, 0.12) 0px 1rem 1rem 0px;
    position: static;
    flex: 1 1 0;
    max-width: min(31.25rem, 90vw);
  }
}

@media screen and (max-width: 640px) {
  .container {
    top: 3rem;
  }
  .closeBtnConteiner {
    display: none;
  }
  .mainContainer{
    height: 100vh;
  }
  .mainBlock{
    height: 100vh;
  }
  .blockHeader {
    display: none;
  }
  .downBlock {
    padding-top: 0;
  }
}

.active {
  background-color: var(--background-color);
  font-weight: bold;
  border-radius: 12.5rem;
  color: var(--text-color);
}

.tabPanel.active {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  padding-top: 80px;
  color: black;
  @media (max-width: 850px) {
    padding-top: 5px;
  }
}


.tabsContainer {
  border-radius: 12.5rem;
  width: auto;
  background-color: var(--dark-grey-color);
  display: flex;
  justify-content: center;
  padding: 0.25rem;
  height: 3rem;
  margin: 0.1875rem 1rem 1rem;
  position: relative;
  z-index: 22;
  @media screen and (max-width: 640px) {
    height: 2.4rem;
    padding: 0.15rem;
  }
}

.tabs {
  display: flex;
  @media (max-width: 640px) {
    width: 100%;
    justify-content: space-between;
  }
}
.tabButton {
  width: 254px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
  color: darkgrey;
  @media (max-width: 640px) {
    width: 50%;
    padding: 5px;
    text-decoration: none;
  }
}

.tabButton:hover {
  text-decoration: underline;
  @media (max-width: 640px) {
    text-decoration: none;
  }
}
.active {
  background-color: var(--background-color);
  color: var(--text-color);
  font-weight: bold;
  border-radius: 12.5rem;
}

.initPass {
  color: #1b5e3b;
  display: flex;
  margin: 0.5rem 0;
}
