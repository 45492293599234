.mainBlock {
  width: min-content;
  padding-bottom: 0.5rem;
}
.filter {
  height: 1px;
  margin: -1px;
  width: 1px;
  position: absolute;
  border: 0;
  padding: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  white-space: nowrap;
  overflow: hidden;
}
.label {
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.9rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  font-weight: 500;
  background: #1b5e3b;
  padding: 0.5rem 1rem;
  border-radius: 1rem;
  color: white;
  transition: background 100ms linear 0s, color 100ms linear 0s, outline 100ms linear 0s;
  text-transform: capitalize;
  white-space: nowrap;
  cursor: pointer;
  width: 100%;
}