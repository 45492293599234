.mainContainer {
  min-height: calc(-4.375rem + 100vh);
  width: 75rem;
  margin: 3rem auto 0;
  background-color: var(--background-color);
  color: var(--text-color);

  @media (max-width: 1200px) {
    width: auto;
  }

  @media (max-width: 1020px) {
    width: auto;
    margin: 0;
  }
  @media (max-width: 720px) {
    height: auto;
  }
  @media (min-width: 640px) {
    width: 94rem;
  }

}