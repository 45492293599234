@import '../../styles/variables';


.mainContBlock {
}


.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 99999999;
  bottom: 0;
}

.modal-content {
  box-sizing: border-box;
  min-width: 520px;
  max-height: 570px;
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  display: flex;
  flex-direction: column;

  @media (max-width: 640px) {
    min-width: unset;
    width: 100%;
    max-height: 99%;
    background-color: var(--background-color);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 0.75rem 0px, rgba(0, 0, 0, 0.12) 0px 0.75rem 0.75rem 0px;
    position: absolute;
    height: fit-content;
    padding: 10px 10px;
    margin:0;
    border-radius: 10px 10px 0 0;
    bottom: 1rem;
    padding-top: 0;
  }
  @media (max-width: 640px) {
    height: 100%;
    animation-name: slideUp;
    animation-duration: 1s;
    animation-fill-mode: forwards; /* Сохранение конечного состояния после анимации */
    @keyframes slideUp {
      from {top: 150%; opacity: 1;}
      to {top: 1%; opacity: 1;}
    }
  }
}

.closeImgBtn {
  align-items: center;
  border-radius: 0.25rem;
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;
  z-index: 1;
  width: 3.5rem;
  background-color: #61dafb;
  color: #1b5e3b;
}

.closeImg {
  height: 1.25rem;
  width: 1.25rem;
  fill: #202125;
}

::-webkit-scrollbar {
  width: 0px;
  transition: background-color .2s linear, width .2s ease-in-out;
  -webkit-transition: background-color .2s linear, width .2s ease-in-out;
}

::-webkit-scrollbar-thumb {
  background-color: #1b5e3be0;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.button {
  color: var(--background-color);
  font-size: 14px;
  @media (max-width: 850px) {
    font-size: 10.5px;
  }
}

.close-button {
  text-align: end;
  position: relative;
}

.header {
  margin-top: 10px;
  padding: 16px;
  font-size: 40px;
  color: var(--text-color);
  @media (max-width: 850px) {
    font-size: 24px;
    padding: 12px;
  }
}

ul {
  list-style: none;
  padding: 0;
}

.element {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  min-height: 60px;
  &--burger {
    justify-content: flex-start;
  }
}
.element-boxes {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  min-height: 60px;
  border-bottom: 0.5px solid rgba(128, 128, 128, 0.67);
  &--burger {
    justify-content: flex-start;
  }
}

.element-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;

}
.element-description {
  font-size: 0.875rem;
  color: $grey-link;
}

.choose {
  margin-left: auto;
  padding: 10px 15px;
  background-color: #1b5e3b14;
  color: #1b5e3be0;
  border: 1px;
  border-radius: 8px;
  cursor: pointer;
}


.language {
  font-size: 16px;
  color: var(--text-color);
  @media (max-width: 850px) {
    font-size: 12px;
  }
}

li {
  cursor: pointer;
  margin-bottom: 10px;
}

li:hover {
  background-color: #f0f0f0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 800px; /* Измените это значение на желаемую максимальную высоту списка */
  }
}

.fade-in {
  animation: fadeIn 0.2s ease-out;
}


.fieldCont {
  position: relative;
  width: 100%;
}

.fieldInp {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  appearance: none;
  box-shadow: none;
  box-sizing: border-box;
  width: 100%;
  color: #202125;
  transition: border 0.2s linear 0s;
  border-radius: 0.5rem;
  border: 2px solid #2021251f;
  padding: 1rem 0.875rem 0.4rem 0.875rem;;
  background: transparent;
  outline: none;
  &:hover,&:focus {
    border-color: $blue;
  }

}

.lable {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: #202125a3;
  position: absolute;
  top: 0.875rem;
  width: auto;
  right: 1rem;
  left: 1rem;
  pointer-events: none;
  transition: transform 0.15s ease-in-out 0s;
  transform-origin: left center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
}

.large-lable {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.6rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: #202125a3;
  position: absolute;
  top: 0.1rem;
  width: auto;
  right: 1rem;
  left: 1rem;
  pointer-events: none;
  transition: transform 0.15s ease-in-out 0s;
  transform-origin: left center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
}


.submitBtn {
  margin-top: 1rem;
  position: relative;
  z-index: 0;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: 100%;
  cursor: pointer;
  min-height: 3.375rem;
  border-radius: 0.5rem;
  background: transparent;
  color: #fff;
}

.fakeSub {
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
  transition: background-color 120ms ease-out 0s;
  background: #1b5e3b;
}

.fakeSubTwo {
  position: absolute;
  inset: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textSub {
  flex: 1 1 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 150ms ease-in 0s, color 150ms linear 0s;
  border-radius: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

.image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.closeBtnConteiner {
  z-index: 100;
  position: fixed;
  right: 1.5rem !important;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  inset-inline-end: 1rem;
  margin-top: 0.5rem;
}
.rowBlockCont {
  background-color: var(--background-color);
  width: 100%;
  min-height: 3.2rem;
  left: 0;
  top: 0;
  position: sticky;
}

.buttonBlock {
  height: 2.5rem;
  width: 3.5rem;
  cursor: pointer;
  background-color: rgba(97, 218, 251, 0.3);
  color: #1b5e3b;
  border-radius: 0.5rem;
  font-weight: bold;
  margin-right: 1rem;
}

.fakeBtnBlock {
  background-color: #20212514;
  border-radius: 50%;
  inset: 0;
  position: absolute;
  transition: background-color .12s ease-out;
}

.closeImgBtn {
  align-items: center;
  border-radius: 50%;
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;
  z-index: 1;
}

.closeImg {
  height: 1.25rem;
  width: 1.25rem;
  fill: #202125;
}

.bigModal {
  min-height: 600px !important;
  bottom: 2rem !important;
}

.imagesBlock {
  display: flex;
  height: 3rem;
  margin-top: 1rem;
  justify-content: space-between;
  padding: 0 0.5rem;
}
.rowBlock {
  display: flex;
  height: 3rem;
  align-items: center;
}
.svgImage {
  height: 30px;
  width: 30px;
  color: black;
  fill: black;
  &__active {
    height: 30px;
    width: 30px;
    color: #1b5e3b;
    fill: #1b5e3b;
  }
}
.leftBlock {
  display: flex;
  align-items: center;
}
.textBlockImage {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}
.topText {
  font-size: 0.9rem;
  font-weight: bold;
  color: black;
  &__active {
    font-size: 0.9rem;
    font-weight: bold;
    color: #1b5e3b;
  }
}
.downText {
  font-size: 0.7rem;
  color: #707174;
}
.IconNextBlock {
  height: 100%;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.IconNext {
  margin-top: 4px;
  fill: forestgreen;
}
.conteinerAddress {
  height: 85vh;
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  @media screen and (max-height: 700px) {
    height: 80vh;
  }
}
.round {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 1px solid rgba(169, 169, 169, 0.3);
}
.addNewAddress {
  position: absolute;
  width: 92%;
  background-color: #1b5e3b;
  border-radius: 0.5rem;
  height: 3rem;
  color: white;
  font-weight: bold;
  font-size: 1.1rem;
  bottom: 0.5rem;
  @media screen and (max-height: 700px) {
    height: 2.7rem
  }
}
.addTitleMess {
  font-weight: 800;
  font-size: 1.8rem;
  margin-top: 1rem;
}
.middleBlock {
  margin-top: 1rem;
  margin-bottom: 0.5rem;
}
.downBlock {
  margin-top: 1.5rem;
}