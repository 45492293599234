.button {
    position: absolute;
    top: 8px;
    width: 64px;
    height: 64px;
    background-color: #22202b;
    border-radius: 50%;
    cursor: pointer;
    touch-action: none;
}

.button::after {
  content: '';
  display: block;
  width: 40px;
  height: 40px;
  background-image: (url('./chevron.png'));
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 11px;
  left: 13px;
}

.container {
  position: relative;
  max-width: 380px;
  width: 100%;
  height: 80px;
  background-color: #1b5e3b;
  border-radius: 40px;
  overflow: hidden;
  touch-action: none;
  margin: 0 auto;
}

.linearWipe {
  background: linear-gradient(
        to right,
        #202125 20%,
        #00affa 30%,
        #0190cd 70%,
        #202125 80%
    );
    -webkit-background-clip: text;
    background-clip: text;
    -webkit-text-fill-color: transparent;
    background-size: 500% auto;
    animation: textShine 5s ease-in-out infinite alternate;
}
@keyframes textShine {
      0% {
          background-position: 0% 50%;
      }
      100% {
          background-position: 100% 50%;
      }
  }

.buttonText {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.spinner {
  color: #1c4c5b;
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px;
}
.spinner div {
  transform-origin: 20px 20px;
  animation: spinner 1.2s linear infinite;
}
.spinner div:after {
  content: " ";
  display: block;
  position: absolute;
  top: 1.6px;
  left: 18.4px;
  width: 3.2px;
  height: 8.8px;
  border-radius: 20%;
  background: currentColor;
}
.spinner div:nth-child(1) {
  transform: rotate(0deg);
  animation-delay: -1.1s;
}
.spinner div:nth-child(2) {
  transform: rotate(30deg);
  animation-delay: -1s;
}
.spinner div:nth-child(3) {
  transform: rotate(60deg);
  animation-delay: -0.9s;
}
.spinner div:nth-child(4) {
  transform: rotate(90deg);
  animation-delay: -0.8s;
}
.spinner div:nth-child(5) {
  transform: rotate(120deg);
  animation-delay: -0.7s;
}
.spinner div:nth-child(6) {
  transform: rotate(150deg);
  animation-delay: -0.6s;
}
.spinner div:nth-child(7) {
  transform: rotate(180deg);
  animation-delay: -0.5s;
}
.spinner div:nth-child(8) {
  transform: rotate(210deg);
  animation-delay: -0.4s;
}
.spinner div:nth-child(9) {
  transform: rotate(240deg);
  animation-delay: -0.3s;
}
.spinner div:nth-child(10) {
  transform: rotate(270deg);
  animation-delay: -0.2s;
}
.spinner div:nth-child(11) {
  transform: rotate(300deg);
  animation-delay: -0.1s;
}
.spinner div:nth-child(12) {
  transform: rotate(330deg);
  animation-delay: 0s;
}

@keyframes spinner {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}
