.mainConteiner {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  height: 36px;
  outline: none;
  padding: 0.5rem 0.75rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 1rem;
  cursor: pointer;
  white-space: nowrap;
  opacity: 1;
  pointer-events: auto;
  text-decoration: none;
  box-sizing: border-box;
  color: black;
}

.active {
  background-color: #1b5e3b14;
  color: #1b5e3b;
}

@media screen and (max-width: 639px) {
  .mainConteiner {
    height: 28px;
    font-size: 0.75rem;
  }
}
