.backButton {
    display: none;
  }
  @media screen and (max-width: 640px) {
    .backButton {
      position: fixed;
      width: 100%;
      z-index: 1;
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  
  .title {
    font-size: 1.1rem;
    line-height: 1.5rem;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    font-weight: 500;
  }

  .deliveriesWrapper {
    padding-top: 48px;
  }

  .totalEarnings{
    display: flex;
    font-size: 1.5rem;
    margin-bottom: 1.5rem;
    font-weight: 700;
  }

  .earningsDate{
    display: flex;
    font-size: 1rem;
    margin-bottom: 0.5rem;
  }

  .deliveryCardsWrapper {
    display: flex;
    flex-direction: column;
  }
  