.mainContainer {
  margin: 0.5rem 30px 2rem;
}

.mainBlock {
  max-width: 1540px;
  margin: 0px auto;
}

.upConteiner {
  position: relative;
  width: 100%;
  height: 4rem;
  display: flex;
  flex-direction: row;
  padding-inline: 0rem;
  padding-block-end: 1.5rem;
  justify-content: space-between;
  align-items: center;
}

.upConteinerTitle {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  padding: 0;
}


.downConteiner {
  display: flex;
  box-sizing: content-box;
  flex-direction: row;
  gap: 1rem;
  padding: 3rem 0 0.75rem;
  overflow-x: auto;
  overscroll-behavior-x: contain;
  scroll-behavior: smooth;
  scrollbar-width: none;
  scroll-snap-type: x mandatory;
}

@media screen and (min-width: 0px) {
  .downConteiner > * {
    width: calc(100% - 2rem);
  }
}

.downConteiner > * {
  flex-grow: 0;
  flex-shrink: 0;
  scroll-snap-align: start;
}

@media screen and (min-width: 0px) {
  .downConteiner {
    flex-wrap: nowrap;
    padding-inline-start: 0.75rem;
    scroll-padding: 0 0.75rem;
    margin: -3rem -0.75rem -0.75rem;
  }
}

@media screen and (min-width: 640px) {
  .downConteiner {
    flex-wrap: nowrap;
    padding-inline-start: 0.75rem;
    scroll-padding: 0 0.75rem;
    margin: -3rem -0.75rem -0.75rem;
  }
}

@media screen and (max-width: 640px) {
  .mainContainer {
    margin: 0.5rem 1rem;
  }
}
