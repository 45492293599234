/* Общие стили для блока */
.blockNotFound {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  background-color: #f8f8f8;
  padding: 20px;
  text-align: center;
}

/* Стили для мобильной версии (windowWidth < 700) */
.m1m6ijyu {
  max-width: 400px;
  margin: 0 auto;
}

/* Контейнер для SVG (иконки) */
.i2vnumb {
  margin-bottom: 24px;
}

/* Стили для SVG (если нужно задать размер) */
.Illustration__image svg {
  width: 120px;
  height: 120px;
}

/* Заголовок "Something unexpected happened" */
.tseoo83 {
  font-size: 24px;
  font-weight: 600;
  color: #333;
  margin-bottom: 12px;
}

/* Текст ошибки */
.b1gmi0cx {
  font-size: 16px;
  color: #666;
  margin-bottom: 24px;
  line-height: 1.5;
}

/* Стили кнопки "Back to Wolt" */
.cbc_Button_rootClass_0259f {
  background-color: #00cc33; /* Зеленый, как в Wolt */
  color: white;
  border: none;
  border-radius: 4px;
  padding: 12px 24px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
  transition: background-color 0.2s;
}

.cbc_Button_rootClass_0259f:hover {
  background-color: #00b32d;
}

/* Стили для десктопной версии (если нужен другой фон) */
.image {
  width: 100%;
  height: 100vh;
  background-color: #f8f8f8;
  background-image: url('404.jpg');
  background-size: cover;
  background-position: center;
}