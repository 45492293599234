.addButton {
  font-weight: 600;
  font-size: 1.2rem;
  border: 1px solid #1b5e3b;
  border-radius: 10px;
  padding: 7px;
  margin: 4px;
  background-color: #1b5e3b;
  color: white;
}
.btnSelect {
  background: #0f99d3;
  color: white;
  border: 5px solid #0f99d3;
  border-radius: 0.5rem;

}
.mainBtnLinkControll {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.closeImgBtn {
  position: relative;
  align-items: center;
  width: 100%;
  border-radius: 50%;
  display: flex;
  inset: 0;
  justify-content: end;
  z-index: 999999;
  margin-top: 0.5rem;
  padding-right: 0.5rem;
}
.closeImg {
  height: 1.75rem;
  width: 1.75rem;
  fill: #202125;
  border: 0.4rem solid rgba(0, 0, 0, 0);
  background: rgba(0, 0, 0, 0.1);
  border-radius: 50%;
}

