@import '../../styles/variables';

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999999;
}

.modal-content {
  box-sizing: border-box;
  margin: 20px;
  max-width: 520px;
  max-height: 650px;
  width: 55vw;
  background-color: var(--background-color);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;
  @media (min-width: 640px) {
    max-height: unset;
  }

  @media (max-width: 640px) {
    overflow: hidden;
    min-width: unset;
    width: 100%;
    background-color: var(--background-color);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 0.75rem 0px, rgba(0, 0, 0, 0.12) 0px 0.75rem 0.75rem 0px;
    position: fixed;
    bottom: 0;
    height: fit-content;
    padding: 0 0;
    margin:0;
    border-radius: 10px 10px 0 0 ;
    animation-name: slideUp;
    animation-duration: 0.5s;
    animation-fill-mode: forwards; /* Сохранение конечного состояния после анимации */
    @keyframes slideUp {
      from {bottom: -100%; opacity: 1;}
      to {bottom: 0; opacity: 1;}
    }
  }
}

@media (min-width: 640px) {
  .modal-content {
    padding:0;
  }
}

.contentBox {
  padding: 10px;
  @media (min-width: 640px) {
    padding: 16px;
  }
}

.closeImgBtn {
  align-items: center;
  border-radius: 50%;
  display: flex;
  inset: 0;
  justify-content: center;
  z-index: 1;
}

.closeImg {
  height: 1.25rem;
  width: 1.25rem;
  fill: #202125;
}

.fantomConteiner {
  position: relative;
  width: 96%;
  height: 500px !important;
  bottom: 0
}
.infoTestCont {
  height: 500px !important;
  position: absolute;
  width: 100%;
  box-sizing: border-box;
  animation-name: slideUp;
  animation-duration: 1.5s;
  animation-fill-mode: forwards; /* Сохранение конечного состояния после анимации */
  @keyframes slideUp {
    from {bottom: -100%; opacity: 0.5;}
    to {bottom: 0; opacity: 1;}
  }
}

.productInfoModal {
  position: relative;
  top: 1rem;
}

.header-wrapper {
  position: relative;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
}
::-webkit-scrollbar {
  width: 5px;
  transition: background-color .2s linear, width .2s ease-in-out;
  -webkit-transition: background-color .2s linear, width .2s ease-in-out;
}

::-webkit-scrollbar-thumb {
  background-color: #1b5e3be0;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.button {
  color: white;
  font-size: 14px;
  @media (max-width: 850px) {
    font-size: 10.5px;
  }
}

.close-button {
  text-align: end;
  position: relative;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.closeBtn {
  font-weight: bold;
  font-size: 1.35rem;
  border-radius: 50%;
  background-color: var(--dark-grey-color);
  margin-bottom: 2px;
  width: 36px;
  height: 36px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--text-color);
}

.header {
  padding: 16px 16px 16px 0;
  font-size: 40px;
  color: var(--text-color);
  @media (min-width: 640px) {
    line-height: 44px;
  }
  @media (max-width: 850px) {
    font-size: 24px;
    padding: 12px;
  }
}

.price {
  color: $blue-light;
}

.description {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: var(--cb-color-text);
  margin-top: 1rem;
  white-space: pre-wrap;
}

.description2 {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: bold;
  color: var(--cb-color-text);
  white-space: pre-wrap;
  bottom: 0.2rem;
  margin-left: 2rem;
}

@media (min-width: 640px) {
  .description,
  .description2 {
    overflow-y: auto;
    max-height: 180px;
  }
}

ul {
  list-style: none;
  padding: 0;
}

.element {
  width: 100%;
  display: flex;
  padding-right: 16px;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  min-height: 60px;
  border-top: 1.2px solid #e4e4e5;
  border-left: unset;
  border-right: unset;
  cursor: pointer;
  &--burger {
    justify-content: flex-start;
  }
}

.element-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;

}

.element-description {
  font-size: 1rem;
  color: $blue-light;

  @media (max-width: 850px) {
    font-size: 0.875rem;
  }
}

.choose {
  margin-left: auto;
  padding: 10px 15px;
  background-color: #1b5e3b14;
  color: #1b5e3be0;
  border: 1px;
  border-radius: 8px;
  cursor: pointer;
}


@keyframes fadeIn {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 800px; /* Измените это значение на желаемую максимальную высоту списка */
  }
}

.fade-in {
  animation: fadeIn 0.2s ease-out;
}


.fieldCont {
  position: relative;
  width: 100%;
}

.fieldInp {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  appearance: none;
  box-shadow: none;
  box-sizing: border-box;
  width: 100%;
  color: #202125;
  transition: border 0.2s linear 0s;
  border-radius: 0.5rem;
  border: 2px solid #2021251f;
  padding: 1rem 0.875rem 0.4rem 0.875rem;;
  background: transparent;
  outline: none;
  &:hover,&:focus {
    border-color: $blue;
  }

}

.lable {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: #202125a3;
  position: absolute;
  top: 0.875rem;
  width: auto;
  right: 1rem;
  left: 1rem;
  pointer-events: none;
  transition: transform 0.15s ease-in-out 0s;
  transform-origin: left center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
}

.large-lable {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.6rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: #202125a3;
  position: absolute;
  top: 0.1rem;
  width: auto;
  right: 1rem;
  left: 1rem;
  pointer-events: none;
  transition: transform 0.15s ease-in-out 0s;
  transform-origin: left center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
}

.fakeSub {
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
  transition: background-color 120ms ease-out 0s;
  background: #1b5e3b;
}

.fakeSubTwo {
  position: absolute;
  inset: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.mainImg {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.mainContentBlockImg {
  object-fit: cover;
  width: 100%;
  height: auto;
  aspect-ratio: 3 / 2;
  pointer-events: none;
  transform: scale(1);
  transition: transform 150ms cubic-bezier(0.45, 0, 0.55, 1) 0s;
}

.image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
  @media (min-width: 640px) {
    object-fit: cover;
    object-position: 50% 50%;
    width: 100%;
    cursor: zoom-in;
  }
}

.image-order {
  width: 100%;
  height: 200px;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 850px) {
    background-size: contain;
    object-fit: cover;
    height: 260px;
  }
  @media (min-width: 640px) {
    width: 100%;
    height: 292px;
    display: block;
    inset: 0;
  }
}

.quantity-wrapper {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.quantity-container {
  display: flex;

}

.quantity {
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  background-color: $blue-light-background;
  border-radius: 0.5rem;
  color: var(--cb-color-text-brand);
  text-align: center;
  display: grid;
  align-items: center;
  grid-template-columns: 3.375rem minmax(1.75rem, auto) 3.375rem;
  height: 3.375rem;

  @media (max-width: 640px) {
    gap: 0.75rem;
  };
  &--count {
    color: $blue-light;
  }
  &--element {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
  }
}

.add-button {
  border: none;
  position: relative;
  z-index: 0;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: 100%;
  max-width: 100%;
  cursor: pointer;
  padding: 0px 1rem;
  border-radius: 0.5rem;
  background: var(--blue-btn-background-color);
  justify-content: center;
  &--element {
    color: var(--blue-btn-text-color);
  }
}

.closeBtnConteiner {
  z-index: 100;
  position: absolute;
  top: 0;
  right: 0.2rem !important;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  inset-inline-end: 1rem;
}

@media screen and (min-width: 640px) {
  .closeBtnConteiner {
    top: 1rem;
    right: 1rem !important;
  }
}

.buttonBlock {
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
}

.fakeBtnBlock {
  background-color: #20212514;
  border-radius: 50%;
  inset: 0;
  position: absolute;
  transition: background-color .12s ease-out;
}

.closeImgBtn {
  align-items: center;
  border-radius: 50%;
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;
  z-index: 1;
}

.closeImg {
  height: 1.25rem;
  width: 1.25rem;
  fill: #202125;
}


@media screen and (max-width: 640px) {
  .quantity--element {
    width: 56px;
    display: flex;
    justify-content: center;
  }
  .price {
    font-size: 0.8rem;
  }
  .description {
    margin-top: 8px;
    font-size: 0.9rem;
    max-height: 153px;
    overflow-y: auto;
    padding: 1rem 0 0 0;
  }

  .closeBtnConteiner {
    top:8px;
    width: 2rem;
    height: 2rem;
  }
  .image-order {
    height: 260px;
  }
  .quantity--count {
    font-size: 0.9rem;
  }
  .quantity {
    height: 44px !important;
    box-sizing: border-box !important;
    max-width: 140px;
    gap: 0rem;
  }
  .quantity-wrapper {
    height: 44px !important;
    box-sizing: border-box !important;
  }
  .add-button {
    max-width: 260px !important;
    height: 44px !important;
    box-sizing: border-box !important;
  }
  .add-button--element {
    text-rendering: optimizelegibility;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 0.85rem;
    line-height: 1.2rem;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    font-weight: 700;

  }
  .add-button--element{
    text-rendering: optimizelegibility;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 0.85rem;
    line-height: 1.2rem;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    font-weight: 700;
  }
  .element {
    min-height: 40px !important;
    margin-bottom: 20px;
    padding-right: 0;
  }
  .element-description {
    margin-top: 2px;
    font-size: 0.8rem;
  }

}

.goToShop {
  width: 100% !important;
  max-width: 100% !important;
}