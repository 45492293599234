.conteiner {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0;
  position: absolute;
  bottom: 40%;
  z-index: 999999;
  left: calc(50% - 8rem);
  @media screen and (max-height: 900px) {
    bottom: 54%;
  }
  @media screen and (max-height: 780px) {
    bottom: 45%;
  }
  @media screen and (max-height: 700px) {
    bottom: 45%;
  }
}

.circleContent {
  position: absolute;
  width: 15rem;
  height: 15rem;
  top: 2.2rem;
  background: white;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  z-index: 3;
  @media screen and (max-height: 850px) {
    width: 14rem;
    height: 14rem;
  }
  @media screen and (max-height: 700px) {
    width: 12rem;
    height: 12rem;
  }
}
.circleContentDark {
  position: absolute;
  width: 15rem;
  height: 15rem;
  top: 2.2rem;
  border-radius: 50%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  z-index: 3;
  @media screen and (max-height: 850px) {
    width: 14rem;
    height: 14rem;
  }
  @media screen and (max-height: 700px) {
    width: 12rem;
    height: 12rem;
  }
}

.timeRange {
  font-size: 36px;
  font-weight: bold;
  margin: 0;
}

.text {
  font-size: 16px;
  margin: 0;
}

.outerRing {
  position: absolute;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background: #e0e0e0;
  z-index: 1;
}

.progressSegment {
  position: absolute;
  width: 250px;
  height: 250px;
  border-radius: 50%;
  background: repeating-linear-gradient(
    45deg,
    #ffb400,
    #ffb400 10px,
    #ffffff 1px,
    #ffffff 4px
  );
  transform: rotate(20deg);
  transform-origin: center;
  z-index: 1;
}

.progress1 {
  clip: rect(0px, 250px, 80px, 125px);
  background: repeating-linear-gradient(
    45deg,
    red,
    red 10px,
    #ffffff 1px,
    #ffffff 4px
  );
}

.progress2 {
  clip: rect(0px, 250px, 260px, 125px);
  background: repeating-linear-gradient(
    45deg,
    #ffb400,
    #ffb400 10px,
    #ffffff 1px,
    #ffffff 4px
  );
  transform: rotate(0deg);
}

.progress3 {
  clip: rect(0px, 265px, 365px, 65px);
  background: repeating-linear-gradient(
    45deg,
    blue,
    blue 10px,
    #ffffff 1px,
    #ffffff 4px
  );
  transform: rotate(53deg);
}
.progress4 {
  clip: rect(0px, 360px, 360px, 10px);
  background: repeating-linear-gradient(
    45deg,
    green,
    green 10px,
    #ffffff 1px,
    #ffffff 4px
  );
  transform: rotate(90deg);
}

.cap {
  position: absolute;
  width: 25px;
  height: 25px;
  background: #ffb400;
  border-radius: 50%;
  z-index: 3;
}

.startCap {
  top: 25%;
  left: 19%;
  transform: translate(-50%, -50%) rotate(-20deg) translate(125px);
}
.startCap1 {
  top: 25%;
  left: 19%;
  background: red;
  transform: translate(-50%, -50%) rotate(-20deg) translate(125px);
}
.startCap2 {
  top: 25%;
  left: 19%;
  transform: translate(-50%, -50%) rotate(-20deg) translate(125px);
}
.startCap3 {
  top: 25%;
  left: 19%;
  background: blue;
  height: 24px;
  transform: translate(-50%, -50%) rotate(-20deg) translate(125px);
}
.startCap4 {
  top: 25%;
  left: 19%;
  transform: translate(-50%, -50%) rotate(-20deg) translate(125px);
  background: green;
}

.capTwo {
  position: absolute;
  width: 25px;
  height: 25px;
  background: #e0e0e0;
  border-radius: 50%;
  z-index: 6;
}

.startCapTwo {
  top: 16%;
  left: -14%;
  transform: translate(-50%, -50%) rotate(-10deg) translate(125px);
}
.startCapTwo4 {
  background: green;
}

.endCap {
  top: 89%;
  left: 26%;
  transform: translate(-50%, -50%) rotate(20deg) translate(21px);
}

.endCap1 {
  top: 44%;
  left: 86.8%;
  background: red;
}

.endCap2 {
  top: 92%;
  left: 44%;
}
.endCap3 {
  top: 51%;
  rotate: -13deg;
  left: -1.75%;
  background: blue;
  height: 36px;
}
.endCap4 {
  top: 44%;
  left: 86.8%;
  color: green;
  background: green;
}

.gapCover {
  position: absolute;
  width: 75px; /* Width of the gap */
  height: 60px; /* Height to cover the gap */
  background: white; /* Match background color */
  top: 30px;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 2; /* Ensure it covers the gray ring */
  border-radius: 25px 25px 150px 150px; /* Smooth edges */
}

.containerCircle {
  width: 100%;
  display: flex;
  justify-content: center;
  position: absolute;
  top: 1rem;
}

.mainBlock {
  background-size: cover;
  height: 20rem;
  width: 20rem;
  display: flex;
  align-items: center;
  justify-content: center;
  @media screen and (max-height: 850px) {
    height: 19rem;
    width: 19rem;
  }
  @media screen and (max-height: 700px) {
    height: 18rem;
    width: 18rem;
  }
}

.stateR {
  background-image: url("./red.png");
}

.stateO {
  background-image: url("./orange.png");
}
.stateG {
  background-image: url("./green.png");
}
.stateB {
  background-image: url("./blue.png");
}
.stateR_dark {
  background-image: url("./red-dark.png");
}
.stateO_dark {
  background-image: url("./orange-dark.png");
}
.stateG_dark {
  background-image: url("./green-dark.png");
}
.stateB_dark {
  background-image: url("./blue-dark.png");
}

.contentBlock {
  display: flex;
  flex-direction: column;
  z-index: 9999;
  width: 100%;
  justify-content: center;
  align-items: center;
  text-align: center;
}
.deliveryTime {
  max-width: 6rem;
  font-size: 2.5rem;
  font-weight: 800;
  @media screen and (max-height: 850px) {
    font-size: 2.3rem;
  }
  @media screen and (max-height: 700px) {
    font-size: 2.1rem;
  }
}
.deliveryText {
  max-width: 10rem;
  font-size: 1.15rem;
  align-items: center;
  @media screen and (max-height: 850px) {
    font-size: 1rem;
  }
  @media screen and (max-height: 700px) {
    font-size: 0.85rem;
  }
}

// time circle style

.baseTimer {
  position: absolute;
  width: 20rem;
  height: 20rem;
  top: -0.25rem;
  @media screen and (max-height: 850px) {
    height: 19rem;
    width: 19rem;
  }
  @media screen and (max-height: 700px) {
    height: 17rem;
    width: 17rem;
  }
}

.baseTimerSvg {
  width: 100%;
  height: 100%;
  transform: scaleX(-1);
}

.baseTimerCircle {
  fill: none;
  stroke: none;
}

.baseTimerPathElapsed {
  stroke-width: 0.5rem;
  stroke: #ededed;
  transform: rotate(285deg);
  transform-origin: center;
}

.baseTimerPathRemaining {
  stroke-width: 0.5rem;
  stroke-linecap: round;
  transform: rotate(75deg);
  transform-origin: center;
  transition: 1s linear all;
}
