.button-container {
  display: flex;
  grid-gap: 10px;
  margin-top: 20px;
  @media (min-width: 641px) {
    width: 40%;
  }
}

.add-button {
  border: none;
  position: relative;
  z-index: 0;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: 100%;
  height: 40px;
  max-width: 100%;
  cursor: pointer;
  padding: 0px 1rem;
  border-radius: 0.5rem;
  background: var(--blue-btn-background-color);
  justify-content: center;
  &--element {
    color: var(--blue-btn-text-color);
  }
}
.removeButton {
  border: none;
  position: relative;
  z-index: 0;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: 100%;
  height: 40px;
  max-width: 100%;
  cursor: pointer;
  padding: 0px 1rem;
  border-radius: 0.5rem;
  background: red;
  justify-content: center;
  &--element {
    color: var(--blue-btn-text-color);
  }
}

p {
  font-weight: bold;
  font-size: 17px;
}
.addRoleBtn {
  border: none;
  position: relative;
  z-index: 0;
  user-select: none;
  display: inline-flex;
  align-items: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: 100%;
  height: 40px;
  max-width: 100%;
  cursor: pointer;
  padding: 0px 1rem;
  border-radius: 0.5rem;
  margin-top: 20px;
  background: green;
  justify-content: center;
  color: white;
}
.mainConteiner {
  position: fixed;
  z-index: 9999999;
  background: rgba(128, 128, 128, 0.35);
  height: 100vh;
  width: 100vw;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.topBlockInfo {
  display: flex;
  flex-direction: column;
}
.topBlockInfoText {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
}
.lineTop {
  padding: 0;
  height: 0;
  border: none;
  border-top: 0.5px solid rgba(51, 51, 51, 0.2);
}
.topBlockInfoBlock {
  border: 0.5px solid rgba(51, 51, 51, 0.2);
  width: 100%;
  border-radius: 5px;
  padding-left: 10px;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
}
.topText {
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.5);
}
.downText {
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.8);
}
.topBlockInfoMainBlock {
  display: flex;
  justify-content: space-between;
}
.topBlockInfoMainCont {
  border: 0.5px solid rgba(51, 51, 51, 0.2);
  border-radius: 5px;
  padding-left: 10px;
  width: 30%;
  padding-top: 5px;
  padding-bottom: 5px;
}
.lineMiddle {
  margin-top: 1rem;
  padding: 0;
  height: 0;
  border: none;
  border-top: 0.5px solid rgba(51, 51, 51, 0.2);
}
.middleBlockInfo {

}
.topBlockInfoMainContM {
  border: 0.5px solid rgba(51, 51, 51, 0.2);
  border-radius: 5px;
  padding-left: 10px;
  width: 49%;
  margin-top: 2%;
}
.middleBlockInfoM {
  display: flex;
  flex-direction: column;
}
.topBlockInfoMainBlockM {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.middleBlockInfoD {
  display: flex;
  flex-direction: column;
}
.topBlockInfoMainContD {
  border: 0.5px solid rgba(51, 51, 51, 0.2);
  border-radius: 5px;
  padding-left: 10px;
  width: 100%;
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}