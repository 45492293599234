.mainContainer {
  position: fixed;
  width: 100vw;
  inset: 0;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 2;
  @media (max-width: 640px) {
    bottom: 0;
    animation-name: slideUp;
    animation-duration: 0.5s;
    animation-fill-mode: forwards;
    @keyframes slideUp {
      from {bottom: -100%; opacity: 1;}
      to {bottom: 0; opacity: 1;}
    }
  }
}

.mainBlock {
  transform: translateY(0rem) translateZ(0px);
}

.container {
  position: relative;
  z-index: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  height: 120vh;
  transition: height 500ms cubic-bezier(0.33, 1, 0.68, 1) 0s;
}

@media screen and (min-width: 640px) {
  .mainBlock {
    border-start-start-radius: 1rem;
    border-start-end-radius: 1rem;
    border-end-start-radius: 1rem;
    border-end-end-radius: 1rem;
    max-height: min(50rem, -24px + 100svh);
  }
  .container {
    border-start-start-radius: 1rem;
    border-start-end-radius: 1rem;
    border-end-start-radius: 1rem;
    border-end-end-radius: 1rem;
    max-height: min(50rem, -24px + 100svh);
  }
}

.closeBtnConteiner {
  z-index: 100;
  position: absolute;
  top: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: var(--background-color);
  margin-left: 1rem;
}

.buttonBlock {
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
}

.btnFakeBlock {
  background-color: #ededee;
  border-radius: 50%;
  inset: 0;
  position: absolute;
  transition: background-color .12s ease-out;
}

.btnConteinerInclude {
  align-items: center;
  border-radius: 50%;
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;
  z-index: 1;
}

.btnConteinerInclude:hover {
  background-color: #e3e3e3;
}

.imgCloseBtn {
  height: 1.25rem;
  width: 1.25rem;
  fill: #202125;
}

.mainConteiner {
  position: relative;
  z-index: 0;
  overflow-y: hidden;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color)
}

.mainInfoConteiner {
  display: flex;
  position: relative;
  z-index: 1;
  flex: 1 1 0%;
  padding-top: 0;
  overflow-y: auto;
  width: 100%;
}

.modalContentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 96vh;
}

.venueInformationModal {
  padding: 0px 1rem;
}

@media screen and (min-width: 640px) {
  .mainBlock {
    --container-border-start-start-radius: 1rem;
    --container-border-start-end-radius: 1rem;
    --container-border-end-start-radius: 1rem;
    --container-border-end-end-radius: 1rem;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 1rem 0px, rgba(0, 0, 0, 0.12) 0px 1rem 1rem 0px;
    position: static;
    flex: 1 1 0;
    max-width: min(31.25rem, 90vw);
  }
}

@media screen and (max-width: 640px) {
  .container {
    top: 5.5rem;
  }
  .mainBlock {
    width: 100%;
  }
  .container {
    width: 100%;
  }
}

.content {
  display:flex;
  justify-content:center;
  align-items:center;
  flex-direction: column;
  margin-top: 50%;
  grid-gap: 0.5rem;
  position: relative;

  @media (min-width: 640px) {
    margin-top: 20%;
  }
  h1{
    font-size: 1rem;
    font-weight: 400;
    color: var(--text-color);
  }
  h3{
    font-size: 1.5rem;
    font-weight: 800;
    color: var(--text-color);
  }
  h6{
    font-size: 1rem;
    font-weight: 400;
    color: var(--text-color);
  }
}

.img {
  width: 100vw;
  @media (min-width: 640px) {
    width: initial;
  }
}

.imageBlock {
  position: relative;
}

.reviewBlock {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  margin-top: -60%;
  background-color: white;
  padding: 1rem;
  opacity: 0;
  transform: translateY(100%);
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.reviewBlockVisible {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  position: relative;
  z-index: 2;
  margin-top: -60%;
  background-color: white;
  padding: 1rem;
  opacity: 1;
  transform: translateY(15%);
  transition: transform 0.5s ease, opacity 0.5s ease;
}

.modalContentContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 96vh;
  @media (min-width: 640px) {
    height: 70vh;
  }
}

.buttonReviews {
  width: 92%;
  margin: 0 auto;
  height: 3rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  background-color: var(--blue-btn-background-color);
  color: var(--blue-btn-text-color);
}


