/* ProfileCard.module.css */

.profileCard {
  margin: 1.5rem auto;
  max-width: 900px;
  padding: 2.1875rem 0;
  position: relative;
}

.mainTitle {
  font-size: 1.8rem;
  font-weight: 750;
  padding: 0 1rem;
}

.container {
  padding: 1.25rem 1rem;
  position: relative;
  border-bottom: 0.5px solid rgba(128, 128, 128, 0.31);
}
.mainModalConteiner {
  z-index: 9999999999;
  position: absolute;
  top: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem;
}
.formaBlock {
  margin: 0;
  display: flex;
  flex-direction: column;
  flex: 1 1 0;
  background: white;
  height: 35%;
  padding: 1rem;
  padding-top: 0.5rem;
  border-radius: 1rem;
}
.closeImgBtn {
  align-items: center;
  border-radius: 50%;
  display: flex;
  inset: 0;
  justify-content: center;
  z-index: 1;
  height: 1.75rem;
  width: 1.75rem;
  background: rgba(0, 0, 0, 0.15);
}
.closeImg {
  height: 1rem;
  width: 1rem;
  fill: #202125;
}
.buttonBlock {
  height: 1.65rem;
  width: 1.65rem;
  cursor: pointer;
  z-index: 999999;
}
.closeBtnCont {
  display: flex;
  justify-content: end;
}
.resetConteiner {
  display: flex;
}
.resetTitle {
  width: 100%;
  font-weight: bold;
  font-size: 1.2rem;
  display: flex;
  justify-content: center;
}
.fieldInp {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  appearance: none;
  box-shadow: none;
  box-sizing: border-box;
  width: 100%;
  color: var(--text-color);
  transition: border 0.2s linear 0s;
  border-radius: 0.5rem;
  border: 2px solid var(--dark-grey-color);
  padding: 0.75rem 0.875rem;
  background: var(--background-color);
  @media (max-width: 640px) {
    line-height: 1rem;
    font-size: 0.7rem;
  }
  outline: none;
}
.fieldCont {
  position: relative;
  width: 100%;
}
.lable {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: var(--grey-text-color);
  position: absolute;
  top: 0.875rem;
  width: auto;
  right: 1rem;
  left: 1rem;
  pointer-events: none;
  transition: transform 0.15s ease-in-out 0s;
  transform-origin: left center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
  @media (max-width: 640px) {
    line-height: 1rem;
    font-size: 0.7rem;
  }
}
.formaBlock > :not(:first-child) {
  margin-top: 0.5rem;
}

.imageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}

.imageWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.profileImage {
  background: var(--background-color);
  border: 4px solid var(--background-color);
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
  height: 24px;
  margin-bottom: .3rem;
  margin-right: 4px;
  overflow: hidden;
  width: 24px;
}

.uploadInput {
  width: 60px;
  height: 60px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 30;
}

.uploadLabel {
  color: var(--cb-color-text-brand);
  cursor: pointer;
  font-size: 1.07rem;
  padding: .5rem 1rem;
}

.description {
  color: #1b5e3b;
}

.userInfo {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-inline-end: 0;
  margin-inline-start: 1.2rem;
}

.userName {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.userNameText {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin: 0px;
}

.userDetails {
  display: flex;
  flex-direction: row;
}

.detail {
  margin-right: 4rem;
  margin-inline-end: 4rem;
  margin-inline-start: 0;
}

.upTitle {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  margin: 0;
}

.upDescription {
  padding-top: .1rem;
  font-size: 1.1rem;
  font-weight: 700;
}

.downTitle {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: #707174;
  margin: 0;
}

.downDescription {
  padding-top: .1rem;
  font-size: 1.07rem;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 3rem;
}

.downConteiner {
  padding: 1rem;
}

.downBlock {
  margin-bottom: 2rem;
  margin-top: 2rem;
  overflow: hidden;
}

.downMainBlock {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.boxMain {
  flex: 3;
}

.infoRowLvl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.5rem;
}
.infoBlock  {
  margin-bottom: 1rem;
}
.downText {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  color: #202125;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.downTextTwo {
  color: #202125a3;
}

.iconBlock {
  display: flex;
  flex: 2;
  justify-content: center;
  max-width: 164px;
  text-align: right;
}

.IconNextBlock {
  height: 100%;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.IconNext {
  rotate: (270deg);
  margin-top: 4px;
  fill: var(--text-color);
}

.cardContainerBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
}
.cardText {
  font-size: 1.1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
}
.countMoney {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  margin-right: 5px;
}
.moneyCountBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
}
.img {

}
.imgWolt {
  width: 1.125rem;
  height: 1.125rem;
  opacity: 1;
}
.tokensMain {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.cardTextMain {
  font-weight: 700;
  line-height: 1.8rem;
  font-size: 1.1rem;
  margin-right: 0.5rem;
  margin-bottom: 0.1rem;
}


.contentRowBlock {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.nameRow {
  display: flex;
  align-items: center;
}

.nameRowCountry {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
  box-sizing: border-box;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #23d600;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media screen and (max-width: 640px) {
  .downBlock {
    margin: 2rem 30px 0;
  }
  .iconBlock {

  }

  .userDetails {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  .detail {
    box-sizing: border-box;
    margin-right: 0rem;
    margin-bottom: 0.2rem;
  }

  .cardContainer {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
  }

  .downMainBlock {
    display: flex;
    flex-direction: column;;
  }
}

.goBackButton {
  display: none;
  position: fixed;
  width: 32px;
  height: 32px;
  z-index: 9999999;
  top: 1%;
  left: 3%;
}

@media screen and (max-width: 640px) {
  .goBackButton {
    display: block;
  }
}
.resetPassBtn {
  margin-top: 1rem;
  background: #FF4D4F;
  color: white;
  width: 100%;
  height: 3rem;
  border-radius: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
}
.submitBtn {
  border: none;
  position: relative;
  z-index: 0;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: auto;
  max-width: 100%;
  cursor: pointer;
  padding: 0px 1rem;
  min-height: 3.375rem;
  border-radius: 0.5rem;
  color: var(--blue-btn-text-color);
  @media (max-width: 640px) {
    font-size: 0.7rem;
    line-height: 1rem;
    min-height: 2.5rem;
  }
}

.fakeSub {
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
  transition: background-color 120ms ease-out 0s;
  background: var(--blue-btn-background-color);
}

.fakeSubTwo {
  position: absolute;
  inset: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textSub {
  flex: 1 1 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 150ms ease-in 0s, color 150ms linear 0s;
  border-radius: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  font-size: 1rem;
}