.lvl3 {
  opacity: 1;
  margin-top: 3rem;
}

.mainConteiner {
  margin-left: 30px;
  margin-right: 30px;
}

.mainBlock {
  margin: auto;
  max-width: 500px;
  text-align: center;
}

.mainImg {
  width: 100%;
  position: relative;
}

.mainBlockImg {
  height: 100%;
  opacity: 1;
  transition: opacity .15s ease-in-out;
  width: 100%;
  margin: 0 auto;
}

.container {
  width: 100%;
  margin-bottom: 1.5rem;
}

.title {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  padding: 0 0 0.5rem;
  margin: 0;
  display: flex;
}

.description {
  margin: 1rem auto 0;
  padding-left: 3rem;
  padding-right: 3rem;
}

.descText {
  color: #202125a3;
  font-size: 1.07rem;
}

.inputContainer {
  margin-left: 3rem;
  margin-right: 3rem;
  margin-top: 2rem;
  text-align: start;
}

.inputWrapper {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  height: 5rem;
  justify-content: center;
  position: relative;
}

.input {
  box-sizing: border-box;
  border-radius: 0.5rem;
  border: 1px solid #2021251f;
  font-size: 1.5rem;
  background: #2021250a;
  color: #202125;
  padding: 0.5rem 1rem;
  position: absolute;
  height: 100%;
  width: 100%;
  margin: 0;
}

.button {
  margin-inline-end: 1rem;
  margin-inline-start: auto;
  width: auto;
  font-size: 1rem;
  font-weight: bold;
  color: white;
  background: #1b5e3b;
  border: 20px solid #1b5e3b;
  border-radius: 10px;
}

.buttonBackground {
  transition: background-color 120ms ease-out 0s;
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
}

.buttonBorder {
  position: absolute;
  inset: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.buttonText {
  flex: 1 1 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 150ms ease-in 0s, color 150ms linear 0s;
  border-radius: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}


@media screen and (max-width: 641px) {
  .inputContainer {
    margin: 0;
    margin-top: 3rem;
  }
  .description {
    padding: 0;
    margin-top: 0;

  }
  .input {
    font-size: 1rem;
  }
  .mainConteiner{
    margin-left: 0;
    margin-right: 0;
    padding: 1rem;
  }
  .inputContainer {

  }
  .descText {
    font-size: 1rem;
  }

  .button {
    font-size: 1.2rem;
    width: 100%;

  }
}

.backer {
  display: none;
}

@media screen and (max-width: 640px) {
  .backer {
    display: block;
  }
}
.btnMain {
  width: 100%;
  height: 3rem;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #1b5e3b;
  color: white;
  border-radius: 8px;
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 600;
  cursor: pointer;
}

.clearBtn {
  position: absolute;
  font-weight: bold;
  font-size: 1rem;
  height: 26px;
  width: 26px;
  background-color: rgba(169, 169, 169, 0.4);
  border-radius: 50%;
  z-index: 999999;
  top: 10px;
  right: 15px;
  cursor: pointer;
}

.img {
  width: 100%;
}