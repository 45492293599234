.mainContainer {
  position: fixed;
  width: 100vw;
  inset: 0;
  color: #202125;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 2;
  @media (max-width: 640px) {
    bottom: 0;
    animation-name: slideUp;
    animation-duration: 0.5s;
    animation-fill-mode: forwards; /* Сохранение конечного состояния после анимации */
    @keyframes slideUp {
      from {bottom: -100%; opacity: 1;}
      to {bottom: 0; opacity: 1;}
    }
  }
}

.mainBlock {
  transform: translateY(0rem) translateZ(0px);
}

.container {
  position: relative;
  z-index: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  height: 669px;
  transition: height 500ms cubic-bezier(0.33, 1, 0.68, 1) 0s;
}
.mainConteiner2 {
  position: relative;
  z-index: 0;
  overflow-y: hidden;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
  transform: translateX(0%) translateZ(0px);
}
.header {
  z-index: 20;
  position: absolute;
  top: 0px;
  left: 0px;
  right: 0px;
  height: 4.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-inline: 4.5rem;
  transition: box-shadow 120ms ease-in 0s, background-color 120ms ease-in 0s;
}
.content {
  position: relative;
  z-index: 1;
  flex: 1 1 0;
  padding-top: 4.5rem;
  overflow-y: auto;
}
.modalContentContainer {

}
.marketplaceInfoModal {
  padding-bottom: 5rem;
}
.title {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin: 0;
  padding: 0 1rem;
}
.description {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  margin: 1rem 0 0;
  color: #707173;
  padding: 0 1rem;
}
.howItWorks {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.subtitle {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin: 0;
  padding: 0 1rem;
}
.steps {
  margin: 0;
  list-style: none;
}
.step {
  color: #707173;
  padding: 0.7rem;
  padding-inline-start: 3.5rem;
  background-color: #f6f6f6;
  margin: 0.4rem 0.6rem;
  border-radius: 0.5rem;
  counter-increment: customCounter 1;
  list-style-position: inside;
  position: relative;
  min-height: 3.25rem;
  cursor: text;
}
.step:hover{
  background-color: #f6f6f6;
}

.footer {
  z-index: 100;
  position: absolute;
  bottom: env(safe-area-inset-bottom, 0);
  border-radius: 5px;
  left: 0;
  right: 0;
}
.visible{

}
.container2 {
  padding-inline: 1rem;
  padding-bottom: 1rem;
}
.innerContainer {
  position: relative;
  transition: box-shadow 120ms ease-in 0s;
  border-radius: 0.5rem;
}
.button {
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Segoe UI, Arimo, Open Sans, sans-serif;
  font-size: 1rem;
  font-stretch: 100%;
  font-style: normal;
  line-height: 1.5rem;
  min-height: 3.375rem;
  text-rendering: optimizelegibility;
  text-transform: none;
  width: 100%;
  font-weight: 700;
  color: #fff;
  cursor: pointer;
}
.background {
  background: #1b5e3b;
  z-index: 1;
  inset: 0;
  position: absolute;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
}


@media screen and (min-width: 640px) {
  .mainBlock {
    border-start-start-radius: 1rem;
    border-start-end-radius: 1rem;
    border-end-start-radius: 1rem;
    border-end-end-radius: 1rem;
    max-height: min(50rem, -24px + 100svh);
  }
  .container {
    border-start-start-radius: 1rem;
    border-start-end-radius: 1rem;
    border-end-start-radius: 1rem;
    border-end-end-radius: 1rem;
    max-height: min(50rem, -24px + 100svh);
  }
}

.closeBtnConteiner {
  z-index: 100;
  position: absolute;
  top: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  inset-inline-end: 1rem;
}

.buttonBlock {
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
}

.btnFakeBlock {
  background-color: #ededee;
  border-radius: 50%;
  inset: 0;
  position: absolute;
  transition: background-color .12s ease-out;
}

.btnConteinerInclude {
  align-items: center;
  border-radius: 50%;
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;
  z-index: 1;
}

.btnConteinerInclude:hover {
  background-color: #e3e3e3;
}

.imgCloseBtn {
  height: 1.25rem;
  width: 1.25rem;
  fill: #202125;
}

.mainConteiner {
  position: relative;
  z-index: 0;
  overflow-y: hidden;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}





@media screen and (min-width: 640px) {
  .mainBlock {
    --container-border-start-start-radius: 1rem;
    --container-border-start-end-radius: 1rem;
    --container-border-end-start-radius: 1rem;
    --container-border-end-end-radius: 1rem;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 1rem 0px, rgba(0, 0, 0, 0.12) 0px 1rem 1rem 0px;
    position: static;
    flex: 1 1 0;
    max-width: min(31.25rem, 90vw);
  }
}

@media screen and (max-width: 640px) {
  .container {
    top: 5.5rem;
  }
}
