.countryMainBody {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 1200px;
  padding: 130px 30px;
  width: 100%;
  background-color: var(--background-color);
  box-sizing: border-box;
}

.titleBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 3.125rem;
}

.title {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 2.875rem;
  line-height: 3.5rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  color: var(--cb-color-text);
  margin-top: 0;
}

.countrysBlock {
  display: flex;
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

.countrysConteiner {
  display: grid;
  flex: 1;
  grid-template-columns: repeat(auto-fill, minmax(225px, 1.2fr));
  grid-column-gap: 23px;
  grid-row-gap: 16px;
  animation: Ki3WkX .5s forwards;
  perspective: 1000px;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

//PC
@media screen and (max-width: 1023px) {
  .countryMainBody {
    padding: 6rem 30px;
  }
  .countrysBlock {
    display: flex;
    position: relative;
    margin: 0;
    padding: 0;
  }
  .countrysConteiner {
    grid-template-columns: 1fr;
    grid-row-gap: 8px;
  }
}

//TABLE
@media (max-width: 900px) {
  .titleBlock {
    margin-bottom: 1.5625rem;
  }
  .countrysBlock {
    flex-direction: column;
  }
}

//MOBILE
@media screen and (max-width: 639px) {
  .countryMainBody {
    padding: 40px 16px;
  }
  .titleBlock {
    margin-bottom: 1rem;
  }
  .title {
    font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
    font-variant-ligatures: common-ligatures;
    font-size: 1.75rem;
    line-height: 2.25rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    margin-bottom: 0;
  }
}