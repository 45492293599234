.mainConteiner {
  overflow: auto;
  height: 65vh;
  @media screen and (max-height: 700px) {
    height: 53vh;
  }
}
.conteiner {
  height: 100%;
}

.shopName {
  font-size: 1.1rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
}

.infoBlock {
  display: flex;
  justify-content: space-between;
  padding: 0.7rem 0;
  border-bottom: 1px solid rgba(169, 169, 169, 0.25);
}
.leftText {
  font-size: 0.8rem;
  min-width: 8rem;
}
.rightBlock {
  display: flex;
}
.rightText {
  padding-inline-start: 0.5rem;
  font-size: 0.8rem;
  width: 100%;
}
.btnEdit {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  box-sizing: border-box;
}
.btnPanelBlock {
  position: fixed;
  display: flex;
  justify-content: space-between;
  bottom: 5rem;
  left: 16px;
  right: 16px;
}
.saveBtn {
  width: 48%;
  height: 2.5rem;
  background-color: #1fc70a;
  color: white;
  border-radius: 0.5rem;
  font-weight: bold;
  font-size: 1rem;
}
.deleteBtn {
  width: 48%;
  height: 2.5rem;
  background-color: #FF4D4F;
  color: white;
  border-radius: 0.5rem;
  font-weight: bold;
  font-size: 1rem;
}
.inputFil {
  height: 2.2rem;
  line-height: 2rem;
  input {
    height: 0.5rem;
  }
}