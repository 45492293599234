.mainContainer {
  position: fixed;
  width: 100vw;
  inset: 0;
  color: var(--text-color);
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  z-index: 2;
  @media (max-width: 640px) {
    position: absolute;
    height: 100%;
    animation-name: slideUp;
    animation-duration: 1s;
    animation-fill-mode: forwards; /* Сохранение конечного состояния после анимации */
    @keyframes slideUp {
      from {top: 100%; opacity: 1;}
      to {top: 0; opacity: 1;}
    }
  }
}

.mainBlock {
  transform: translateY(0rem) translateZ(0px);
}

.container {
  position: relative;
  z-index: 0;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  background-color: rgb(255, 255, 255);
  height: 669px;
  transition: height 500ms cubic-bezier(0.33, 1, 0.68, 1) 0s;
}

@media screen and (min-width: 640px) {
  .mainBlock {
    border-start-start-radius: 1rem;
    border-start-end-radius: 1rem;
    border-end-start-radius: 1rem;
    border-end-end-radius: 1rem;
    max-height: min(50rem, -24px + 100svh);
  }
  .container {
    border-start-start-radius: 1rem;
    border-start-end-radius: 1rem;
    border-end-start-radius: 1rem;
    border-end-end-radius: 1rem;
    max-height: min(50rem, -24px + 100svh);
  }
}

.closeBtnConteiner {
  z-index: 100;
  position: absolute;
  top: 1rem;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  inset-inline-end: 1rem;
}

.buttonBlock {
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
}

.btnFakeBlock {
  background-color: #ededee;
  border-radius: 50%;
  inset: 0;
  position: absolute;
  transition: background-color .12s ease-out;
}

.btnConteinerInclude {
  align-items: center;
  border-radius: 50%;
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;
  z-index: 1;
}

.btnConteinerInclude:hover {
  background-color: #e3e3e3;
}

.imgCloseBtn {
  height: 1.25rem;
  width: 1.25rem;
  fill: #202125;
}

.mainConteiner {
  position: relative;
  z-index: 0;
  overflow-y: hidden;
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  background-color: #fff;
}

.mainInfoConteiner {
  display: flex;
  position: relative;
  z-index: 1;
  flex: 1 1 0%;
  padding-top: 0;
  overflow-y: auto;
  width: 100%;
}

.modalContentContainer {
  width: 100%;
  background-color: var(--background-color);
}

.venueInformationModal {
  padding: 0px 1rem;
  margin-top: 4rem;
  background-color: var(--background-color);
}

.header {
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
}

.largeHeader {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin: 0 0 0.5rem;
}
.openingTimes {
  display: flex;
  flex-direction: column;
}
.openingTimes2 {
  margin-top: 1.5rem;
}
.line {
  margin-top: 0.2rem;
}
.day {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  margin: 0;
  color: #707173;
}
.time {
  display: flex;
}
.timeRange{
  margin-inline-start: 0.25rem;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  margin: 0;
  color: #707173;
  text-align: end;
}

.description {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  white-space: pre-line;
  margin: 0;
}

.address {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}
.title {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin-bottom: 0.5rem;
}
.tableConteiner {
  width: 100%;
  padding: 0.5rem 1.5rem;
}

.info{
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  margin: 0;
  display: flex;
  justify-content: space-between;
}
.mapLink {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Segoe UI, Arimo, Open Sans, sans-serif;
  font-size: 1rem;
  font-stretch: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 1.5rem;
  text-rendering: optimizelegibility;
  text-transform: none;
}

.timeTable {
  width: 100%;
}

.deliveryInfo {
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
}

.deliveryTable {
  margin-top: 0.5rem;
  margin-bottom: 1rem;
  border-collapse: collapse;
}

.label{
  white-space: nowrap;
  margin-inline-end: 0.75rem;
}
.value {
  white-space: nowrap;
  color: #707173;
  text-align: end;
  font-feature-settings: "tnum";
  font-variant-numeric: tabular-nums;
}

.contact {
  display: flex;
  flex-direction: column;
  margin: 2rem 0;
  padding-bottom: 3rem;
}

.detail {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline-end: 1rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}
.detail1 {
  width: 100%;
  display: none;
}
.link {
  padding: 0;
  border: none;
  background: none;
  text-align: start;
  display: inline-flex;
  align-items: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  color: #1b5e3b;
  user-select: inherit;
  white-space: normal;
  cursor: pointer;
  text-decoration: none;
}
.supportButton {
  padding: 0;
  border: none;
  background: none;
  text-align: start;
  display: inline-flex;
  align-items: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  color: #1b5e3b;
  text-decoration: none;
  user-select: inherit;
  white-space: nowrap;
  cursor: pointer;
}
.contactDetails {

}

.termBlock {
  margin-top: 1.5rem;
  display: flex;
  flex-direction: column;
}
.termTitle {
  font-size: 1.5rem;
  font-weight: bold;
}
.termDescription {
  margin-top: 0.5rem;
}
.descTime {
  font-weight: bold;
  font-size: 1.2rem;
  text-decoration: underline;
  margin-right: 1.2rem;
}
.timesBlockUp {
  display: flex;
  justify-content: space-between;
}



@media screen and (min-width: 640px) {
  .mainBlock {
    --container-border-start-start-radius: 1rem;
    --container-border-start-end-radius: 1rem;
    --container-border-end-start-radius: 1rem;
    --container-border-end-end-radius: 1rem;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 1rem 0px, rgba(0, 0, 0, 0.12) 0px 1rem 1rem 0px;
    position: static;
    flex: 1 1 0;
    max-width: min(31.25rem, 90vw);
  }
}

@media screen and (max-width: 640px) {
  .mainBlock {
    width: 100%;
    height: 100%;
  }
  .container {
    width: 100%;
    height: 100%;
  }
}

