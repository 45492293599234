.mainConteiner {
  padding: 0.5rem 1rem;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.leftBlock {
  width: 40%;
  display: flex;
  flex-direction: column;
}
.middleBlock {
  display: flex;
  align-items: end;
  width: 50%;
}
.rightBlock {
  display: flex;
  align-items: end;
  width: 30%;
  padding-inline-start: 2rem;
}
.topBlock {
  color: #1fc70a;
  font-weight: 500;
}
.downBlock {

}