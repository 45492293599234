.testFooter {

}
fakeFooter {
  display: none;
}

@media screen and (max-width: 640px) {

  .footerBlock {
    display: none;
  }
}