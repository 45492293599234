.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 600px;
  margin: auto;
  position: relative;
}

.title {
  font-size: 24px;
  margin-bottom: 20px;
  text-align: center;
}

.error {
  color: red;
  font-size: 18px;
  text-align: center;
  margin-top: 10px;
}

.videoWrapper {
  position: relative;
  width: 320px;
  height: 320px;
  border-radius: 50%;
  overflow: hidden;
  background: #000;
}

.videoWrapper::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./person-line.svg');
  background-size: 60%;
  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
}
.video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; /* Обрезает и масштабирует изображение для заполнения контейнера */
  visibility: visible; /* Видимость элемента video */
}

.canvas {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.loading {
  font-size: 18px;
  text-align: center;
}

.success {
  font-size: 18px;
  color: green;
  text-align: center;
}

.button {
  background-color: #007bff;
  color: #fff;
  width: 100%;
  height: 3rem;
  font-size: 1.2rem;
  border-radius: 1rem;
  font-weight: bold;
  color: white;
  margin-top: 1.5rem;
}
.buttonDis {
  display: none;
}

.button:disabled {
  background-color: #6c757d;
  cursor: not-allowed;
}

.button:hover:not(:disabled) {
  background-color: #0056b3;
}
