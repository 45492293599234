.mainCont {
  margin-bottom: 2.5rem;
}

.mainBlock {
  position: relative;
}

.mainContent {
  position: relative;
  width: 100%;
}

.main{

}

@media screen and (min-width: 640px) {

}