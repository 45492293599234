.sliderBar {
  width: 100%
}
.sliderBarBlock {
  scrollbar-width: none;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  --slideTrackPadding: 8px;
  width: calc(100% + calc(16px * 2));
  padding: 1rem 16px;
  margin: -1rem calc(16px * -1);
  box-sizing: border-box;
}

.sliderConteiner {
  box-sizing: border-box;
  display: flex;
  overflow-y: auto;
  scrollbar-width: none;
  padding: 8px 1px;
  scroll-behavior: smooth;
}
.sliderConteiner::-webkit-scrollbar {
  display: none;
}
.fakeElem {
  margin-inline-start: 1.8rem;
  scroll-snap-align: start;
  display: none;
}
