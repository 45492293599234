.title {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.subTitle {
  display: flex;
  font-size: 1.2rem;
}

.subSubTitle{
  display: flex;
  font-size: 1rem;
  margin-bottom: 0.5rem;
}

.information {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5rem;
}

.income {
  margin: 1rem 0;
}

.loadPaymentButton {
  background-color: #1b5e3b;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  height: 3rem;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  color: white;
  width: 100%;
}