/* ProfileCard.module.css */

.profileCard {
  margin: 1.5rem auto;
  max-width: 900px;
  padding: 2.1875rem 1rem;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  grid-gap: 25px;
  margin-top: 60px;
}

.mainTitle {
  position: absolute;
  font-size: 1.8rem;
  font-weight: 750;
  top: 0.25rem;
  left: 3rem;
}

.container {
  padding: 1.25rem 1rem;
  position: relative;
  border-bottom: 0.5px solid rgba(128, 128, 128, 0.31);
}

.imageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}

.imageWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.profileImage {
  background: #fff;
  border: 4px solid #fff;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
  height: 24px;
  margin-bottom: .3rem;
  margin-right: 4px;
  overflow: hidden;
  width: 24px;
}

.activePhone {
  border: 3px solid green;
}

.uploadInput {
  width: 60px;
  height: 60px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 30;
}

.uploadLabel {
  color: var(--cb-color-text-brand);
  cursor: pointer;
  font-size: 1.07rem;
  padding: .5rem 1rem;
}

.description {
  color: #1b5e3b;
  font-size: 1rem;
}

.userInfo {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-inline-end: 0;
  margin-inline-start: 1.2rem;
}

.userName {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.userNameText {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin: 0px;
}

.userDetails {
  display: flex;
  flex-direction: row;
}

.detail {
  margin-right: 4rem;
  margin-inline-end: 4rem;
  margin-inline-start: 0;
}

.upTitle {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  margin: 0;
}

.upDescription {
  padding-top: .1rem;
  font-size: 1.1rem;
  font-weight: 700;
}

.downTitle {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: #707174;
  margin: 0;
}

.downDescription {
  padding-top: .1rem;
  font-size: 1.07rem;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 3rem;
}

.downConteiner {
  padding: 1rem;
}

.downBlock {
  margin-bottom: 2rem;
  margin-top: 2rem;
  overflow: hidden;
}

.downMainBlock {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.boxMain {
  flex: 3;
}

.infoRowLvl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3.5rem;
}
.infoBlock  {
  margin-bottom: 1rem;
}
.downText {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  color: #202125;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.downTextTwo {
  color: #202125a3;
}

.iconBlock {
  display: flex;
  flex: 2;
  justify-content: center;
  max-width: 164px;
  text-align: right;
}

.IconNextBlock {
  height: 100%;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.IconNext {
  rotate: (270deg);
  margin-top: 4px;
}

.cardContainerBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
}
.cardText {
  font-size: 1.1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  text-align: center;
}
.countMoney {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  margin-right: 5px;
}
.moneyCountBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
}
.img {

}
.imgWolt {
  width: 1.125rem;
  height: 1.125rem;
  opacity: 1;
}
.tokensMain {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.cardTextMain {
  font-weight: 700;
  line-height: 1.8rem;
  font-size: 1.1rem;
  margin-right: 0.5rem;
  margin-bottom: 0.1rem;
}

.selectRow {
  border-bottom: 1px solid #2021251f;
}

.contentRowBlock {
  display: flex;
  align-items: stretch;
  justify-content: space-between;
}

.nameRow {
  display: flex;
  align-items: center;
}

.nameRowCountry {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 28px;
  box-sizing: border-box;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  -webkit-transition: .4s;
  transition: .4s;
}

.slider:before {
  position: absolute;
  content: "";
  height: 24px;
  width: 24px;
  bottom: 2px;
  background-color: white;
  -webkit-transition: .4s;
  transition: .4s;
}

input:checked + .slider {
  background-color: #23d600;
}

input:focus + .slider {
  box-shadow: 0 0 1px #2196F3;
}

input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

@media screen and (max-width: 640px) {
  .downBlock {
    margin: 2rem 30px 0;
  }
  .iconBlock {

  }

  .userDetails {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  .detail {
    box-sizing: border-box;
    margin-right: 0rem;
    margin-bottom: 0.2rem;
  }

  .cardContainer {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
  }

  .downMainBlock {
    display: flex;
    flex-direction: column;;
  }
}
.activeBtn {
  width: 75%;
  height: 3rem;
  background-color: var(--blue-color);
  font-size: 1.2rem;
  border-radius: 1rem;
  font-weight: bold;
  color: white;
  margin-top: 1.5rem;
}

.inputName {
  height: 1.65rem;
  border: 1px solid rgba(169, 169, 169, 0.56);
  border-radius: 3px;
  background-color: var(--dark-grey-color);
  color: var(--text-color);
  outline: 0;
  outline-offset: 0;
  padding: 5px;
}
.inputName:focus {
  border: 1px solid rgba(169, 169, 169, 0.56) !important;
}
.inputName:hover {
  border: 1px solid rgba(169, 169, 169, 0.56);
}

.notActive {
  display: none;
}
.activeName {
  border: 1px solid green;
}
.activeName:focus {
  border: 1px solid green;
}

.svg {
  margin-top: 2rem;
  position: relative;
  top: 3rem;
  height: 150px;
  object-fit: cover;
}

.svg:after {
  content: "";
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;
  height: 100%;
  background-image: url('./account.svg');

  background-position: center;
  background-repeat: no-repeat;
  z-index: 1;
}


