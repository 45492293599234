.mainBlock {
}

.titleMenu {
  display: flex;
  justify-content: center;
  font-size: 1.5rem;
  margin-bottom: 0.5rem;
}

.lvl1 {
  max-width: 900px;
  position: relative;
}

@media screen and (max-width: 639px) {
  .mainBlock {
    background: white;
    padding-bottom: 1rem;
    border-radius: 10px;
    //animation: waveIn 1s ease-out forwards;
    //transform: translateY(100%);
    //@keyframes waveIn {
    //  0% {
    //    transform: translateY(100%);
    //  }
    //  100% {
    //    transform: translateY(0);
    //  }
    //}
  }
}
.blockMainNotFound {
  padding-top: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.titleNotFound {

}
.notFound {
  margin-top: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}