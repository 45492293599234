.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  position: relative;
  box-sizing: border-box;
  margin: 20px;
  height: 100%;
  background-color: white;
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 640px) {
    min-width: unset;
    width: 100%;
    min-height: 90%;
    background-color: rgb(255, 255, 255);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 0.75rem 0px, rgba(0, 0, 0, 0.12) 0px 0.75rem 0.75rem 0px;
    position: fixed;
    bottom: 0;
    height: fit-content;
    padding: 10px 10px;
    margin:0;
    border-radius: 10px 10px 0 0 ;
    bottom: 0;
    animation-name: slideUp;
    animation-duration: 0.5s;
    animation-fill-mode: forwards; /* Сохранение конечного состояния после анимации */
    @keyframes slideUp {
      from {bottom: -100%; opacity: 1;}
      to {bottom: 0; opacity: 1;}
    }
  }
}



.closeImgBtn {
  align-items: center;
  border-radius: 50%;
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;
  z-index: 1;
  color: #1b5e3b;
}

.closeImg {
  height: 1.25rem;
  width: 1.25rem;
  fill: #202125;
}

::-webkit-scrollbar {
  width: 5px;
  transition: background-color .2s linear, width .2s ease-in-out;
  -webkit-transition: background-color .2s linear, width .2s ease-in-out;
}

::-webkit-scrollbar-thumb {
  background-color: #1b5e3be0;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.button {
  color: white;
  font-size: 14px;
  @media (max-width: 850px) {
    font-size: 10.5px;
  }
}

.close-button {
  text-align: end;
  position: relative;
}

.header {
  margin-top: 10px;
  padding: 16px;
  font-size: 40px;
  color: black;
  @media (max-width: 850px) {
    font-size: 24px;
    padding: 12px;

  }
}

ul {
  list-style: none;
  padding: 0;
}

.element {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  min-height: 60px;
  &--burger {
    justify-content: flex-start;
  }
}
.element-boxes {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  min-height: 60px;
  border-bottom: 0.5px solid rgba(128, 128, 128, 0.67);
  &--burger {
    justify-content: flex-start;
  }
}

.element-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;

}
.element-description {
  font-size: 0.875rem;
  color: red;
}

.choose {
  margin-left: auto;
  padding: 10px 15px;
  background-color: #1b5e3b14;
  color: #1b5e3be0;
  border: 1px;
  border-radius: 8px;
  cursor: pointer;
}


.language {
  font-size: 16px;
  color: black;
  @media (max-width: 850px) {
    font-size: 12px;
  }
}

li {
  cursor: pointer;
  margin-bottom: 10px;
}

li:hover {
  background-color: #f0f0f0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 800px; /* Измените это значение на желаемую максимальную высоту списка */
  }
}

.fade-in {
  animation: fadeIn 0.2s ease-out;
}

.fakeSub {
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
  transition: background-color 120ms ease-out 0s;
  background: #1b5e3b;
}

.fakeSubTwo {
  position: absolute;
  inset: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textSub {
  flex: 1 1 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 150ms ease-in 0s, color 150ms linear 0s;
  border-radius: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

.image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.closeBtnConteiner {
  z-index: 100;
  position: absolute;
  right: 13%;
}

.buttonBlock {
  height: 2.5rem;
  width: 3rem;
  cursor: pointer;
}
.IconBack__icon {
  font-size: 1rem;
  font-weight: bold;
  margin-top: 1px;
}

.fakeBtnBlock {
  background-color: #20212514;
  border-radius: 50%;
  inset: 0;
  position: absolute;
  transition: background-color .12s ease-out;
}

.closeImgBtn {
  align-items: center;
  border-radius: 50%;
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;
  z-index: 1;
}

.closeImg {
  height: 1.25rem;
  width: 1.25rem;
  fill: #202125;
}

.titleBody {
  font-feature-settings: "kern", "ss01", "ss05", "ss07";
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-variant-ligatures: common-ligatures;
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 800;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin: 0px;
  padding: 0;
  padding-top: 2.5rem;
  padding-bottom: 0;
}

.imgBlocksSearch {
  display: block;
  margin-top: 1rem;
  position: relative;
}

.searchForm {
  position: relative;
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
  border: rgba(112, 113, 116, 0.3) 1px solid;
  background-color: rgba(188, 188, 188, 0.38);
  background-repeat: no-repeat;
  background-size: 30px;
  opacity: 0.65;
  text-indent: 10px;
  background-position: 10px 10px;
  outline:none;
}
.searchForm:focus {
  border: 1px solid #1b5e3b;
  opacity: 1;
}
.clearBtn {
  position: absolute;
  height: 30px;
  width: 30px;
  right: 1rem;
  top: 8px;
  font-weight: bold;
  color: darkgrey;
  border: 2px solid darkgrey;
  border-radius: 50%;
}

.filtersBlock {
  margin: 0px;
  padding: 0.5rem;
  overflow: hidden;
  border: none;
  position: relative;
}
.filters {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  flex-wrap: wrap;
  margin: 0px;
}
.description {
  padding: 0 1rem;
}
.closeBtnBlock {
  padding: 0px 1rem 0.5rem;
}
.closeBtnConteiners {
  position: relative;
  transition: box-shadow 120ms ease-in 0s;
  border-radius: 0.5rem;
}
.closeBtn {
  height: 2.5rem;
  width: 100%;
}
.closeBtnBlock1 {
  background: #ebf7fd;
  border-radius: 0.5rem;
  inset: 0;
  position: absolute;
}
.closeBtnBlock2{
  align-items: center;
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;
  z-index: 1;
  color: #1b5e3b;
}
.closeBtnBlock3{
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  border-radius: .5rem;
  display: flex;
  flex: 1;
  transition: opacity .2s ease-out, color .15s linear;
}

@import "../../../styles/variables";

$small-icon-size: 1rem;
$large-icon-size: 1.5rem;

.mainStyle {
  background-color: rgba(255, 255, 255, 0.68);
  border-radius: 60px;
  height: 40px;
  width: 40px;
}
.mainSvg {
  margin-top: 2px;
  fill: black !important;
}

.IconBack {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 3%;
  left: 2%;
  z-index: 99999999;
  background-color: rgba(112, 113, 116, 0.2);
  @media (max-width: 820px) {
    top: 1%;
  }
}
.groupTitle {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin: 1.5rem 0px 0.5rem;
  padding: 0px;
}

.addButton {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-inline-start: 0.625rem;
  padding-block: 1.5625rem;
  color: var(--text-color);
}

.plusIcon {
  width: 1rem;
  height: 1rem;
  fill: var(--text-color);
}

.paymentMethod {
  border-bottom: 1px solid #2021251f;
  width: 100%;
  min-height: 4.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: start;
  cursor: default;
  opacity: 1;
  pointer-events: unset;
}

.paymentMethodIcon {
  font-size: 1rem;
  width: 2.5rem;
}

.paymentMethodName {
  font-size: 1rem;
  flex-grow: 1;
  padding: 0px 1rem;
}

.paymentMethodDetails {
  display: flex;
  flex-grow: 0;
  padding: 0 1.5rem;
  font-size: 1rem;
}

.profileActionsButton {
  padding: 0;
  border: none;
  background-image: none;
  background-position: initial;
  background-size: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  position: relative;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  transition: background-color 120ms ease-out 0s;
  background-color: var(--other-background-color);
  cursor: pointer;
}

.otherImg {
  fill: var(--text-color);
  width: 0.75rem;
  height: 0.75rem;
}

.imagesBlock {
  display: flex;
  height: 3rem;
  margin-top: 1rem;
  justify-content: space-between;
}
.rowBlock {
  display: flex;
  height: 3rem;
  align-items: center;
}
.svgImage {
  height: 30px;
  width: 30px;
  color: #1b5e3b;
  fill: #1b5e3b;
}
.leftBlock {
  display: flex;
}
.textBlockImage {
  display: flex;
  flex-direction: column;
}
.topText {
  font-size: 1.1rem;
  font-weight: bold;
}
.downText {
  font-size: 0.85rem;
  color: #707174;
}
.IconNextBlock {
  height: 100%;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.IconNext {
  margin-top: 4px;
  fill: forestgreen;
}

.addAdressBlock {
  display: flex;
  margin-top: 1rem;
}

.plus {
  font-size: 2rem;
  font-weight: 400;
  margin-right: 1rem;
}

.plusText {
  line-height: 3rem;
  font-size: 1.2rem;
  font-weight: 500;
}

.optionalText {
  position: relative;
  top: 10px;
  opacity: 0.5;
}
.optionalText2 {
  position: relative;
  top: -5px;
  opacity: 0.5;
}
.downFields {
  display: flex;
  margin-top: 0.3rem;
  display: flex;
  justify-content: space-between;
}
.footerBtn {
  border: none;
  position: fixed;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-feature-settings: "kern";
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: 95%;
  cursor: pointer;
  min-height: 3.375rem;
  border-radius: 0.5rem;
  color: #fff;
  bottom: 1rem;
  box-sizing: border-box;
  background-color: #1b5e3b;
  z-index: 9999999;
}
.test {
  background-color: #1b5e3b;

}

.footerBtnCont {
  transition: background-color 120ms ease-out 0s;
  background: #1b5e3b;
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
  box-sizing: border-box;
}
.footerBtnBlock {
  position: absolute;
  inset: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.footerBtnMain {
  flex: 1 1 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 150ms ease-in 0s, color 150ms linear 0s;
  border-radius: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  box-sizing: border-box;
}
.itemsBlock {
  height: 100%;
  overflow: auto;
  margin-top: 1rem;
}
.productsTitle {
  width: 100%;
  height: 3.5rem;
  font-size: 1.5rem;
  font-weight: bold;
  display: flex;
  justify-content: center;
  align-items: center;
}