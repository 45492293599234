@import '../../styles/variables';

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

.modal-content {
  box-sizing: border-box;
  margin: 20px;
  min-width: 520px;
  max-height: 650px;
  background-color: var(--dark-bold-grey-color);
  padding: 20px;
  border-radius: 15px;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
  overflow-y: auto;
  display: flex;
  flex-direction: column;
  position: relative;

  @media (max-width: 640px) {
    min-width: unset;
    width: 100%;
    min-height: 400px;
    box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 0.75rem 0px, rgba(0, 0, 0, 0.12) 0px 0.75rem 0.75rem 0px;
    position: fixed;
    bottom: 0;
    height: fit-content;
    padding: 10px 10px;
    margin:0;
    border-radius: 10px 10px 0 0 ;
    bottom: 0;
    animation-name: slideUp;
    animation-duration: 0.5s;
    animation-fill-mode: forwards; /* Сохранение конечного состояния после анимации */
    @keyframes slideUp {
      from {bottom: -100%; opacity: 1;}
      to {bottom: 0; opacity: 1;}
    }
  }
}

.closeImgBtn {
  align-items: center;
  border-radius: 50%;
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;
  z-index: 1;
}

.closeImg {
  height: 1.25rem;
  width: 1.25rem;
  fill: #202125;
}

::-webkit-scrollbar {
  width: 5px;
  transition: background-color .2s linear, width .2s ease-in-out;
  -webkit-transition: background-color .2s linear, width .2s ease-in-out;
}

::-webkit-scrollbar-thumb {
  background-color: #1b5e3be0;
  border-radius: 10px;
}

::-webkit-scrollbar-track {
  background-color: #f1f1f1;
}

.button {
  color: white;
  font-size: 14px;
  @media (max-width: 850px) {
    font-size: 10.5px;
  }
}

.close-button {
  text-align: end;
  position: relative;
}

.header {
  margin-top: 10px;
  padding: 16px;
  font-size: 40px;
  color: black;
  @media (max-width: 850px) {
    font-size: 24px;
    padding: 12px;

  }
}

ul {
  list-style: none;
  padding: 0;
}

.element {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  min-height: 60px;
  &--burger {
    justify-content: flex-start;
  }
}
.element-boxes {
  display: flex;
  justify-content: space-between;
  gap: 1rem;
  align-items: center;
  min-height: 60px;
  border-bottom: 0.5px solid rgba(128, 128, 128, 0.67);
  &--burger {
    justify-content: flex-start;
  }
}

.element-wrapper {
  display: flex;
  flex-direction: column;
  gap: 5px;

}
.element-description {
  font-size: 0.875rem;
  color: $grey-link;
}

.choose {
  margin-left: auto;
  padding: 10px 15px;
  background-color: #1b5e3b14;
  color: #1b5e3be0;
  border: 1px;
  border-radius: 8px;
  cursor: pointer;
}


.language {
  font-size: 16px;
  color: black;
  @media (max-width: 850px) {
    font-size: 12px;
  }
}

li {
  cursor: pointer;
  margin-bottom: 10px;
}

li:hover {
  background-color: #f0f0f0;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    max-height: 0;
  }
  to {
    opacity: 1;
    max-height: 800px; /* Измените это значение на желаемую максимальную высоту списка */
  }
}

.fade-in {
  animation: fadeIn 0.2s ease-out;
}

.fakeSub {
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
  transition: background-color 120ms ease-out 0s;
  background: #1b5e3b;
}

.fakeSubTwo {
  position: absolute;
  inset: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.textSub {
  flex: 1 1 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 150ms ease-in 0s, color 150ms linear 0s;
  border-radius: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

.image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.closeBtnConteiner {
  z-index: 100;
  position: absolute;
  top: 8;
  right: 0.2rem !important;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  background-color: rgb(255, 255, 255);
  inset-inline-end: 1rem;
}

.buttonBlock {
  height: 2.5rem;
  width: 2.5rem;
  cursor: pointer;
}

.fakeBtnBlock {
  background-color: #20212514;
  border-radius: 50%;
  inset: 0;
  position: absolute;
  transition: background-color .12s ease-out;
}

.closeImgBtn {
  align-items: center;
  border-radius: 50%;
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;
  z-index: 1;
}

.closeImg {
  height: 1.25rem;
  width: 1.25rem;
  fill: #202125;
}

.titleBody {
  font-feature-settings: "kern", "ss01", "ss05", "ss07";
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-variant-ligatures: common-ligatures;
  font-size: 1.5rem;
  line-height: 2.5rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin: 0px;
  padding: 1rem;
}

.filtersBlock {
  margin: 0px;
  padding: 0.5rem;
  overflow: hidden;
  border: none;
  position: relative;
}
.filters {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  flex-wrap: wrap;
  margin: 0px;
}
.priceTitle {
  font-feature-settings: "kern", "ss01", "ss05", "ss07";
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-variant-ligatures: common-ligatures;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin: 0px;
  padding: 1.5rem 1rem 0.5rem;
}

.priceBody {
  height: auto;
  margin: 0px;
  padding: 0.5rem;
  overflow: hidden;
  border: none;
  position: relative;
}

.priceParent {
  display: flex;
  align-items: center;
  gap: 0.4rem;
  flex-wrap: wrap;
  margin: 0px;
}

.sortTitle {
  font-feature-settings: "kern", "ss01", "ss05", "ss07";
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-variant-ligatures: common-ligatures;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin: 0px;
  padding: 1.5rem 1rem 0.5rem;
}

.sortBody {
  height: auto;
  margin: 0px;
  padding: 0.5rem;
  overflow: hidden;
  border: none;
  position: relative;
}
.sortParent {
  display: flex;
  align-items: center;
  column-gap: 1rem;
  row-gap: 0.3rem;
  flex-wrap: wrap;
  margin: 0px;
}
.closeBtnBlock {
  padding: 0px 1rem 0.5rem;
}
.closeBtnConteiners {
  position: relative;
  transition: box-shadow 120ms ease-in 0s;
  border-radius: 0.5rem;
}
.closeBtn {
  height: 2.5rem;
  width: 100%;
}
.closeBtnBlock1 {
  background: var(--blue-btn-background-color);
  border-radius: 0.5rem;
  inset: 0;
  position: absolute;
}
.closeBtnBlock2{
  align-items: center;
  display: flex;
  inset: 0;
  justify-content: center;
  position: absolute;
  z-index: 1;
  color: var(--blue-btn-text-color);
}
.closeBtnBlock3{
  align-items: center;
  justify-content: center;
  overflow: hidden;
  text-overflow: ellipsis;
  word-wrap: break-word;
  border-radius: .5rem;
  display: flex;
  flex: 1;
  transition: opacity .2s ease-out, color .15s linear;
}