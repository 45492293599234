.paymentMethods {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  padding: 1rem;
}

.paymentMethodsList {
  display: flex;
  flex-direction: column;
  flex: 1 1 0%;
  padding: 1rem;
}

.title {
  font-variant-ligatures: common-ligatures;
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin-bottom: 1rem;
  padding: 0px;
}

.paymentGroup {
  /* стили для группы методов оплаты */
}

.groupTitle {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin: 1.5rem 0px 0.5rem;
  padding: 0px;
}

.addButton {
  width: 100%;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 1rem;
  margin-inline-start: 0.625rem;
  padding-block: 1.5625rem;
  color: var(--text-color);
}

.plusIcon {
  width: 1rem;
  height: 1rem;
  fill: var(--text-color);
}

.paymentMethod {
  border-bottom: 1px solid #2021251f;
  width: 100%;
  min-height: 4.75rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: start;
  cursor: default;
  opacity: 1;
  pointer-events: unset;
}

.paymentMethodIcon {
  font-size: 1rem;
  width: 2.5rem;
}

.paymentMethodName {
  font-size: 1rem;
  flex-grow: 1;
  padding: 0px 1rem;
}

.paymentMethodDetails {
  display: flex;
  flex-grow: 0;
  padding: 0 1.5rem;
  font-size: 1rem;
}

.profileActionsButton {
  padding: 0;
  border: none;
  background-image: none;
  background-position: initial;
  background-size: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  position: relative;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  transition: background-color 120ms ease-out 0s;
  background-color: var(--other-background-color);
  cursor: pointer;
}

.otherImg {
  fill: var(--text-color);
  width: 0.75rem;
  height: 0.75rem;
}

.lvl1 {
  margin: 1rem auto;
  max-width: 900px;
  padding: 2.1875rem 0;
  position: relative;
}
.lvl2 {
  padding: 1.25rem 0;
  position: relative;
}
.lvl3 {
  opacity: 1;
}
.lvl4 {
  margin-left: 30px;
  margin-right: 30px;
}
.lvl5 {
  opacity: 1;
}

@media screen and (max-width: 641px) {
  .lvl4 {
    margin-left: 16px;
    margin-right: 16px;
  }
  .paymentMethods {
    padding: 0;
  }
  .title {
    font-size: 2rem;
  }
}

.backer {
  display: none;
}

@media screen and (max-width: 640px) {
  .backer {
    display: block;
  }
  .paymentMethodsList {
    padding: 0;
  }
}
.paymentMethodName {
  color: var(--text-color);
}
