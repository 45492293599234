.countryButton {
  align-items: center;
  border: 1px solid #2021251f;
  border-radius: 4px;
  color: var(--text-color);
  display: flex;
  flex: 1;
  justify-content: space-between;
  padding: 1rem;
  position: relative;
  box-sizing: border-box;
}

.countryButton:hover {
  color: #1b5e3b;
  cursor: pointer;
}

.countryButton:after {
  border-radius: 4px;
  box-shadow: 0 4px 12px 0 rgba(32,33,37,.06);
  content: "";
  height: 100%;
  left: 0;
  opacity: 0;
  position: absolute;
  top: 0;
  transition: opacity .3s ease-in-out;
  width: 100%;
}

.textBlockC{
  font-size: 1.125rem;
  line-height: 1.75rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  display: flex;
  justify-content: center;
  align-items: center;
}

.textConteiner {
  align-items: center;
  display: flex;
  justify-content: flex-start;
}

.imgBlockC {
  display: flex;
  margin-right: 1rem;
}

.countryImg {
  width: 24px;
}

.imgCountry {
  width: 24px;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 0.1875rem 0px, rgba(0, 0, 0, 0.12) 0px 0.1875rem 0.1875rem 0px;
  border-radius: 0.25rem;
}

.arrowBlock {
  align-items: center;
  display: flex;
}

.arrowConteiner {
  display: inline-flex;
}

.arrowImg {
  height: 24px;
  width: 24px;
  box-sizing: border-box;
  padding: 4px;
}

@media (max-width: 700px){
  .countryButton {
    width: calc(50% - 11.5px);
  }
}

@media screen and (max-width: 639px){
  .countryButton {
    margin-right: 0;
    width: 100%;
  }
}