/* ProfileCard.module.css */

.profileCard {
  margin: 1.5rem auto;
  max-width: 900px;
  padding: 2.1875rem 0;
  position: relative;
  height: calc(100vh - 5rem);
}

.mainTitle {
  font-size: 1.8rem;
  font-weight: 750;
  padding: 0 1rem;
  left: 5.5rem;
  position: absolute;
  top: 0.25rem;
}
.downConteiner {
  padding: 1rem;
}
.infoBlock  {
  margin-bottom: 1rem;
}
.upperBlock {
  position: fixed;
  width: 32px;
  height: 32px;
  z-index: 999999;
  top: 1%;
  left: 3%;
}

.payBtn {
  background-color: rgba(35, 214, 0, 0.85);
  width: 90%;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  border-radius: 0.6rem;
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
  position: absolute;
  bottom: 2rem;
  margin-left: 1.2rem;
}

