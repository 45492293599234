.curierBlock {
  display: flex;
  align-items: center;
  justify-content: start;
  height: 3.1rem;
  border-radius: 1rem;
  border: 1px solid rgba(169, 169, 169, 0.3);
  padding: 1rem 1rem;
  position: relative;
  margin-top: 0.5rem;
}
.nameCurier {
  font-size: 1.25rem;
  font-weight: bold;
  margin-left: 1rem;
  color: rgba(169, 169, 169, 0.9);
}
.svgBlock {
  right: 1rem;
  position: absolute;
}