.closeButton {
  align-items: center;
  display: flex;
  color: #fff;
  margin: 0 0 0 auto;
  position: absolute;
  right: 1rem;
  top: 1rem;
  font-size: 1rem;
  background: none;
  border: 0;
  z-index: 99;
  cursor: pointer;
}

.closeButtonIcon {
  background-color: #fff;
  border-radius: 50%;
  box-shadow: 0 0 8px 0 rgba(32,33,37,.12);
  height: 3.75rem;
  justify-content: center;
  margin: 2px;
  transition: all .15s ease-out;
  width: 3.75rem;
  align-items: center;
  display: flex;
  background-color: rgba(239, 237, 237, 0.5);
}

.closeButtonSvg {
  height: 1.25rem;
  width: 1.25rem;
}

@media screen and (max-width: 639px) {
  .closeButtonIcon {
    height: 2rem;
    width: 2rem;
  }
  .closeButtonSvg {
    height: 1rem;
    width: 1rem;
  }
}
