
.modalContainer {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.modalContainerBlock {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: rgba(86, 80, 89, 0.8);
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.productsCategoryModal {
  z-index: 30;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 636px;
  border-radius: 1rem;
  overflow: hidden;
  max-width: 90%;
  max-height: 90%;
  margin: 0 auto;
  transform: scale(1);
  background-color: var(--background-color);
}
.productsCategoryTextBlock {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: var(--background-color);
  -webkit-box-shadow: 0 1px 0 0 rgba(216, 211, 219, 0.52);
  box-shadow: 0 1px 0 0 rgba(216, 211, 219, 0.52);
  color: var(--text-color);
  font-weight: 500;
  font-family: Roboto, sans-serif;
  padding: 18px 24px;
  font-size: 18px;
  letter-spacing: 0.2px;
  line-height: 22px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  z-index: 1;
}
.productsCategoryTextImg {
  position: relative;
  width: 24px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
}
.infoBlock {
  padding: 1rem;
  width: 100%;
  display: flex;
  flex-direction: column;
}
.inputCheck {
  position: relative;
  display: flex;
}
.checkbox {
  width: 14px;
  height: 14px;
}
.label {
  margin-left: 1rem;
}
.checkBoxCont {
  margin-top: 1rem;
}

.createBtn {
  width: 100%;
  height: 3rem;
  background-color: #1b5e3b;
  color: white;
  border-radius: 0.5rem;
  margin: 0.5rem 0;
  font-size: 1.1rem;
  font-weight: bold;
  margin-top: 1rem;
  cursor: pointer;
}

.inactive {
  background-color: #8e8e8e;
  cursor: none;
}

.sendCode {
  background-color: #1b5e3b;
  width: 3.4rem;
  height: 2rem;
  border-radius: 1rem;
  color: white;
  font-weight: bold;
}
.sendCodeBlock {
  width: 3.4rem;
  height: 2rem;
  border-radius: 1rem;
  color: white;
  font-weight: bold;
  background-color: var(--grey-text-color);
}
