.madolMain {
  position: absolute;
  height: 100%;
  width: 100vw;
  z-index: 99999999999;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
}
.modalConteiner {
  position: sticky;
  top: calc(50% - 12rem);
  height: 12rem;
  width: 85%;
  border-radius: 1rem;
  background-color: white;
  padding: 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.topPanel {
  display: flex;
  justify-content: end;
}
.mainConeiner {
  display: flex;
  flex-direction: column;
}
.textInfo {
  font-size: 0.7rem;
  color: rgba(0, 0, 0, 0.4);
  margin-bottom: 7px;
}
.fieldInp {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  appearance: none;
  box-shadow: none;
  box-sizing: border-box;
  width: 100%;
  color: var(--text-color);
  transition: border 0.2s linear 0s;
  border-radius: 0.5rem;
  border: 2px solid var(--dark-grey-color);
  padding: 0.55rem 0.575rem;
  background: var(--background-color);
  @media (max-width: 640px) {
    line-height: 0.5rem;
    font-size: 0.7rem;
  }
  outline: none;
  margin-top: 0.5rem;
}
.textTitle {
  display: flex;
  justify-content: center;
  font-weight: bold;
  font-size: 1.2rem;
  margin-bottom: 0.5rem;
}
.btnActive {
  border: none;
  position: relative;
  z-index: 0;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  max-width: 100%;
  cursor: pointer;
  padding: 0px 1rem;
  min-height: 3.375rem;
  border-radius: 0.5rem;
  background-color: #2990c3;
  color: var(--blue-btn-text-color);
  @media (max-width: 640px) {
    font-size: 0.7rem;
    line-height: 1rem;
    min-height: 2.5rem;
  }
  margin-top: 0.75rem;
  width: 100%;
}
.textSub {
  flex: 1 1 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 150ms ease-in 0s, color 150ms linear 0s;
  border-radius: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  width: 100%;
}
.errorText {
  color: #FF4D4F;
  font-weight: bold;
  font-size: 0.85rem;
  display: flex;
  justify-content: center;
}