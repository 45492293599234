.mainConteiner {
  height: 105px !important;
  padding: 1rem;
  border: 1px solid rgba(169, 169, 169, 0.2);
  border-radius: 4px;
  cursor: pointer;
}
.topProfitBlock {
  height: 50%;
  display: flex;
  justify-content: space-between;
}
.downProfitBlock {
  height: 50%;
  display: flex;
  justify-content: space-between;
  width: 60%;
}
.profitBlock {
  display: flex;
  flex-direction: column;
}
.profitTitle{
  font-size: 0.8rem;
  color: var(--grey-text-color);
}
.profitCount {
  font-weight: bold;
}
.shiftBlock {
  color: #1b5e3b;
  font-weight: bold;
}
.shopName {
  font-weight: bold;
  font-size: 1.1rem;
}
