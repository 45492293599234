.mainPage {
  display: block;
  unicode-bidi: isolate;
  flex: 1 1 0;
  z-index: 0;
  margin-top: 0;
}

.mapBlock {
  width: 100%;

  margin-bottom: -23px;
  height: 16vh;
}
.shadowBlock {
  width: 100%;
  box-shadow: inset 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 5px 0 0 0 rgba(255, 255, 255, 0.25);
  height: 15.5rem;
  z-index: 999999;
  position: absolute;
}

.mapBlock .ymaps-2-1-79-map-copyrights-promo{
  display: none;
}

.tabsContainer {
  border-radius: 12.5rem;
  width: auto;
  background-color: rgba(32, 33, 37, 0.08);
  display: flex;
  justify-content: center;
  padding: 0.25rem;
  height: 3rem;
  position: relative;
  margin-top: 1rem;
  @media screen and (max-width: 640px) {
    height: 2.25rem;
    padding: 0.1rem;
  }
}

.tabs {
  display: flex;
  height: 2rem;
  width: 100%;
  justify-content: space-between;
}
.tabButton {
  width: 254px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;

  @media (max-width: 640px) {
    width: 50%;
    padding: 5px;
    text-decoration: none;
  }
}

.tabButton:hover {
  text-decoration: underline;
  @media (max-width: 640px) {
    text-decoration: none;
  }
}

.active {
  background-color: #fff;
  font-weight: bold;
  border-radius: 12.5rem;
  width: 100%;
}

@media screen and (max-width: 1023px) {
  .downBlock {
    display: block;
  }
}
.backer {
  display: none;
}
@media screen and (max-width: 640px) {
  .backer {
    position: fixed;
    width: 100%;
    z-index: 9999999;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 1rem 0 0 1rem;
  }
}

.iBlock {
  height: 40px;
  width: 40px;
  border-radius: 4rem;
  background-color: rgba(112, 113, 116, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  margin-top: 0px;
  font-weight: bold;
}
.lineClose{
  border-bottom: 2px solid darkgray;
  height: 1rem;
  width: 4rem;
  border-radius: 0.1rem;
  margin-bottom: 1rem;
}

.mainConteiner {
  width: 100%;
  padding: 1rem 1rem 2rem 1rem;
  box-sizing: border-box;
  background-color: var(--background-color);
  position: relative;
  border-radius: 35px 35px 0 0;
  transition: max-height 0.5s ease-in-out, opacity 0.5s ease-in-out, transform 0.5s ease-in-out;
  overflow: hidden;
  min-height: 90vh;
  opacity: 1;
  transform: translateY(0);

  &.hidden {
    max-height: 0;
    opacity: 0;
    transform: translateY(100%); /* Move down when hidden */
  }
}
.mainBoxBlock {
  margin-bottom: 1rem;
}
.imagesBlock {
  display: flex;
  height: 3rem;
  margin-top: 0.5rem;
  justify-content: space-between;
}
.rowBlock {
  display: flex;
  height: 3rem;
  align-items: center;
}
.svgImage {
  height: 30px;
  width: 30px;
  color: #1b5e3b;
  fill: #1b5e3b;
}
.leftBlock {
  display: flex;
}
.textBlockImage {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}
.topText {
  font-size: 0.85rem;
  font-weight: bold;
}
.downText {
  font-size: 0.7rem;
  color: #707174;
}
.IconNextBlock {
  height: 100%;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.IconNext {
  rotate: (270deg);
  margin-top: 4px;
}

.img-blocks {
  display: block;
  margin: 0 auto;
  height: 3rem;
}

.imgBlocksSearch {
  display: block;
  height: 3rem;
  margin-top: 1rem;
}

.imgBlocksSearchPhone {
  display: block;
  height: 3rem;
  margin-top: 1rem;
}

.searchForm {
  position: relative;
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
  border: rgba(112, 113, 116, 0.3) 1px solid;
  background-color: rgba(188, 188, 188, 0.38);
  background-image: url("https://static-00.iconduck.com/assets.00/message-icon-512x463-tqzmxrt7.png");
  background-repeat: no-repeat;
  background-size: 30px;
  opacity: 0.65;
  text-indent: 50px;
  background-position: 10px 10px;
  outline: none;
}

.searchFormPhone {
  position: relative;
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
  border: rgba(112, 113, 116, 0.3) 1px solid;
  background-color: rgba(188, 188, 188, 0.38);
  background-image: url("https://uxwing.com/wp-content/themes/uxwing/download/communication-chat-call/phone-call-icon.png");
  background-repeat: no-repeat;
  background-size: 25px;
  opacity: 0.65;
  text-indent: 50px;
  background-position: 10px 10px;
  outline: none;
  outline: none;
}

.paymentBlock {
  margin-top: 1.5rem;
}
.titlePayment {
  font-size: 1.3rem;
  font-weight: bold;
}

.paymentBtn {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border: 1px solid rgba(169, 169, 169, 0.55);
  border-radius: 0.3rem;
  padding: 1rem 0.6rem;
  margin-top: 1rem;
}
.promoBtn {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 1rem 0.6rem;
  margin-top: 0.5rem;
  padding-left: 0;
}
.customSvg {
  color: white;
  background-color: #1b5e3b;
  height: 1.1rem;
  width: 1.5rem;
  font-weight: bold;
  border-radius: 3px;
}
.mailLeftBlBtn {
  display: flex;
}
.paymentBtnLeftBLock {
  display: flex;
  justify-content: center;
  margin-top: 0;
  margin-inline-end: 1rem;
  margin-inline-start: 0;
  position: relative;
  vertical-align: middle;
  width: 24px;
}
.paymentBtnLeftImg {
  fill: #202125;
  height: 24px;
  margin-top: 3px;
  width: 24px;
}
.paymentBtnMiddle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 0;
}
.paymentBtnMiddleLeft {
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.9rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 600;
}
.paymentBtnMiddleRight {
  font-size: 0.75rem;
  opacity: 0.5;
}
.paymentBtnRight {
  align-self: center;
  display: flex;
  padding: 0;
  color: rgba(112, 113, 115, 0.38);
  font-size: .8125rem;
  text-align: right;
}
.paymentBtnRightImg {
  color: #202125;
  height: 24px;
  transform: rotate(0deg);
  transition: transform .15s ease-out;
  width: 24px;
}

.priceInBlock {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.priceInUp {
  font-size: 1.3rem;
  font-weight: bold;
}
.priceInDown {
  font-size: 0.9rem;
  opacity: 0.5;
}
.titleFees {
  color: #1b5e3b;
  font-weight: 500;
}
.upMiddleBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0;
}

.upMiddleBlockText {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  display: block;
  margin: 0;
  padding: 0 0 0.3125rem;
}
.costList {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.excludedTitle {
  font-size: 0.75rem;
  opacity: 0.5;
}

.upMiddleBlockDown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.4rem;
}

.excludedDeliveryBlock {
  padding-top: 0.6rem;
}

.footerBtn {
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  cursor: pointer;
  min-height: 3.375rem;
  border-radius: 0.5rem;
  width: 92%;
  color: #fff;
  bottom: 2rem;
  box-sizing: border-box;
  background-color: #1b5e3b;
  position: fixed;
}
.test {
  background-color: #1b5e3b;

}

.footerBtnCont {
  transition: background-color 120ms ease-out 0s;
  background: #1b5e3b;
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
  box-sizing: border-box;
}

.fakeDisc {
  opacity: 0.5;
  margin-right: 1rem;
  text-decoration: line-through;
}

.footerBtnBlock {
  position: absolute;
  inset: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.footerBtnMain {
  flex: 1 1 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 150ms ease-in 0s, color 150ms linear 0s;
  border-radius: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  box-sizing: border-box;
}
.fakeDownBlock {
  height: 8rem;
}

.good {
  background-color: #23d600;
}
.error {
  background-color: #FF4D4F;
}

.btnBlocks {
  position: fixed;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: 92%;
  cursor: pointer;
  min-height: 3.375rem;
  border-radius: 0.5rem;
  bottom: 1rem;
  box-sizing: border-box;
  margin-bottom: 3.5rem;
}

.chatBlock {
  margin-top: 1rem;
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #1b5e3b;
}

.chatText {
  color: white;
  font-weight: bold;
  font-size: 1rem;
}

.shopName {
  &__title {
    font-size: 25px;
    font-weight: 800;
    color: var(--blue-btn-background-color);
  }
  &__description {
    font-size: 25px;
    font-weight: 800;
    color: var(--text-color);
  }
}

.statusBlock {
  margin: 0.5rem 0;
  font-size: 0.9rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  font-weight: 500;
  background: #1b5e3b;
  padding: 0.175rem 0.75rem;
  border-radius: 1rem;
  color: white;
  transition: background 100ms linear 0s, color 100ms linear 0s, outline 100ms linear 0s;
  text-transform: capitalize;
  white-space: nowrap;
  cursor: pointer;
  width: fit-content;
}
.delivery--address {
  display: flex;
  align-items: center;
  grid-gap: 1rem;
}
.dotBlue {
  content: '•';
  font-weight: bold;
  color: #1b5e3b;
  font-size: 30px;
  margin-right: 5px;

}
.dotGreen {
  content: '•';
  font-weight: bold;
  color: #2fae23;
  font-size: 30px;
  margin-right: 5px;

}
.deliveryAddressText {
  display: inline-block;
  margin-right: 5px;
}

.salary {
  margin-bottom: 2rem;
  .salaryMoney {
    font-size: 25px;
    font-weight: 800;
    text-align: center;

  }
  .salaryText {
    color: var(--grey-text-color);
    text-align: center;
  }
}

.footerBtnCancel {
  position: absolute;
  font-weight: bold;
  font-size: 1.1rem;
  background-color: var(--background-color);
  display: flex;
  align-items: center;
  justify-content: center;
  color: red;
  cursor: pointer;
  top: 1rem;
  right: 1rem;
  z-index: 12222222;
  border-radius: 0.5rem;
  padding: 0.3rem 1rem;
}

.itemProduct {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //border: 0.4px solid #2990c3;
  border-radius: 0.5rem;
  padding: 5px 10px;
  background-color: var(--blue-btn-text-color);
  color: var(--text-color);
}

.header {
  margin-top: 2rem;
  font-size: 1.5rem;
  letter-spacing: 1px;
}

.supportButton {
  margin-top: 1rem;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--blue-color);
  grid-gap: 1rem;
}

.editTimeBlock {
  background-color: rgba(255, 50, 52, 0.44);
  border-radius: 0.7rem;
  height: 3.5rem;
  display: flex;
  justify-content: center;
  align-items: center;
}
.editTime {
  color: white;
  font-weight: bold;
  font-size: 1.2rem;
}
.infoRowLvl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
  margin-bottom: 1rem;
}
.infoBlock  {
  margin-bottom: 1rem;
}

.swipeButton {

}
.statusBadge {
  background-color: #1b5e3b;
  border-radius: 1rem;
  padding: 0.2rem;
  max-width: 6.5rem;
  color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  margin: 0.75rem 0;
  font-size: 0.85rem;
}
.titleDetail {
  font-size: 1.2rem;
  font-weight: bold;
  padding-bottom: 1rem;
}
.bodyCarts {
  display: flex;
  flex-direction: column;
}
.itemCard {
  padding: 0.5rem 0rem;
}
.bigBox {
  width: 16px;
  height: 16px;
}
.rowInputItem{
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.boxHashTag{
  display: flex;
  align-items: center;
  margin: 0.5rem 0;
}
.tagTitle{
  margin-left: 0.5rem;
}
.detailTitle{
  margin-top: 1rem;
  font-size: 1.2rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
}
.downBtnChat {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3rem;
  background-color: rgba(255, 50, 52, 0.44);
  border-radius: 1rem;
  width: 100%;
  margin: 1rem 0;
}
.downBtnChat2{
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 0.5rem;
  height: 3rem;
  margin-bottom: 1.5rem;
  margin-top: 0.5rem;
  font-size: 1.1rem;
  font-weight: 500;
  color: white;
  width: 100%;
}
.titleBtnChat2{
  margin-left: 0.5rem;
  color: #1b5e3b;
}
.titleBtnChat{
  color: white;
  font-size: 1rem;
  font-weight: bold;
}