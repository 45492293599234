.header {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
  grid-gap: 16px;
  @media (min-width: 641px) {
    justify-content: space-between;
  }
}

.edit  {
  font-size: 16px;
  color:#1b5e3b;
  text-transform: none;
}
.vidjetOrderBlock {
  width: 147px;
  display: flex;
  align-items: center;
  @media (min-width: 641px) {
    width: initial;
  }
}

.orderTitle {
  @media (max-width: 641px) {
    position: relative;
  }
}
.vidjetOrder {
  font-size: 0.9rem;
  font-weight: 600;
  border-radius: 7px;
  background-color: #1b5e3b;
  border: 7px solid #1b5e3b;
  color: white;
}
.title {
  padding-left: 0.5rem;
  font-size: 32px;
  line-height: 3.5rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin: 0;
  overflow-wrap: break-word;
  min-width: 0;
  display: flex;
  align-items: center;
  word-wrap: break-word;
  justify-self: start;
}

.basket {
  z-index: 9999999;
  background-color: var(--dark-bold-grey-color);
  ::-webkit-scrollbar {
    width: 0;
  }
}
.headerContent {
  z-index: 99999999;
  padding: 1rem 1rem 0.5rem 1rem;
  width: calc(100vw - 5px);
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.addonRowBlock {
  display: flex;
  align-items: center;
}

@media screen and (max-width: 640px) {
  .title {
    font-size: 1.5rem;
  }
}