.downRootBlock {
  margin: 6.25rem auto 0;
  max-width: 1200px;
  padding: 0 30px;
  text-align: center;
  @media screen and (max-width: 639px){
    margin-top: 4rem;
  }
}


.rootTitle {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 2.875rem;
  line-height: 3.5rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin-top: 2.5rem;
  padding: 0 1.5rem;
  display: block;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
  margin-inline-start: 0;
  margin-inline-end: 0;
  unicode-bidi: isolate;

  @media screen and (max-width: 639px) {
    font-feature-settings: "kern", "ss01", "ss05", "ss07";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
    font-variant-ligatures: common-ligatures;
    font-size: 1.75rem;
    line-height: 2.25rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    margin-top: 2rem;
  }
}

.downRootConteiner{
  align-content: stretch;
  display: flex;
  margin-top: 2.5rem;

  @media screen and (max-width: 1023px) {
    display: block;
  }

  @media screen and (max-width: 639px) {
    margin-top: 2rem;
  }
}



