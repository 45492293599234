.mainConteiner {
  position: absolute;
  background-color: #fff;
  z-index: 2;
  border-radius: 0.5rem;
  min-height: 1.75rem;
  top: 60px;
  right: 320px;
  box-shadow: rgba(0, 0, 0, 0.06) 0 0 0.125rem 0px, rgba(0, 0, 0, 0.12) 0 0.125rem 0.125rem 0;
  transition: box-shadow 0.3s cubic-bezier(0.45, 0, 0.55, 1) 0s, background 100ms ease-out 0s;
  cursor: pointer;
}

.mainBlock {
  max-width: 31.25rem;
  padding: 0.75rem;
  display: flex;
  flex-direction: column;
}

.elem {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
  border-radius: 1rem;
  color: #202125;
  background-color: transparent;
  display: block;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: fit-content;
  transition: color 100ms linear 0s, background 100ms linear 0s;
  outline: none;
  text-decoration: none;
}
