.navbar {
  display: flex;
  justify-content: center;
  gap: 10px;
  padding: 10px;
}

.nav-link {
  display: flex;
  gap: 5px;
  text-decoration: none;
  color: var(--grey-text-color);
  padding: .5rem 1rem;
  border-radius: 5rem;
  transition: background-color 0.3s;
  &:hover {
    background-color: var(--grey-border-color);
    color: var(--text-color);
  }
}
.active {
  display: flex;
  gap: 5px;
  text-decoration: none;
  padding: .5rem 1rem;
  border-radius: 5rem;
  transition: background-color 0.3s;
  background-color: var(--blue-btn-background-color);
  color: var(--blue-btn-text-color);
}
