.mainConteiner {
  position: absolute;
  top: calc(50% - 3.625rem);
  padding: 0;
  pointer-events: none;
  z-index: 2;
  inset-inline-end: 0.75rem;
}

.mainBlock {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  position: relative;
  border-start-end-radius: 0.5rem;
  border-end-start-radius: 1.5rem;
  display: inline-flex;
  background-color: #1b5e3b14;
  color: #1b5e3b;
  transition: background-color 150ms linear 0s, color 150ms linear 0s;
}

.mainBlock:before {
  content: "";
  z-index: -1;
  position: absolute;
  inset-inline-start: 0;
  border-start-end-radius: 0.5rem;
  border-end-start-radius: 1.5rem;
  width: 100%;
  height: 100%;
  background-color: #fff;
}

.mainBtn {
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  border-radius: 0.25rem;
  height: 2.75rem;
}

.img {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 1.8rem;
  height: 1.8rem;
  padding: 0.5rem;
  background-color: transparent;
  fill: #1b5e3b;
  transition: background-color 50ms linear 0s, transform 50ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  transform: scale(1);
}

@media screen and (max-width: 640px) {
  .img {
    width: 1.6rem;
    height: 1.6rem;
  }
}
