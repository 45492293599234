
.nav-bar {
  display: flex;
  justify-content: space-around;
  grid-gap:4px;
  height: 88px;
  align-items: center;

  @media (max-width: 640px) {
    background-color: var(--other-background-color);
    color: var(--text-color);
    box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 0.75rem 0px, rgba(0, 0, 0, 0.12) 0px 0.75rem 0.75rem 0px;
    position: fixed;
    left: 0px;
    width: 100%;
    height: 40px;
    z-index: 10000;
    padding: 10px 0;
    bottom: -1px;
  }
}

.link {
  position: relative;
  display: inline-flex;
  align-items: center;
  white-space: nowrap;
  border-radius: 5rem;
  text-decoration: none;
  transition: box-shadow 250ms ease-out 0s, background 150ms ease-out 0s, color 150ms ease-out 0s;
  padding: 0.5rem 1rem;
  grid-gap:8px;
  color: #202125A3;
  height: 36px;
  box-sizing: border-box;
  font-weight: 400;

  @media (max-width: 640px) {
    padding: 0.2rem 0.75rem;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    align-items: center;
    height: 45px;
    grid-gap: 1px;
    max-height: 50px;
    max-width: 80px;
    font-size: 0.7rem;
    width: 18%;
  }
}
.dark {
  color: #BBBBBB;
}

.active {
  color: white;
  background-color: rgb(0, 157, 224);
  @media (max-width: 640px) {
    color: rgb(0, 157, 224);
    background-color: transparent;
  }
}
.footer {
  @media (max-width: 640px) {
    margin-bottom: 100px;
  }
}

@media (max-width: 640px) {
  .nav-bar{
    width: 100%;
    height: 7%;
  }
  .bugIcon {
    bottom: 2px;
  }

}