.cardBlock {
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-right: 24px;
  margin-inline-end: 24px;
  margin-inline-start: 0;
  text-align: center;
  transition: box-shadow .2s ease-in;
  background: #2021250a;
}

.fakeBlock {
  background-position: 50% 0;
  background-size: cover;
  height: 300px;
  width: 100%;
}

.contentTextCard {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 37px 39px;
}

.textCard {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  padding-bottom: 1rem;
  margin: 0;
}

.linkBlock {
  margin: auto auto 10px;
  display: inline-block;
  font-size: 14px;
  font-weight: 500;
  overflow: hidden;
  padding-bottom: 4px;
  position: relative;
  text-decoration: none;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: #1b5e3b;
  cursor: pointer;
}

.linkBlock:after {
  transform: translateZ(0);
  border-bottom: 1px solid #1b5e3b;
  bottom: 0;
  content: "";
  left: 0;
  position: absolute;
  transition: transform .4s cubic-bezier(.01,.21,.35,1);
  width: 100%;
  box-sizing: inherit;
}