.container {
  max-width: 800px;
  background-color: white;
  height: 100vh;
  width: 100vw;
  position: absolute;
  top: 0;
  margin: 0 auto;
  padding: 2rem;
  font-family: 'Arial', sans-serif;
  line-height: 1.6;
  color: #333;
  z-index: 999999999;
}

.title {
  color: #2c3e50;
  border-bottom: 2px solid #eee;
  padding-bottom: 0.5rem;
  margin-bottom: 1rem;
}

.lastUpdated {
  font-style: italic;
  color: #7f8c8d;
  margin-bottom: 2rem;
}

.section {
  margin-bottom: 2rem;

  &Title {
    color: #3498db;
    margin: 2rem 0 1rem;
  }

  &Content {
    padding-left: 1rem;
  }
}

.list {
  padding-left: 1.5rem;
  margin: 1rem 0;

  li {
    margin-bottom: 0.5rem;
  }
}

.test {
  color: #e74c3c;
  font-weight: 500;
}