.mainBlock {
  margin-top: 10px;
  background-color: var(--background-color);
}

@media screen and (max-width: 639px) {
  .mainBlock {
    padding: 0 0 20px 0;
    border-radius: 10px;
    //animation: waveIn 1s ease-out forwards;
    //transform: translateY(100%);
    //@keyframes waveIn {
    //  0% {
    //    transform: translateY(100%);
    //  }
    //  100% {
    //    transform: translateY(0);
    //  }
    //}
  }
}