/* ResetPasswordModal.module.css */

.mainModalConteiner {
  z-index: 9999;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(5px);
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5rem;
  animation: fadeIn 0.3s ease;
}

@keyframes fadeIn {
  from { opacity: 0; }
  to { opacity: 1; }
}

.formaBlock {
  margin: 0;
  display: flex;
  flex-direction: column;
  background: white;
  padding: 1rem;
  border-radius: 1rem;
  max-width: 450px;
  width: 100%;
  box-shadow: 0 10px 25px rgba(0, 0, 0, 0.1);
  animation: slideUp 0.3s ease;
}

@keyframes slideUp {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

.closeImgBtn {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 2rem;
  width: 2rem;
  border-radius: 50%;
  background: rgba(0, 0, 0, 0.05);
  transition: background 0.2s ease;
}

.closeImgBtn:hover {
  background: rgba(0, 0, 0, 0.1);
}

.closeImg {
  height: 1rem;
  width: 1rem;
  fill: #555;
}

.buttonBlock {
  height: 2rem;
  width: 2rem;
  cursor: pointer;
  background: none;
  border: none;
  padding: 0;
  outline: none;
}

.resetConteiner {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
}

.resetTitle {
  font-weight: 600;
  font-size: 1.5rem;
  color: #333;
  text-align: center;
  flex: 1;
}

.descriptionText {
  color: #666;
  font-size: 0.95rem;
  line-height: 1.4;
  margin-bottom: 1.5rem;
  text-align: center;
}

.fieldEmail {
  margin-bottom: 1.25rem;
  position: relative;
}

.fieldInp {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", sans-serif;
  font-size: 1rem;
  line-height: 1.5;
  appearance: none;
  box-sizing: border-box;
  width: 100%;
  color: #333;
  transition: all 0.2s ease;
  border-radius: 0.5rem;
  border: 1px solid #ddd;
  padding: 1rem;
  background: #fff;
  outline: none;
}

.fieldInp:focus {
  border-color: #1b5e3b;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
}

.fieldInp:focus + .lable,
.fieldInp:not(:placeholder-shown) + .lable {
  transform: translateY(-1.75rem) scale(0.85);
  background: white;
  padding: 0 0.3rem;
  color: #1b5e3b;
}

.fieldCont {
  position: relative;
  width: 100%;
}

.lable {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", sans-serif;
  font-size: 1rem;
  color: #999;
  position: absolute;
  top: 1rem;
  left: 1rem;
  pointer-events: none;
  transition: all 0.2s ease;
  transform-origin: left center;
}

.errorText {
  color: #ff4d4f;
  font-size: 0.9rem;
  margin: -0.5rem 0 0.5rem;
  text-align: center;
}

.submitBtn {
  border: none;
  position: relative;
  overflow: hidden;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", sans-serif;
  font-size: 1rem;
  font-weight: 600;
  width: 100%;
  cursor: pointer;
  padding: 0 1rem;
  height: 3rem;
  border-radius: 0.5rem;
  color: white;
  background: #1b5e3b;
  transition: all 0.2s ease;
  margin-top: 0.5rem;
}

.submitBtn:hover {
  background: #1b5e3b;
  transform: translateY(-1px);
}

.submitBtn:active {
  transform: translateY(0);
}

/* Убираем ненужные элементы */
.fakeSub, .fakeSubTwo {
  display: none;
}

.textSub {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
}

/* Анимация для перехода между шагами */
.stepTransition {
  animation: fadeInOut 0.3s ease;
}

@keyframes fadeInOut {
  0% { opacity: 1; transform: translateY(0); }
  50% { opacity: 0; transform: translateY(10px); }
  100% { opacity: 1; transform: translateY(0); }
}

/* Медиа-запросы для мобильных устройств */
@media (max-width: 640px) {
  .formaBlock {
    padding: 1rem;
    max-width: 90%;
  }

  .resetTitle {
    font-size: 1.3rem;
  }

  .fieldInp {
    padding: 0.875rem;
    font-size: 0.95rem;
  }

  .lable {
    font-size: 0.95rem;
    top: 0.875rem;
  }

  .fieldInp:focus + .lable,
  .fieldInp:not(:placeholder-shown) + .lable {
    transform: translateY(-1.5rem) scale(0.85);
  }

  .submitBtn {
    height: 2.75rem;
    font-size: 0.95rem;
  }
}
.otpContainer {
  margin-bottom: 1.5rem;
}

.otpLabel {
  color: #666;
  font-size: 0.9rem;
  margin-bottom: 0.75rem;
  display: flex;
  text-align: center;
  justify-content: center;
}

.otpInputs {
  display: flex;
  justify-content: space-between;
  gap: 0.75rem;
}

.otpInput {
  width: 3rem;
  height: 3.5rem;
  text-align: center;
  font-size: 1.25rem;
  border: 1px solid #ddd;
  border-radius: 0.5rem;
  transition: all 0.2s ease;
}

.otpInput:focus {
  border-color: #1b5e3b;
  box-shadow: 0 0 0 2px rgba(74, 144, 226, 0.2);
  outline: none;
}

/* Mobile styles */
@media (max-width: 640px) {
  .otpInputs {
    gap: 0.5rem;
  }

  .otpInput {
    width: 2.5rem;
    height: 3rem;
    font-size: 1.1rem;
  }
}