.mainConteiner {
  width: 100%;
  margin: 0 auto;
  padding: 2rem 30px;
  min-height: calc(100vh);
  overflow: hidden;
  box-sizing: border-box;
}

@media screen and (min-width: 1024px) {
  .mainConteiner {
    width: calc(100% - 3rem);
  }
}

.conteiner {
  margin: 0;
  padding: 0;
  display: block;
  unicode-bidi: isolate;
}
@media screen and (max-width: 639px) {
  .mainConteiner {
    padding: 1rem 16px;
  }
}
