button {
  border: none;
  background: none;
}

.mainConteiner {
  position: relative;
  border-radius: 0.5rem;
  z-index: 0;
  box-shadow: rgba(0, 0, 0, 0.04) 0 0 0.375rem, rgba(0, 0, 0, 0.08) 0 0.125rem 0.375rem;
}

.mainConteiner:hover {
  transform: scale(1.02) translateZ(0);
}

.mainBlock {
  position: relative;
  display: flex;
  align-items: stretch;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  min-height: 8.75rem;
  cursor: pointer;
  padding: 0.75rem 0rem;
  border-radius: 0.5rem;
  z-index: 0;
  padding: 12px;
}

.upBlock {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 0.25rem;
  overflow: hidden;
  width: 100%;
  padding-inline-end: 0.75rem;
}

.downConteiner {
  position: relative;
  align-self: center;
}

.downBlock {
  height: 7.25rem;
}

.downBlockImg {
  height: 7.25rem;
  width: 10.875rem;
  flex-shrink: 0;
  overflow: hidden;
  border-radius: 0.5rem;
  border-start-end-radius: 0.625rem;
  z-index: 1;
}

.img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 0.6rem;
}

.upContent {
  display: flex;
  flex: 1 1 0%;
  flex-direction: column;
  gap: 0.25rem;
}

.downContent {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.25rem 0.5rem;
}

.titleUp {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  margin: 0px;
  text-align: start;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  color: #202125;
  height: 1.375rem;
}

.descriptionUp {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  margin: 0;
  color: #202125a3;
  text-align: start;
  text-overflow: ellipsis;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.downContentBlock {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 0.25rem 0.5rem;
  width: 100%;
  max-width: 100%;
}

.costBlock {
  display: flex;
  align-items: center;
  column-gap: 0.5rem;
  margin: 0;
  white-space: nowrap;
  max-width: 100%;
}

.newCost {
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: #f93a25;
}

.oldCost {
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: #20212566;
  text-decoration: line-through;
}

@media screen and (min-width: 640px) {
  .mainBlock {
    border-radius: 0.5rem;
    padding: 0.75rem;
    background-color: rgb(255, 255, 255);
  }
  .mainConteiner:before {
    content: "";
    pointer-events: none;
    position: absolute;
    inset: 0;
    border-radius: inherit;
    transition: opacity 150ms cubic-bezier(0.45, 0, 0.55, 1) 0s;
  }
}

@media not (prefers-reduced-motion) {
  .mainConteiner {
    transform: scale(1);
    transition: transform 150ms cubic-bezier(0.45, 0, 0.55, 1) 0s;
  }
}
