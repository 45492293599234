.mainBlock {
  width: min-content;
}
.filter {
  height: 1px;
  margin: -1px;
  width: 1px;
  position: absolute;
  border: 0px;
  padding: 0px;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  white-space: nowrap;
  overflow: hidden;
}
.label {
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.65rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  outline: none;
  border: 1px solid #e4e4e5;
  padding: 0 0.3rem;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  height: 1.5rem;
  border-radius: 1rem;
  color: var(--grey-text-color);
  transition: outline 100ms linear 0s, color 100ms linear 0s, background 100ms linear 0s;
  white-space: nowrap;
  cursor: pointer;
  width: 100%;
  &__active {
    border:  1px solid var(--blue-btn-background-color);
    color: var(--blue-btn-background-color);
  }
}
.label__dark {
    border:  1px solid #1b5e3b;
    color:#1b5e3b;
}