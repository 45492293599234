@import "../../../styles/variables";

$small-icon-size: 1rem;
$large-icon-size: 1.5rem;

.mainStyle {
  background-color: rgba(255, 255, 255, 0.68);
  border-radius: 60px;
  height: 40px;
  width: 40px;
}
.mainSvg {
  fill: var(--text-color);
}

.IconBack {
  display: flex;
  align-items: center;
  justify-content: center;
  left: 2%;
  z-index: 99999999;
  @media (max-width: 820px) {
    top: 1%;
  }
  margin-top: 0.25rem;
  border-radius: 50%;
  width: 2rem;

  .IconBack__icon {
    display: flex;
    align-items: center;
    justify-content: center;
    height: $large-icon-size;
    padding: 8px;
    width: $large-icon-size;
    align-items: center;
    border-radius: 50%;
    display: flex;
    inset: 0;
    justify-content: center;
    background: #2021250A;
    transition: background-color 0.3s;
    &:hover {
    }
  }

  @media (max-width: 820px) {
    .IconBack__icon {
      height: $small-icon-size;
      width: $small-icon-size;
      top: 1%;
      left: 2%;
    }
  }
  @media (max-width: 640px) {
    .IconBack__icon {
      width: 32px;
      height: 32px;
    }
  }
}
