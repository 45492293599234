.header {
  width: 100%;
  height: 70px;
  background-color: white;
  margin: 0;
}

.logoBlock {
  flex-shrink: 0;
  position: relative;
  display: inline-block;
  background: transparent;
  border: none;
  border-radius: 0.5rem;
  outline: none;
  margin-inline-start: -1.5rem;
  color: #1b5e3b;
  text-decoration: none;
}

.logoConteiner {
  position: relative;
  width: 75px;
  height: 25px;
}

.containerHeader {
  width: 100%;
  background-color: #1b5e3b;
  @media (min-width: 640px) {
    padding: 0 12rem;
  }
}

@media (min-width: 640px) {
  .shopHeader {
    padding: 0 10rem 0 8rem;
    background-color: transparent;
    &__Scroll {
     padding: 0;
    }
  }

  .viewOrderBlock1 {
    border: none;
    position: relative;
    z-index: 0;
    user-select: none;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    font-feature-settings: "kern";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    font-weight: 700;
    width: 100%;
    max-width: 100%;
    cursor: pointer;
    padding: 0px 1rem;
    min-height: 3.375rem;
    border-radius: 0.5rem;
    background: transparent;
    color: #fff;;
  }
  .viewOrderBlock2 {
    position: absolute;
    inset: 0px;
    border-radius: 0.5rem;
    transition: background-color 120ms ease-out 0s;
    background: var(--blue-btn-background-color);
  }
  .viewOrderBlock3 {
    position: absolute;
    inset: 0px;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .viewOrderBlock4 {
    flex: 1 1 0%;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 1;
    transition: opacity 150ms ease-in 0s, color 150ms linear 0s;
    border-radius: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    overflow-wrap: break-word;
  }
  .viewOrderMain {
    display: flex;
    align-items: center;
    white-space: nowrap;
    overflow: hidden;
    width: 100%;
    color: var(--blue-btn-text-color);
  }
  .viewOrderInfo {
    font-feature-settings: "kern";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 0.9rem;
    line-height: 1rem;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    font-weight: 500;
    flex-shrink: 0;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 1.5rem;
    height: 1.5rem;
    border-radius: 0.75rem;
    padding: 0px 0.5rem;
    background-color: rgba(255, 255, 255, 0.15);
    color: white;
    margin-inline-end: 0.75rem;
  }
  .viewOrderText {
    overflow: hidden;
    text-overflow: ellipsis;
  }
  .viewOrderCost {
    font-feature-settings: "kern";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    font-weight: 500;
    flex: 1 1 0%;
    margin-inline-start: 1.5rem;
    text-align: end;
  }
  .infoViewBlock {
    position: fixed;
    bottom: 10px;
    width: 100%;
    padding: 1rem 1rem;
    box-sizing: border-box;
    border-radius: 0.75rem;
    flex: 1 1 0;
    transform: none;
    opacity: 1;
    pointer-events: auto;
    display: none;
    z-index: 998;
  }
  .show {
    display: block;
    position: unset;
  }
}

.shadowStyle {
  background-color: #1b5e3b;
  border-width: 0px 0px 1px;
  border-bottom: 0.3px solid rgba(169, 169, 169, 0.2);
  box-shadow: 0px 2px 5px rgba(169, 169, 169, 0.2);
}

.contentBlocks {
  position: relative;
  height: calc(-1px + 4.375rem);
  background: #1b5e3b;
  transition: background 150ms linear 0s;
  display: flex;
}

.contentBlocksShop {
  position: relative;
  height: calc(-1px + 4.375rem);
  background: #1b5e3b;
  transition: background 150ms linear 0s;
  display: flex;
  justify-content: space-between;
}

.leftBlock {
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  opacity: 0;
}
.mainBlock {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-inline-start: 30px;
  padding-inline-end: 30px;
  max-width: 1200px;
  margin: 0 auto;
  height: 69px;
}
@media (min-width: 640px) {
  .mainBlockShop {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-inline-start: 30px;
    padding-inline-end: 30px;
    max-width: 1200px;
    margin-right: auto;
    height: 69px;
  }
}


.leftContent {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  box-sizing: border-box;
  padding: 0 25px;
}

.fakeBlock {
  flex: 1 1 0%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.rightBlock {
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.collapse {
  height: 0 !important;
}
.contentRightBlocks {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-inline-start: 0.75rem;
}

.conteinerBtn {
  display: flex;
  align-items: center;
  padding: 0 30px;
}


@media screen and (max-width: 640px) {
  .containerHeader {
  }
  .contentRightBlocks {
    gap: 0;
  }
  .otstup {
    margin-right: 16px;
  }
  .containerHeader{
    position: fixed;
    z-index: 99999;
  }
  .fantomBlockF{
    height: 50px;
  }
  .contentBlocks {
    height: 55px;
  }
  .mainBlock {
    height: 55px;
    width: 80px;
    box-sizing: border-box;
    padding: 0 16px;
  }

  .logoConteiner {
    width: 48px;
    height: 18px;
  }
  .conteinerBtn {
    height: 32px;
  }

  .conteinerBtn {
    padding: 0 0;
  }
}

.fixedHeader {
  top: 0;
  position: fixed;
  z-index: 999;
  width: 100%;
}
.backgoundFantom {
  background-color: rgba(255, 255, 255, 0);
}
.whiteLogo {
  filter: invert(1)
}

.infoBlock {
  padding-inline-end: 1rem;
  height: 44px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1000000;
}
.kill {
  display: none;
}