.mainConteiner {
  position: relative;
  overflow: hidden;
  border-radius: 0.5rem;
  box-shadow: rgba(0, 0, 0, 0.06) 0 0 0.125rem 0, rgba(0, 0, 0, 0.12) 0 0.125rem 0.125rem 0;
  transition: box-shadow 0.3s cubic-bezier(0.45, 0, 0.55, 1) 0s, background 100ms ease-out 0s;
  transform: scale(1) rotate(0.02deg);
  z-index: 0;
  margin-inline-start: 1rem;
  scroll-snap-align: start;
  background-color: var(--other-background-color);
}
.card {
  user-select: none;
  -webkit-user-drag: none;
  display: block;
  color: #1b5e3b;
  cursor: pointer;
  text-decoration: none;
}

.mainConteiner:hover {
  transform: scale(1.02) rotate(0.02deg);
}

.headerBlcok {
  position: relative;
  z-index: 0;
}

.mainBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  white-space: nowrap;
  padding: 0.8rem;
  gap: 1rem;
}

.footerBlock {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  display: flex;
  align-items: center;
  height: 2rem;
  border-top: 0.0625rem dashed var(--cb-color-border);
  color: var(--cb-color-text-subdued);
  padding: 0px 0px 0px 0.8rem;
}

.imgBlock {
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 0.46;
  transform: scale(1) rotate(0.02deg);
}

.imgBlock:hover {
  transform: scale(1.02) rotate(0.02deg);
}

.imgConteiner {
  width: 100%;
  height: 100%;
  position: relative;
}

.img {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
  border-style: none;
}

.leftConteiner {
  display: flex;
  min-width: 0px;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.rightConteiner {
  flex-shrink: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.linkConteiner {
  display: block;
  color: #1b5e3b;
  line-height: 1.4;
  text-decoration: none;
}

.linkTitle {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 1.125rem;
  line-height: 2rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  text-align: start;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--text-color);
}

.linkTitle:after {
  content: "";
  display: block;
  position: absolute;
  inset: 0;
  box-sizing: inherit;
}

.description {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  margin: 0;
  color: var(--text-color);
  text-align: start;
  text-overflow: ellipsis;
  overflow: hidden;
}

.upBlock {
  display: flex;
  min-width: 0px;
  gap: 0.25rem;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
}

.downBlock {
  display: flex;
  min-height: 1.25rem;
}

.rightBoxCont {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 0.25rem 0.5rem;
  min-height: 2.5rem;
  min-width: 3.75rem;
  background-color: #1b5e3b14;
  border-radius: 0.5rem;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: #1b5e3b;
}

.rightBoxContUp {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  width: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  color: inherit;
}

.rightBoxContDown {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  width: inherit;
  text-overflow: ellipsis;
  overflow: hidden;
  text-align: center;
  color: inherit;
}

.footerContentBlock {
  white-space: nowrap;
  display: flex;
  gap: .5rem;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 1rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
}

.footerTextBlock {
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
}

.textCost {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.87rem;
  line-height: 1rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  color: var(--grey-text-color);
  overflow: hidden;
  text-overflow: ellipsis;
}

.textImg {
  min-width: 0.75rem;
  min-height: 0.75rem;
  width: 0.9rem;
  height: 0.9rem;
  margin-inline-end: 0.25rem;
  fill: var(--green-blue-color);
}
.footerTextBlockN {
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 0.25rem;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
}
.footerTextBlockN:before {
  content: "·";
  position: relative;
  display: inline-block;
  margin: 0 0.5rem;
  white-space: nowrap;
}

.footerTextBlockNContent {
  overflow: hidden;
  text-overflow: ellipsis;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 1rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  white-space: nowrap;
  color: var(--grey-text-color);
}

.baksImg {
  color: rgba(32, 33, 37, 0.28);
}

.footerTextBlockR {
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 0.25rem;
}
.footerTextBlockR:before {
  content: "·";
  position: relative;
  display: inline-block;
  margin: 0 0.5rem;
  white-space: nowrap;
  color: var(--grey-text-color);
}
.headerTextBlock {
  position: absolute;
  left: 1rem;
  right: 1rem;
  top: 1rem;
  display: flex;
  flex-wrap: wrap;
  z-index: 3;
}

.smileImg {
  min-width: 0.75rem;
  min-height: 0.75rem;
  width: 1rem;
  height: 1rem;
  margin-inline-end: 0.1rem;
  fill: #202125a3;
  color: var(--grey-text-color);
}
.points {
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--grey-text-color);
}
.mainConteiner:first-child{
  margin-inline-start: 0;
}

@media screen and (min-width: 0px) {
  .mainConteiner {
    width: calc((100% / 3.4) - 1rem + 1rem / 3.4);
    min-width: calc((100% / 3.4) - 1rem + 1rem / 3.4);
  }
  .mainConteiner {
    width: calc((100% / 2) - 1rem + 1rem / 2);
    min-width: calc((100% / 2) - 1rem + 1rem / 2);
    margin-inline-start: 8px;
  }
}

@media screen and (min-width: 640px) {
  .mainConteiner {
    width: calc((100% / 4) - 1rem + 1rem / 4);
    min-width: calc((100% / 4) - 1rem + 1rem / 4);
  }

  .title {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    font-weight: 500;
  }

  .count {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
    font-size: 0.75rem;
    line-height: 1rem;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    font-weight: 400;
  }
  .mainConteiner {
    width: calc((100% / 2) - 1rem + 1rem / 2);
    min-width: calc((100% / 2) - 1rem + 1rem / 2);
  }
}

@media screen and (min-width: 641px) {
  .title{
    font-size: 1.15rem;
    line-height: 1.4rem;
    font-weight: 500;
  }
  .count {
    font-size: 0.7rem;
    line-height: 1.5rem;
    font-weight: 500;
  }
  .mainConteiner {
    width: calc((100% / 2) - 1rem + 1rem / 2);
    min-width: calc((100% / 2) - 1rem + 1rem / 2);
  }
}

@media screen and (min-width: 800px) {
  .title{
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 500;
  }
  .count {
    font-size: 0.7rem;
    line-height: 1.5rem;
    font-weight: 500;
  }
  .mainConteiner {
    width: calc((100% / 3) - 1rem + 1rem / 3);
    min-width: calc((100% / 3) - 1rem + 1rem / 3);
  }
}

@media screen and (min-width: 1000px) {
  .title{
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 500;
  }
  .count {
    font-size: 0.7rem;
    line-height: 1.5rem;
    font-weight: 500;
  }
  .mainConteiner {
    width: calc((100% / 3) - 1rem + 1rem / 3);
    min-width: calc((100% / 3) - 1rem + 1rem / 3);
  }
}

@media screen and (min-width: 1200px) {
  .title{
    font-size: 1.3rem;
    line-height: 1.6rem;
    font-weight: 500;
  }
  .count {
    font-size: 0.8rem;
    line-height: 1.6rem;
    font-weight: 500;
  }
  .mainConteiner {
    width: calc((100% / 4) - 1rem + 1rem / 4);
    min-width: calc((100% / 4) - 1rem + 1rem / 4);
  }
}

@media screen and (max-width: 640px) {
  .mainConteiner {
    width: calc((100% / 2) + 6.4rem);
    min-width: calc((100% / 2) + 6.4em);
  }
  .imgBlock {
    aspect-ratio: 1/0.54;
  }
  .mainBlock {
    height: 3.2rem;
  }
  .linkTitle{
    font-size: 1rem;
    font-weight: 500;
    position: relative;
    top: 5px;
  }
  .rightConteiner {
    height: 1.5rem;
  }
  .rightBoxContUp {
    font-size: 0.8rem;
  }
  .rightBoxContUp {
    font-size: 0.75rem;
  }
  .rightBoxCont {
    min-height: 2rem;
    height: 2.5rem;
  }

}
.hr {
  width: 100%;
  height: 1px;
  background-image: linear-gradient(to right, var(--background-color) 30%, rgba(169, 169, 169, 0.25) 50%);
  background-size: 10px 1px;
  background-repeat: repeat-x;
}
.skeleton {
  position: absolute;
  inset: 0;
  top: -55px;
  width: 100%;
  min-height: 250px;
  object-fit: cover;
  display: inline-block;
  vertical-align: middle;
  border-style: none;
}