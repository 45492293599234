.mainConteiner {
  opacity: 1;
  z-index: initial;
  transition: opacity 150ms ease-in-out 100ms;
  cursor: pointer;
}

.active {
  border-radius: 10px;
  background-color: rgba(0, 157, 224, 0.2);
  color: #1b5e3b;
}

.active:hover {
  border-radius: 10px;
  background-color: rgba(0, 157, 224, 0.2) !important;
  color: #1b5e3b;
}

.mainConteiner:hover {
  border-radius: 10px;
  background-color: rgba(112, 113, 116, 0.1);
}

.mainBlock {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  padding: 0px 0.5rem;
  border-radius: 0.5rem;
  background-color: initial;
  color: var(--cb-color-text);
}

.mainContent {
  padding: 0.5rem 0;
}

.imgBlock {
  height: 2.5rem;
  width: 2.5rem;
  border-radius: 50%;
  overflow: hidden;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 0.125rem 0px, rgba(0, 0, 0, 0.12) 0px 0.125rem 0.125rem 0px;
}

.img {
  inset: 0;
  width: 40px;
  height: 40px;
  object-fit: cover;
}

.fakeBlock {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  padding: 0.375rem 0px;
  padding-inline-start: 0px;
}