.mainConteiner {
  position: relative;
  border-radius: 15px;
}

.imgBody:hover {
  transform: scale(1.03) translateZ(0);
}

.imgBody {
  width: 47vw;
  height: 360px;
  aspect-ratio: 1/1;
  outline-color: red;
  display: inline-block;
  border-radius: 15px;
  filter: brightness(0.85);
  @media (min-width: 640px) {
    width: 38vw;
  }
}

.textOverlay {
  position: absolute;
  top: 0px;
  bottom: 0;
  left: 0;
  width: 85vw;
  height: 205px;
  padding: 10px;
  color: #f0f0f0;
  display: flex;
  flex-direction: column;
  gap: 5px;
  justify-content: flex-end;
  z-index: 100;
  @media (min-width: 640px) {
    width: 37vw;
    line-height: 10px;
  }
}

.topText {
  font-family: "Nunito", sans-serif;
  font-optical-sizing: auto;
  width: 100%;
  font-size: 1.35rem;
  line-height: 1.5rem;
  font-weight: 800;
  text-shadow: 2px 2px 5px rgba(0, 0, 0, 0.9);
  @media (min-width: 640px) {
    font-size: 1.55rem;
    line-height: 2rem;
  }
}

.bottomText {
  line-height: 1.15rem;
  width: 100%;
  font-size: .75rem;
  font-weight: 400;
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.7);
  @media (min-width: 640px) {
    font-size: 1rem;
    top: 325px;
    position: absolute;
  }
}

@media screen and (max-width: 1024px) {
  .imgBody {
    width: 95vw;
    height: 40vw;
  }
}
@media screen and (max-width: 641px) {
  .imgBody {
    width: 89vw;
    height: 200px;
  }
}
.sceleton {
  position: relative;
  outline-color: red;
  display: inline-block;
  border-radius: 15px;
  filter: brightness(0.85);
  top: -60px;
  width: 100%;
  min-height: 330px;
}