.chat {
  border-top: 0.1px solid gray;
  text-align: center;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 4rem;
  cursor: pointer;
  width: 86vw;
  left: -1rem;
  position: relative;
  padding: 0.5rem 1rem;

}
.username {
  font-size: 1.2rem;
  font-weight: 600;
}
.textContent {
  padding-top: 5px;
  white-space: nowrap;
  overflow: hidden;
  font-size: 0.9rem;
  color: black;
  opacity: 0.6;
}
.leftChatBlock {
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: start;
}
.dateBlock {
  display: flex;
  justify-content: start;
  align-items: start;
  height: 100%;
}
.dateRow {
  margin-top: 5px;
  display: flex;
  justify-content: start;
  align-items: start;
  opacity: 0.6;
}