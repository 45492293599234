.mainPage {
  width: 100%;
  margin: 0 auto;
  padding: 2rem 30px;
  overflow: hidden;
  box-sizing: border-box;
  @media (min-width: 640px) {
    padding: 2rem 12rem;
  }
}

.title {
  height: 1px;
  margin: -1px;
  width: 1px;
  position: absolute;
  border: 0;
  padding: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width: 639px) {
  .mainPage {
    background-color: var(--background-color);
    padding: 20px;
    box-sizing: border-box !important;
    border-radius: 10px;
    //box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    //animation: waveIn 1s ease-out forwards;
    //transform: translateY(100%);
    //@keyframes waveIn {
    //  0% {
    //    transform: translateY(100%);
    //  }
    //  100% {
    //    transform: translateY(0);
    //  }
    //}
  }
}
