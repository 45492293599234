.mainConteiner {
  position: absolute;
  display: inline-block;
  height: calc(100% - 64px);
  width: 100%;
  max-height: 100%;
  background: #f4f4f6;
  -webkit-box-shadow: none;
  box-shadow: none;
  z-index: 2;
  overflow: hidden;
  top: 64px;
  @media (max-width: 640px) {
    position: inherit;
    width: 100%;
    top: initial;
    max-height: inherit;
    height: fit-content;
    margin-bottom: 20px;
  }
}
.mainBlock {
  display: block;
  height: 100%;
  height: calc(100% - 120px);
  @media (max-width: 640px) {
    height: fit-content;
    overflow: auto;
;
  }
}
.categoryImgBlock {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  padding: 16px;
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 24px;
  font-weight: 500;
  color: #0E1F40;
}
.categoryTextBlock {
  position: relative;
  width: 24px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
}
.fakeBlock {
  width: 100%;
  height: 1px;
  background: rgba(216, 211, 219, 0.52);
}
.allCategoryBlock {
  position: relative;
}
.allCategoryBlockLink {
  position: relative;
  display: block;
  color: #0E1F40;
  cursor: pointer;
  font-size: 13px;
  line-height: 16px;
  padding: 8px 40px;
  z-index: 1;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  padding-left: 16px;
  font-weight: 500;
}
.allCategoryText {
  position: absolute;
  right: 17px;
  font-size: 12px;
  line-height: 14px;
  color: #0E1F40;
  font-weight: normal;
  top: 50%;
  z-index: 1;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}
.allCategoryB {
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 30px;
  color: #0E1F40;
  background: #e2e3e7;
}
.createCategoryBlock {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  padding: 13px 7px;
  @media (max-width: 640px) {
    padding: 5px;
    margin: 0;
  }
}
.createCategoryBtn {
  font-family: Roboto, sans-serif;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 16px;
  text-align: center;
  background-color: transparent;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  color: #0E1F40;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  ackground: rgba(225, 221, 227, 0.3);
  border-radius: 4px;
  padding: 8px 12px 8px 6px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 13px;
  line-height: 15px;
  color: #1b5e3b;
  opacity: 0.9;
  padding-left: 17px;
  width: 100%;
  @media (max-width: 640px) {
    padding: 0;
    margin: 0;
  }
}
.bigCategoryBlock {
  position: relative;
  overflow: auto;
  height: 100%;
  padding: 0;
  height: fit-content;
}
ul {
  margin-left: var(--indent, 0);
}

.arrowIcon {
  width: 20px;
  height: 20px;
  position: relative;
  z-index: 1;
  top: 25px;
  left: 20px;
}
.selectedCategory {
  background-color: lightblue;
}