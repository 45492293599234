.mainContainer {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  padding: 0 16px;
  @media (max-width: 840px) {
    padding: 0 15px;
  }
}

.headerBox {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 2.875rem;
  line-height: 3.5rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin: 0;
  padding: 0;
  @media (max-width: 840px) {
    font-size: 2rem;
  }
}

.buttonBlock {
  border: none;
  position: relative;
  z-index: 0;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  width: auto;
  max-width: 100%;
  cursor: pointer;
  padding: 0 1rem;
  min-height: 3.375rem;
  border-radius: 0.5rem;
  background: transparent;
  color: #1b5e3b;
  @media (max-width: 840px) {
    font-size: 0.7rem;
    padding: 0 0.5rem;
    min-height: 1.375rem;
  }
}

.firstBlock {
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
  transition: background-color 120ms ease-out 0s;
  background: #1b5e3b14;
}

.secondBlock {
  position: absolute;
  inset: 0px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.threeBlock {
  flex: 1 1 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 150ms ease-in 0s, color 150ms linear 0s;
  border-radius: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

.mainBlock {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  margin-inline: 0px 1rem;
}

.img {
  width: 1rem;
  fill: #1b5e3b;
}

