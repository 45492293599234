.button-container {
  display: flex;
  grid-gap: 10px;
  margin-top: 20px;
  @media (min-width: 641px) {
    width: 40%;
  }
}

.add-button {
  border: none;
  position: relative;
  z-index: 0;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: 100%;
  height: 40px;
  max-width: 100%;
  cursor: pointer;
  padding: 0px 1rem;
  border-radius: 0.5rem;
  background: var(--blue-btn-background-color);
  justify-content: center;
  &--element {
    color: var(--blue-btn-text-color);
  }
}
.removeButton {
  border: none;
  position: relative;
  z-index: 0;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: 100%;
  height: 40px;
  max-width: 100%;
  cursor: pointer;
  padding: 0px 1rem;
  border-radius: 0.5rem;
  background: red;
  justify-content: center;
  &--element {
    color: var(--blue-btn-text-color);
  }
}

p{
  font-weight: bold;
  font-size: 17px;
}
.chats {
  max-height: 25vh;
  padding-top: 0.5rem;
  overflow-y: auto;
}
.topBlockInfo {
  display: flex;
  flex-direction: column;
}
.topBlockInfoText {
  margin-top: 1rem;
  font-size: 1rem;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.5);
}
.lineTop {
  padding: 0;
  height: 0;
  border: none;
  border-top: 0.5px solid rgba(51, 51, 51, 0.2);
}
.topBlockInfoBlock {
  border: 0.5px solid rgba(51, 51, 51, 0.2);
  width: 100%;
  border-radius: 5px;
  padding-left: 10px;
  margin-bottom: 10px;
  padding-top: 5px;
  padding-bottom: 5px;
  margin-top: 5px;
}
.topText {
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.5);
}
.downText {
  font-size: 0.9rem;
  color: rgba(0, 0, 0, 0.8);
}
.topBlockInfoMainBlock {
  display: flex;
  justify-content: space-between;
}
.topBlockInfoMainCont {
  border: 0.5px solid rgba(51, 51, 51, 0.2);
  border-radius: 5px;
  padding-left: 10px;
  width: 30%;
  padding-top: 5px;
  padding-bottom: 5px;
}
.lineMiddle {
  margin-top: 1rem;
  padding: 0;
  height: 0;
  border: none;
  border-top: 0.5px solid rgba(51, 51, 51, 0.2);
}
.middleBlockInfo {

}
.topBlockInfoMainContM {
  border: 0.5px solid rgba(51, 51, 51, 0.2);
  border-radius: 5px;
  padding-left: 10px;
  width: 49%;
  margin-top: 2%;
}
.middleBlockInfoM {
  display: flex;
  flex-direction: column;
}
.topBlockInfoMainBlockM {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.middleBlockInfoD {
  display: flex;
  flex-direction: column;
}
.topBlockInfoMainContD {
  border: 0.5px solid rgba(51, 51, 51, 0.2);
  border-radius: 5px;
  padding-left: 10px;
  width: 100%;
  margin-top: 5px;
  padding-top: 5px;
  padding-bottom: 5px;
}
.mainContentBlockImg {
  object-fit: cover;
  width: 100%;
  height: auto;
  aspect-ratio: 3 / 2;
  pointer-events: none;
  transform: scale(1);
  transition: transform 150ms cubic-bezier(0.45, 0, 0.55, 1) 0s;
}
.mainImg {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.docText {
  color: rgba(0, 0, 0, 0.5);
}
.mainTitleUser {
  position: relative;
  top: -37px;
  left: 4rem;
  width: 10rem;
  font-size: 1.4rem;
}
.imgShadow {
  box-shadow: 0 2px 5px rgb(41, 41, 41);
}
.madalMedia {
  position: fixed;
  overflow: visible;
  height: 100vh;
  width: 100vw;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgb(62, 62, 62);
  color: black;
  z-index: 9999999999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  border: 5px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}