@import "src/styles/variables";
.buttonWrapper {
  display: flex;
  justify-content: center;
  margin: 12px;
}

.button {
  background-color: var(--other-background-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  padding-top: 3px;
  &__active {
    background-color: #1b5e3b;
  }
  background-color: rgba(169, 169, 169, 0.3);
  &__Shop {
    background-color: var(--light-background-color);
  }
  &__ShopScrolled {
    background-color: rgba(169, 169, 169, 0.3);
  }
  &__scrolled {
    background-color: rgba(225, 225, 225, 0.2);
  }
}

.button:hover {
  background-color: $blue-light;
}

.icon {
  width: 18px;
  height: 18px;
  fill: var(--text-color);
}

@media screen and (max-width: 640px) {
  .button {
    width: 40px;
    height: 40px;
  }
  .icon {
    width: 14px;
    height: 14px;
  }
  .buttonWrapper {
    margin: 8px;
  }
}