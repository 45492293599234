.mainBoxBlock {
  width: 100%;
  padding: 1rem;
}

.conteiner {
  height: 18rem;
  width: 100%;
  border: 1px solid rgba(169, 169, 169, 0.2);
  border-radius: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.mainImg {
  height: 60px;
  width: 60px;
  border-radius: 0.5rem;
}
.upRowBlock {
  border-bottom: 2px dashed rgba(169, 169, 169, 0.2);
  display: flex;
  align-items: center;
  padding: 1rem;
}
.nameShopText {
  font-size: 1rem;
  font-weight: bold;
  margin-left: 1rem;
}
.downRowBlock {
  display: flex;
  overflow: auto;
  padding: 1rem;
  gap: 10px;
}
.downRowBlock::-webkit-scrollbar {
  display: none;
}
.textItemSub {
  font-size: 0.9rem;
  font-weight: bold;
}
.viewBtn {
  background-color: #1b5e3b14;
  color: #2990c3;
  height: 2.5rem;
  border-radius: 0.5rem;
  margin-top: 1rem;
  font-size: 0.8rem;
  font-weight: 600;
}
.infoBlock {
  display: flex;
  flex-direction: column;
  padding: 1rem;
}
.middleImg {
  height: 60px;
  width: 60px;
  border-radius: 0.5rem;
}