/* ProfileCard.module.css */

.profileCard {
  margin: 1rem auto;
  max-width: 900px;
  padding: 2.1875rem 0;
  position: relative;
  background-color: var(--background-color);
  color: var(--text-color);
}
.mainHi {
  font-size: 1.7rem;
  font-weight: 750;
  padding: 0 1rem;
}
.mainTitle {
  font-size: 1.4rem;
  font-weight: 400;
  padding: 0 1rem;
  color: rgba(0, 0, 0, 0.65);
}

.container {
  padding: 1.25rem 1rem;
  position: relative;
  border-bottom: 0.5px solid rgba(128, 128, 128, 0.31);
}



.imageContainer {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 1rem;
}

.imageWrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.avatar {
  background-size: cover;
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.profileImage {
  background: #fff;
  border: 4px solid #fff;
  border-radius: 50%;
  box-shadow: 0 2px 4px 0 rgba(0,0,0,.5);
  height: 60px;
  margin-bottom: .3rem;
  overflow: hidden;
  width: 60px;
}
.verifImg {
  border: 4px solid rgba(28, 230, 28, 0.7);
}
.notVerifImg {
  border: 4px solid rgba(253, 35, 35, 0.7);
}

.uploadInput {
  width: 60px;
  height: 60px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: 30;
}

.uploadLabel {
  color: var(--cb-color-text-brand);
  cursor: pointer;
  font-size: 1.07rem;
  padding: .5rem 1rem;
}

.description {
  color: #1b5e3b;
}

.userInfo {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: start;
  align-items: start;
  margin-inline-end: 0;
  margin-inline-start: 1.2rem;
}

.userName {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.userNameText {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin: 0px;
}

.userDetails {
  display: flex;
  flex-direction: row;
}

.detail {
  margin-right: 4rem;
  margin-inline-end: 4rem;
  margin-inline-start: 0;
}

.upTitle {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  margin: 0;
}

.upDescription {
  padding-top: .1rem;
  font-size: 1.1rem;
  font-weight: 700;
  color: #1b5e3b;
}

.downTitle {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: #707174;
  margin: 0;
}

.downDescription {
  padding-top: .1rem;
  font-size: 1.07rem;
}

.cardContainer {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-top: 3rem;
}

.downConteiner {
  padding: 1rem;
}

.downBlock {
  margin-bottom: 2rem;
  margin-top: 2rem;
  overflow: hidden;
}

.downMainBlock {
  display: flex;
  flex: 1;
  flex-direction: row;
}

.boxMain {
  flex: 3;
}

.infoRowLvl {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
}
.infoBlock  {
  margin-bottom: 1rem;
}
.downText {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  color: #202125;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.downTextTwo {
  color: #202125a3;
}

.iconBlock {
  display: flex;
  flex: 2;
  justify-content: center;
  max-width: 164px;
  text-align: right;
}

.IconNextBlock {
  height: 100%;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.IconNext {
  rotate: (270deg);
  margin-top: 4px;
}

.cardContainerBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 3rem;
}
.cardText {
  font-size: 1.1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
}
.countMoney {
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  margin-right: 5px;
}
.moneyCountBlock {
  display: flex;
  align-items: center;
  justify-content: center;
  min-width: 40px;
}
.img {

}
.imgWolt {
  width: 1.125rem;
  height: 1.125rem;
  opacity: 1;
}
.tokensMain {
  display: flex;
  align-items: center;
  height: 100%;
  justify-content: center;
}

@media screen and (max-width: 640px) {
  .downBlock {
    margin: 2rem 30px 0;
  }
  .iconBlock {

  }

  .userDetails {
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
  }
  .detail {
    box-sizing: border-box;
    margin-right: 0rem;
    margin-bottom: 0.2rem;
  }

  .cardContainer {
    margin-top: 0.5rem;
    display: flex;
    flex-direction: column;
  }

  .downMainBlock {
    display: flex;
    flex-direction: column;;
  }
  .logOutBlock {
    background-color: rgba(255, 50, 52, 0.44);
    border-radius: 90px;
    height: 3rem;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logOut {
    color: white;
    font-weight: bold;
    font-size: 1.2rem;
  }
}

.verif {
  color: rgba(37, 179, 37, 0.4);
}
.notVerif {
  color: red;
}

.banned {
  text-transform: uppercase;
  color: red;
  position: absolute;
  font-size: 2.2rem;
  font-weight: 600;
  top: 5%;
  left: 45%;
}


