.mainBlock {
  display: flex;
  align-items: center;
  padding: 2px 5px;
  border-radius: 12px;
  background-color: white;
  width: 100%;
  margin-bottom: 12px;
}
.leftBlock {
  padding-top: 2px;
  display: flex;
  align-items: start;
  justify-content: start;
  height: 100%;
}

.leftImg {
  width: 80px;
  height: 80px;
  border-radius: 12px;
  object-fit: cover;
  margin-right: 10px;
}
.pointSpace {
  margin-left: 5px;
  margin-right: 5px;
  display: flex;
  justify-content: center;
}

.rightBlock {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
}

.title {
  font-size: 16px;
  font-weight: bold;
}

.subTitle {
  font-weight: normal;
  color: gray;
}

.description {
  font-size: 12px;
  color: #666;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 250px;
}

.infoBlock {
  display: flex;
  align-items: center;
  font-size: 12px;
  color: #444;
}

.icon {
  font-size: 8px;
  height: 0.8rem !important;
  width: 0.8rem !important;
  margin-bottom: 2px;
  color: rgba(0, 0, 0, 0.6);
}

.price {
  font-weight: bold;
  color: #007aff;
  display: flex;
  align-items: center;
}

.ratingBlock {
  display: flex;
  align-items: center;
  font-size: 12px;
  margin-top: 4px;
  color: #007aff;
}

.rating {
  margin-left: 4px;
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
}

.time {
  font-weight: bold;
  color: rgba(0, 0, 0, 0.5);
}
.skeleton {
  position: relative;
  top: -10px;
  width: 80px;
  min-height: 120px;
  border-radius: 12px;
  object-fit: cover;
  margin-right: 10px;
}
