.chatWrapper {
  height: calc(100vh);
  background-image: url("./chat.jpg");
}

.lvl2 {
  position: relative;
}

.lvl3 {
  opacity: 1;
  padding: 0.4rem 0;
  border-bottom: 0.5px solid #f2f2f2;
}

.content {
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.stateTitle {
  font-size: 1.5rem;
  font-weight: 700;
}

.description {
  color: var(--grey-text-color);
  font-size: 1.2rem;
}

.left {
  display: flex;
  font-size: 1.2rem;
  align-items: center;
  color: var(--order-text-color);
  font-weight: 500;
}

.date {
  color: var(--order-grey-text-color);
}

.middle {
  width: 33%;
  display: flex;
  align-items: center;
  color: var(--grey-text-color);
}

.right {
  width: 33%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  color: var(--order-grey-text-color);
}

@media screen and (max-width: 641px) {
  .stateTitle {
    font-size: 1.3rem;
  }

  .description {
    margin-top: 1rem;
    font-size: 0.8rem;
  }
}

.backer {
  display: none;
  position: fixed;
  width: 32px;
  height: 32px;
  z-index: 9999999;
  top: 1%;
  left: 3%;
}

@media screen and (max-width: 640px) {
  .backer {
    display: block;
  }
}

@media screen and (max-height: 700px) {
  .backer {
    display: block;
    width: 32px;
    height: 32px;
    top: 0;
    left: 2%;
  }
}

.IconNextBlock {
  margin-left: 1rem;
  height: 100%;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.IconNext {
  rotate: (270deg);
  margin-top: 4px;
  fill: var(--order-grey-text-color);
}

.chatHeader {
  height: calc(9vh - 1rem);
  min-height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  width: 100%;
  position: absolute;
  border-bottom: 0.1px solid darkgrey;
  z-index: 9999;
}
.closeBtn {
  position: absolute;
  right: 1rem;
  font-weight: bold;
  border: 1px solid darkgrey;
  border-radius: 0.9rem;
  padding: 5px 10px;
  font-size: 1.1rem;
  color: grey;
}

.title {
  font-size: 1.5rem;
  font-weight: 700;
  text-align: center;
}

.chatBody {
  height: 100%;
  width: 100%;
}

.inputMessagePanel {
  display: flex;
  flex-direction: column-reverse;
  border-radius: 1rem;
  height: 100%;
}

.inputWrapper {
  max-width: 900px;
  padding: 0.8rem 1rem;
}

.inputMessagePanelWrapper {
  max-width: 900px;
  padding: 1.1875rem 1rem;
  height: calc(97vh - 2.5rem);
  border-bottom: 0.5px solid rgba(128, 128, 128, 0.31);
  border-top: 0.5px solid rgba(128, 128, 128, 0.31);
}

@media screen and (min-width: 1000px) {
  .inputMessagePanelWrapper {
    max-width: 2140px;
  }
  .inputWrapper {
    max-width: 2140px;
  }
}

.chatPanel {
  border-radius: 16px;
  background-color: #ededed;
  padding: 2px 4px;
  display: flex;
  align-items: center;
  width: 100%;
}

.messageConteiner {
  width: 100%;
  padding: 0.5rem;
  padding-top: 2.5rem;
  max-width: 100%;
  overflow: auto;
  ::-webkit-scrollbar {
    width: 0;
  }
}
::-webkit-scrollbar {
  width: 0;
}

.messageBody {
  border-radius: 1rem;
  font-weight: 500;
  padding: 0.5rem 0.75rem;
  box-sizing: border-box;
  margin-top: 0.5rem;
  max-width: 70vw;
  display: flex;

}

.message {
  width: 100%;
  display: inline-block;
  box-sizing: border-box;
  overflow-wrap: break-word;
  width: 100%;
  max-width: 53vw;
  word-break: break-word;
  color: white;
}

.timeRow {
  display: flex;
  font-weight: 400;
  font-size: 0.75rem;
  width: 100%;
  max-width: 6vw;
  margin-right: 4vw;
  color: rgba(255, 255, 255, 0.75);
  align-items: end;
  padding-left: 0.4rem;
}

.conteinerRow {
  width: 100%;
}

.conteinerRowLeft {
  display: flex;
  justify-content: flex-end;
}

.conteinerRowRight {
  display: flex;
}

.messageBodyRight {
  background-color: rgba(43, 35, 50, 0.65);
}
.messageBodyLeft {
  background-color: rgba(143, 68, 250, 0.65);
}
