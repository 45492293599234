.cardWrapper {
  opacity: 1;
  padding: 1rem 0;
  position: relative;
  border-bottom: 0.5px solid #f2f2f2;
}

.content {
  text-align: center;
  display: flex;
  justify-content: space-between;
}

.earningsDateLeft {
  display: flex;
  align-items: center;
  color: var(--order-text-color);
  font-weight: 500;
}

.earningsDate {
  font-size: 1rem;
}

.earningsSum {
  width: 80%;
  display: flex;
  font-size: 1rem;
  align-items: center;
  justify-content: flex-end;
  color: var(--order-grey-text-color);
}

.earnings {
  display: flex;
  align-items: center;
  justify-content: space-between;
  column-gap: 4px;
  max-width: 160px;
  width: 100%;
}
