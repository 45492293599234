

@media screen and (max-width: 640px) {
  .info {
    width: 14px;
    height: 14px;
  }
  .infoImg {
    width: 14px;
    height: 14px;
  }
}