.buttonShared {
  margin-top: 16px;
  background: none;
  border: 0;
  text-align: start;
  display: inline-flex;
  align-items: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  color: var(--cb-color-text);
  text-decoration: underline;
  color: black;
}

.img-shared {
  height: 16px;
  width: 16px;
  margin-right: 8px;
}
