* {
  margin: 0;
  padding: 0;
}

* {
  -webkit-tap-highlight-color: transparent;
}

.ymaps-2-1-79-copyrights-pane {
  display: none;
}

a {
  color: #1b5e3b;
  text-decoration: none;
}

.react-international-phone-country-selector-button {
  width: 4rem !important;
  height: 3rem !important;
}
.tested-style-forthis {
  fill: none;
}
::-webkit-scrollbar {
  width: 0;
}

body,
html {
  height: 100vh;
  background: white;
}

.app {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background-color: var(--background-color);
  color: var(--text-color);
}

$grey-link: #202125A3;

$blue-button:rgb(0, 157, 224);

.MuiPaper-root {
  border-radius: 20px 0 0 20px !important;
  @media (max-width: 850px) {
    border-radius: 20px 20px 0 0 !important;
  }
}
.Mui-checked {
  transform: translateX(30px)!important;
}
:root {
  --background-color: #fff;
  --text-color: #202125;
  --other-background-color: rgba(163, 163, 163, 0.65);
  --grey-text-color: #202125a3;
  --light-grey-text-color: #3d3e42a3;
}

[data-theme='dark'] {
  --background-color: #0000;
  --text-color: #fff;
  --other-background-color: #171717;
  --grey-text-color: #fff;
  --blue-color: #1b5e3b;
}

body {
  background-color: var(--background-color);
  color: var(--text-color);
}

.rsbcText {
  background-color: rgb(1, 157, 224) !important;
  font-size: 16px !important;
}
.slick-next:before{
  content:none !important;
}
.slick-prev:before {
  content:none !important;
}

.custom-hover:hover {
  background-color: rgba(0, 157, 224, 0.18) !important;
}
.MuiTab-root.Mui-selected {
  color: #1b5e3b !important;
}

.MuiTabs-indicator {
  color: #1b5e3b !important;
}

.MuiInputLabel-root {
  color: #1b5e3b !important;
}


.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: #1b5e3b !important;
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: #1b5e3b !important;
}

