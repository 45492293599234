@import "src/styles/variables";
.mainConteiner {
  margin-bottom: 0.5rem;
  box-sizing: border-box !important;
}

.mainBlock {
  position: relative;
  width: 100%;
  touch-action: pan-y pinch-zoom;
  display: flex;
  flex-direction: column;
  margin-bottom: 0.5rem;
  padding: 0 0;
}

.panelConteiner {
  display: flex;
  position: relative;
  gap: 1rem;
}

.titleBlock {
  align-items: center;
  display: flex;
  flex-direction: row;
  gap: .5rem;
  height: 4.5rem;
  justify-content: space-between;
  padding: 1rem 0 0.5rem 0;
  width: 100%;
}

.title {
  font-variant-ligatures: common-ligatures;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
  font-feature-settings: "kern", "ss01", "ss05", "ss07";
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
}

.btnConteiner {
  align-items: center;
  display: flex;
}

.seeAll {
  padding: 5px 10px;
  border: none;
  background: var(--light-blue-color);
  text-align: start;
  display: inline-flex;
  align-items: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  text-transform: none;
  color: #1b5e3b;
  user-select: inherit;
  cursor: pointer;
  text-decoration: none;
  margin-inline-start: 0;
  border-radius: 0.5rem;

}

.btnBlock {
  display: flex;
  flex-direction: row;
  gap: 0.25rem;
  opacity: 1;
}

.leftBtn {
  cursor: pointer;
  background-color: #1b5e3b0a;
  padding: 0;
  border: none;
  background-image: none;
  background-position: initial;
  background-size: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  position: relative;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transition: background-color 120ms ease-out 0s;
}

.rightBtn {
  cursor: pointer;
  padding: 0;
  border: none;
  background-image: none;
  background-position: initial;
  background-size: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  position: relative;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 50%;
  transition: background-color 120ms ease-out 0s;
  background-color: #1b5e3b14;
}
.rightBtn:hover{
  background-color: rgba(0, 157, 224, 0.18);
}
.leftBtn:hover{
  background-color: rgba(0, 157, 224, 0.16);
}

.sliderBar {
  width: 100%;
}

.sliderBarBlock {
  scrollbar-width: none;
  overflow-x: auto;
  scroll-snap-type: x mandatory;
  --slideTrackPadding: 8px;
  width: calc(100% + calc(16px * 2));
  padding: 1rem 16px;
  margin: -1rem calc(16px * -1);
  box-sizing: border-box;
}

.sliderConteiner {
  box-sizing: border-box;
  display: flex;
  overflow-y: auto;
  scrollbar-width: none;
  padding: 8px 0;
  scroll-behavior: smooth;
}
.sliderConteiner::-webkit-scrollbar {
  display: none;
}

.leftSvg {
  fill: #1b5e3b;
  width: 1.25rem;
  height: 1.25rem;
}

.rightSvg {
  fill: #1b5e3b;
  width: 1.25rem;
  height: 1.25rem;
}

.fakeElem {
  margin-inline-start: 1.8rem;
  scroll-snap-align: start;
  display: none;
}

@media screen and (max-width: 1600px) {
  .mainBlock {
    width: 100%;
    margin: 0 0 0.5rem;
    padding: 0 0;
  }
  .sliderBarBlock {
    --slideTrackPadding: 16px;
    padding-bottom: 0.5rem;
    margin-bottom: -0.5rem;
  }
}

@media screen and (max-width: 1023px) {
  .panelConteiner {
    margin-bottom: 1rem;
  }
  .sliderBarBlock {
    --slideTrackPadding: 16px;
    padding-top: 0;
    padding-bottom: 0.5rem;
    margin-bottom: -0.5rem;
  }

}

@media screen and (max-width: 639px) {
  .fakeElem {
    display: block;
  }
  .titleBlock {
    height: 60px;
    box-sizing: border-box;
  }
  .title{
    font-size: 1.5rem;
    font-weight: 700;
  }
  .sliderConteiner {
    --slideMargin: 0.5rem;
  }

  .leftBtn {
    display: none;
  }

  .rightBtn {
    display: none;
  }

  .panelConteiner {
    flex-direction: row-reverse;
    gap: 2rem;
  }

  .mainConteiner {
    margin-bottom: 0.625rem;
    margin-inline: 0;
  }

  .mainBlock {
    margin-bottom: 1rem;
  }
}