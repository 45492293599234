.mainConteiner {

}

.container {
  position: relative;
  box-sizing: border-box;
  color: rgb(32, 33, 37);
  border-radius: 1.25rem;
  height: 2.5rem;
  backdrop-filter: none;
}

.searchIcon {
  position: absolute;
  inset: 0;
  background: #fff;
  border-radius: 1.25rem;
}

.icon {
  width: 1rem;
  position: absolute;
  inset-inline-start: 1rem;
  top: 50%;
  transform: translateY(-50%);
  fill: currentcolor;
}

.iconTwo {
  width: 1rem;
  fill: currentcolor;
}

.input {
  border: none;
  outline: none;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  position: relative;
  display: block;
  margin: 0px;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-inline: 2.5rem 1rem;
  box-sizing: border-box;
  border-radius: 1.25rem;
  color: inherit;
  height: 2.5rem;
  width: 100%;
  max-width: 100%;
  transition: background-color 70ms linear 0s;
  caret-color: #1b5e3b;
  background: rgba(32, 33, 37, 0.12);
}
.input::placeholder {
  color: #707174;
}

.clearIcon {
  position: absolute;
  inset-inline-end: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 2rem;
  height: 2rem;
}

.clearButton {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: inherit;
  transition: transform 100ms ease-in 0s;
  transform: scale(0);
}

@media screen and (max-width: 639px) {
  .mainConteiner {
    display: block;
    padding: 0.5rem 1rem;
  }
}