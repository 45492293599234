.shopInformationBlockWrapper {
  opacity: 1;
  padding: 0.4rem 0;
  border-bottom: 0.5px solid #f2f2f2;
}

.shopInformation {
  display: flex;
  column-gap: 0.7rem;
  @media (min-width: 641px) {
    justify-content: space-between;
    cursor:pointer
  }
}

.main {
  display: flex;
  flex-direction: column;
  color: var(--order-text-color);
  width: 9rem;
}

.status {
  width: 9rem;
}

.shopName {
  font-size: 1.15rem;
  font-weight: 500;
  overflow-wrap: break-word;
  text-align: left;
}

.orderDate {
  color: var(--order-grey-text-color);
}

.price {
  text-wrap: nowrap;
  align-self: center;
  color: var(--order-grey-text-color);
}

.priceWrapper {
  display: flex;
  width: 5rem;
}

.iconNextBlock {
  margin-left: 1rem;
  height: 100%;
  width: 20px;
  display: flex;
  align-self: center;
  justify-self: center;
}

.iconNext {
  rotate: (270deg);
  margin-top: 4px;
  fill: var(--order-grey-text-color);
}
