.mainConteiner {
  display: flex;
  align-items: center;
  overflow: hidden;
  pointer-events: initial;
  opacity: 1;
}

.blockConteiner {
  margin-inline-end: 0.75rem;
  overflow: hidden;
}

.conteiner {
  max-width: 25rem;
}

.leftBlock {
  opacity: 1;
}

.locationSvgWrapper{
  background-color: var(--light-background-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  cursor: pointer;
  padding-top: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.locationSvg {
  display: none;
  @media (min-width: 640px) {
    display: block;
    height: 25px;
    width: 25px;
    color: white;
    fill: white;
  }
}
.btnBlock {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 0.5rem;
  position: relative;
  max-width: 100%;
  color: #fff;
  outline: none;
  cursor: pointer;
  border: 0;
  background: none;
  &__scrolled {

  }
}

.locationImgBlock {
  width: 2.6rem;
  min-width: 2.2rem;
  height: 2.6rem;
  min-height: 2.2rem;
  border-radius: 50%;
  display: inline-flex;
  position: relative;
  justify-content: center;
  align-items: center;
  transition: background-color 120ms ease-out 0s;
  background-color: rgba(225,225,225, 0.3);
}

.countryBlock {
  display: inline-flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  overflow: hidden;
  height: 2rem;
  margin-inline: 0.5rem;
  padding: 0;
}

.coutryName {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  max-width: 100%;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.89rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  box-sizing: border-box;
}

.arrow {
  fill: currentcolor;
  width: 1rem;
  height: 1rem;
}

.locationImg {
  width: 1.25rem;
  height: 1.25rem;
  fill: currentcolor;
}

.mainConteiner:hover {
  .locationImgBlock {
    background-color: rgba(0, 157, 224, 0.16);
  }
}

@media screen and (max-width: 640px) {
  .basket {
    height: 100vh;
  }
}
.upRow {
  color: #fff;
  font-weight: bold;
}

.leftNewLocBlock {
  display: flex;
  flex-direction: column;
}
.downRow {
  color: #fff;
  display: flex;
  align-items: center;
  .arrow {
    margin-left: 5px;
  }
}



