.mainConteiner {
  margin-bottom: 0.5rem;
  margin-inline-end: 0.5rem;
  display: inline-block;
}

.conteiner {
  display: inline-flex;
  background: rgb(255, 255, 255);
  border-radius: 0.5rem;
  max-width: 100%;
  width: fit-content;
  padding: 0.25rem 0.5rem;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  color: rgb(32, 33, 37);
  white-space: nowrap;
}

.text {
  text-overflow: ellipsis;
  overflow: hidden;
  align-self: center;
}