.mainConteinder {
  padding: 1rem;
  border-radius: 0.5rem;
  display: flex;
  flex: 0 1 calc(50% - 3rem);
  margin-top: 1rem;
  background-color: var(--other-background-color);
  box-shadow: 0px 1px 2px 0px #0000001f, 0px 0px 1px 0px #0000001f;
}

.mainBlock {
  flex: 1 1 auto;
  padding-inline-end: 1rem;
  display: flex;
  flex-direction: column;
}


.title {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  flex: 0 1 0%;
  color: var(--text-color);
}

.description {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: var(--grey-text-color);
  flex: 1 1 0;
}

.fakeBlock {

}

.downBlock {
  flex: 0 0 auto;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
}

.downCont {
  white-space: nowrap;
  display: flex;
  align-items: center;
}

.downText {
  margin-inline-end: 0.375rem;
  color: var(--text-color);
}

.img {
  width: 1.125rem;
  height: 1.125rem;
  opacity: 1;
}
