.mainPage {
  flex: 1 1 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: var(--background-color);
  padding-block-end: 1.5rem;
}
.madalMedia {
  position: fixed;
  overflow: visible;
  height: 100vh;
  width: 100vw;
  right: 0;
  left: 0;
  top: 0;
  bottom: 0;
  background: rgb(62, 62, 62);
  color: black;
  z-index: 9999999999 !important;
  display: flex;
  justify-content: center;
  align-items: center;
}
.closeIcon {
  position: absolute;
  top: 10px;
  right: 10px;
  background-color: white;
  border: 5px solid white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.mainContentBlockImg {
  object-fit: cover;
  width: 100%;
  height: auto;
  aspect-ratio: 3 / 2;
  pointer-events: none;
  transform: scale(1);
  transition: transform 150ms cubic-bezier(0.45, 0, 0.55, 1) 0s;
}
.mainImg {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}
.image-order {
  width: 100%;
  height: 320px;
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 850px) {
    background-size: contain;
    object-fit: cover;
    height: 260px;
  }
}
