.cardWrapper {
    height: 64px;
    width: 100%;
    margin-bottom: 1.5rem;
    border-bottom: 0.5px solid #f2f2f2;
}

.cardInfo {
    display: flex;
    justify-content: space-between;
}

.shopName {
    display: flex;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}

.paymentForOrder{
    display: flex;
    font-size: 1rem;
    font-weight: 500;
    margin-bottom: 0.5rem;
}
