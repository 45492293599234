.mainConteiner {
  margin-bottom: 2.5rem;
}

.title {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto,
    "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 650;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  padding: 0.5rem 0;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  margin: 0;
}
.btnMoreInfo {
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo,
    "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  text-transform: none;
  color: #1b5e3b;
  background-color: rgba(2, 172, 248, 0.1);
  height: 30px;
  padding: 0 10px;
  border-radius: 5px;
  display: flex;
  align-items: center;
}

.titleBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.cardConteiner {
  display: grid;
  grid-template-columns: repeat(3, minmax(0px, 1fr));
  gap: 1rem;
}

@media screen and (max-width: 1023px) {
  .cardConteiner {
    grid-template-columns: repeat(2, minmax(0px, 1fr));
  }
}

@media screen and (max-width: 639px) {
  .mainConteiner {
    margin-bottom: 0.625rem;
    margin-inline: 0px;
  }
  .title {
    padding-inline-start: 16px;
    font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto,
      "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
    font-variant-ligatures: common-ligatures;
    font-size: 1.25rem;
    line-height: 2.25rem;
    font-weight: 650;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    padding: 0.5rem 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
  }
  .cardConteiner {
    grid-template-columns: repeat(1, minmax(0px, 1fr));
  }
}
