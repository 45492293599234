.mainContainer {
  position: relative;
}

.mainBtn {
  all: unset;
  display: flex;
  width: 100%;
  box-sizing: border-box;
  cursor: pointer;
  overflow: hidden;
  border: none;
  padding: 0;
}

.mainBtnBlock {
  transition: opacity 300ms linear 150ms;
  display: flex;
  align-items: center;
}

.mainBtnCont {
  width: 4.44444rem;
  height: 2.5rem;
  overflow: hidden;
  border-radius: 0.25rem;
  position: relative;
}

.mainImg {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.downContainer {
  margin-inline-start: 1rem;
  margin-inline-end: 3.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  color: var(--text-color);
}

.downContainerText {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
}

.downDownContainer {
  display: flex;
  flex-wrap: wrap;
  gap: 0rem 0.5rem;
  align-items: center;
}

.downDownContainerText {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.8rem;
  line-height: 1rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: var(--blue-color);
}

.downDownList {
  display: flex;
  margin: 0px;
  padding: 0px;
  list-style: none;
}

.downDownItem {
  display: inline-flex;
  background: rgba(252, 98, 0, 0.08);
  border-radius: 0.5rem;
  max-width: 100%;
  width: fit-content;
  padding: 0.2rem 0.4rem;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.7rem;
  line-height: 0.7rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  color: rgb(254, 169, 13);
  white-space: nowrap;
}

.downDownText{
  text-overflow: ellipsis;
  overflow: hidden;
  align-self: center;
}

.downContent {
  position: absolute;
  inset-block: 0;
  inset-inline-end: 0;
  pointer-events: none;
  display: flex;
  align-items: center;
}

.downContentBlock {
  display: inline-block;
  position: relative;
  pointer-events: auto;
  pointer-events: none;
}

.downContentLabel {
  height: 1px;
  margin: -1px;
  width: 1px;
  position: absolute;
  border: 0;
  padding: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  white-space: nowrap;
  overflow: hidden;
}

.downContentInput {
  height: 1px;
  margin: -1px;
  width: 1px;
  position: absolute;
  border: 0;
  padding: 0;
  clip: rect(1px, 1px, 1px, 1px);
  clip-path: inset(50%);
  white-space: nowrap;
  overflow: hidden;
}

.downContentDownBlock {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  width: 10rem;
  border-radius: 0.5rem;
  text-align: center;
  display: flex;
  align-items: center;
  overflow: visible;
  box-sizing: border-box;
}

.boxShadow {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  justify-content: flex-end;
}


.secondBtn {
  -webkit-tap-highlight-color: transparent;
  padding: 0px;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  border: 2px solid transparent;
  -webkit-appearance: button;
  z-index: 1;
  cursor: pointer;
  pointer-events: auto;
  background-color: transparent;
  border-color: var(--grey-text-color);
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 25ms, background-color 75ms linear 25ms, border-color 75ms linear 25ms;
}

.secondBtnBlock {
  position: absolute;
  height: 1.75rem;
  min-width: 1.75rem;
  max-width: 1.75rem;
  border-radius: 50%;
  background-color: #fff;
  opacity: 0;
  transition: none 0s ease 0s;
}

.inSecondBtnBlock {
  width: 100%;
  height: 100%;
  padding: 0 0.4375rem;
  border-radius: 50%;
  background-color: #1b5e3b14;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #1b5e3b;
}

.svg {
  width: 0.875rem;
  height: 0.875rem;
  overflow: visible;
  fill: #1b5e3b;
}

.secondUpBtnBlock {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 1.5rem;
  height: 1.5rem;
  background-color: rgb(255, 255, 255);
  transform: scale(1);
  transition: transform 75ms cubic-bezier(0.4, 0, 0.2, 1) 0s, background-color 75ms linear 0s;
  width: 1.5rem;
  height: 1.5rem;
  background-color: transparent;
}


.secondDownBtnText {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  overflow: hidden;
  text-overflow: ellipsis;
  color: var(--blue-color);
}

.test {
  width: 50px;
  height: 50px;
  background: black;
}

.panelBtnItem {
  -webkit-tap-highlight-color: transparent;
  padding: 0px;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  width: 2.5rem;
  height: 2.5rem;
  border-radius: 0.5rem;
  border: 2px solid transparent;
  -webkit-appearance: button;
  z-index: 1;
  cursor: pointer;
  pointer-events: auto;
  background-color: transparent;
  transition: transform 200ms cubic-bezier(0.4, 0, 0.2, 1) 25ms, background-color 75ms linear 25ms, border-color 75ms linear 25ms;
  display: flex;
  flex-direction: row;
}

.deleteBtnImg {
  width: 1rem;
  height: 1rem;
  overflow: visible;
  fill: #1b5e3b;
}

.panelBtn {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgb(222, 246, 255);
  border-radius: 10px;
  box-shadow: 0 -5px 5px 20px rgba(255, 255, 255, 0.72);
}

.textBtnContent {
  color: #1b5e3b;
  font-size: 1rem;
  font-weight: bold;
}

.svgBtn {
  width: 0.875rem;
  height: 0.875rem;
  overflow: visible;
  fill: #1b5e3b;
}

