.mainConteiner {
  position: absolute;
  z-index: 998;
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: clamp(285px, 20vh, 620px);
  padding: 1rem 16px;
}
.killAn {
  padding: 0;
}
.panelNavigate {
  position: fixed;
  display: flex;
  justify-content: space-between;
  width: calc(100% - 2rem);
  z-index: 998;
  height: 40px;
}
.mainBox {
  background-color: white;
}

.mainStyle {
  background-color: rgba(255, 255, 255, 0.85);
  border-radius: 60px;
  height: 40px;
  width: 40px;
  z-index: 999999;
}
.mainSvg {
  fill: var(--text-color);
  margin-top: 3px;
}

.mainStyleDark {
  background-color: rgba(14, 14, 14, 0.8);
  border-radius: 60px;
  height: 40px;
  width: 40px;
  z-index: 999999;
}

.mainStyle2 {
  background-color: rgba(255, 255, 255, 0.68);
  border-radius: 60px;
  height: 40px;
  width: 40px;
  z-index: 999999;
  rotate: 90deg;
}

.whiteBlock {
  width: 100%;
  padding: 0 16px;
  display: flex;
  align-items: center;
  height: 3.5rem;
  z-index: 998;
  background-color: var(--background-color);
}
.backgroundNew {
  background-color: rgba(112, 113, 116, 0.2);
}

.shopName {
  font-weight: bold;
  font-size: 1.2rem;
}

.modalInfoPanel {
  position: fixed;
  width: 150px;
  height: 40px;
  border-radius: 0.5rem;
  right: 40px;
  top: 35px;
  background-color: var(--background-color);
  z-index: 9999999;
  padding: 12px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  box-shadow: 0px 1px 15px 1px rgba(0, 0, 0, 0.25);
}
.btnMoreInfo {
  font-size: 1.1rem;
  font-weight: 500;
  width: 100%;
  height: 100%;
  text-align: start;
  color: var(--text-color);
}