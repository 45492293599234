.mainConteiner {
  flex: 1 1 0;
  position: relative;
  padding-block-start: 0.5rem;
  box-sizing: border-box;
  background-color: var(--background-color);
  ::-webkit-scrollbar {
    width: 0;
  }
}
.mainBlock {
  max-width: calc(1500px + 2* 30px);
  margin: 0 auto;
  ::-webkit-scrollbar {
    width: 0;
  }
}

.mainContent{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

@media (max-width: 640px) {
  .mainContent {
    margin: 2.5rem 0 1rem;
    display: grid;
    grid-template-columns: 17.5rem calc(100% - 20.5rem);
    column-gap: 3rem;
    padding: 0 30px;
  }
}

.fakeBlock {
  position: sticky;
  bottom: 3rem;
  display: flex;
  justify-content: center;
  z-index: 4;
  margin: 0 30px;
}

.viewOrderBlock1 {
  border: none;
  position: relative;
  z-index: 0;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: 100%;
  max-width: 100%;
  cursor: pointer;
  padding: 0px 1rem;
  min-height: 3.375rem;
  border-radius: 0.5rem;
  background: transparent;
  color: #fff;;
}
.viewOrderBlock2 {
  position: absolute;
  inset: 0px;
  border-radius: 0.5rem;
  transition: background-color 120ms ease-out 0s;
  background: var(--blue-btn-background-color);
}
.viewOrderBlock3 {
  position: absolute;
  inset: 0px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}
.viewOrderBlock4 {
  flex: 1 1 0%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 150ms ease-in 0s, color 150ms linear 0s;
  border-radius: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}
.viewOrderMain {
  display: flex;
  align-items: center;
  white-space: nowrap;
  overflow: hidden;
  width: 100%;
  color: var(--blue-btn-text-color);
}
.viewOrderInfo {
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.9rem;
  line-height: 1rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  min-width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  padding: 0px 0.5rem;
  background-color: rgba(255, 255, 255, 0.15);
  color: white;
  margin-inline-end: 0.75rem;
}
.viewOrderText {
  overflow: hidden;
  text-overflow: ellipsis;
}
.viewOrderCost {
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  flex: 1 1 0%;
  margin-inline-start: 1.5rem;
  text-align: end;
}
.infoViewBlock {
  position: fixed;
  bottom: 10px;
  width: 100%;
  padding: 1rem 1rem;
  box-sizing: border-box;
  border-radius: 0.75rem;
  flex: 1 1 0;
  transform: none;
  opacity: 1;
  pointer-events: auto;
  display: none;
  z-index: 998;
}
.show {
  display: block;
}

@media screen and (max-width: 900px) {
  .mainContent {
    grid-template-columns: 13.5rem calc(100% - 17.5rem);
  }
}

@media screen and (max-width: 641px) {
  .mainContent {
    grid-template-columns: 100%;
    margin: 0 0 1rem;
    padding: 0 1rem;
  }
}
