@import "./../../../../styles/variables.scss";
.mainConteiner {
  position: sticky;
  top: 4.375rem;
  left: 0;
  right: 0;
  margin-right: 0;
  padding-right: 0;
  background-color: var(--background-color);
  z-index: 997;
}

.mainConteiner:before {
  content: "";
  height: 0.25rem;
  width: 100%;
  position: absolute;
  background-color: var(--background-color);
  top: -0.25rem;
  inset-inline-start: 0px;
  border-block-end: 1px solid var(--background-color);
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.06) 0 0 0.25rem 0, rgba(0, 0, 0, 0.12) 0 0.25rem 0.25rem 0;
}

.mainBlock {
  align-self: start;
  width: 100%;
  position: sticky;
  top: 6.375rem;
  height: calc(-10.375rem + 100vh);
}

.mainBlock:before {
  margin-block-end: 0;
  background-color: #fff;
}

.conteiner {
  position: relative;
  z-index: 0;
  width: 100%;
  height: 100%;
}

.categorysConteiner {
  position: relative;
  z-index: 0;
  height: 43px;
  box-sizing: border-box;
}

.categorysBlock {
  width: 100%;
  height: 43px;
  overflow: visible;
}

.content {
  height: 43px;
}

.categorysConteiner {
  border-radius: 0.5rem;
  background-color: rgb(255, 255, 255);
  box-shadow: rgba(0, 0, 0, 0.06) 0 0 0.125rem 0px, rgba(0, 0, 0, 0.12) 0 0.125rem 0.125rem 0;
}

@media screen and (max-width: 639px) {
  .categorysBlock {
    transform: translateY(0px);
    transition: transform 150ms ease-in-out 0ms;
  }
  .content {
    //display: flex;
    //flex-direction: row;
    //gap: 1rem;
    padding: 0 16px;
    overflow-x: auto;
    scrollbar-width: none;
    background-color: var(--background-color);;
    border-block-end: solid 1px #2021251f;
  }

  .mainConteiner {
    top: 0;
  }

  .conteiner {
    grid-template-columns: 100%;
  }

  .mainBlock {
    top: -1px;
    left: 0px;
    right: 0px;
    height: initial;
    z-index: 1;
  }
}
.category {
  text-align: center;
  padding: 4px 0;
  margin-right: 10px;
  background-color: var(--background-color);
  cursor: pointer;
  color: var(--grey-text-color);
  &__active {
   color: var(--blue-color);
    border-bottom: var(--blue-color) solid 2px;
  }
}

.slider {
  padding-bottom: 0.5px;
  border-bottom: 1.2px solid #e4e4e5;
}