.mainContainer {
  position: relative;
  height: clamp(320px, 20vh, 620px);
  padding: 1.5rem 30px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  z-index: 0;
  overflow: hidden;
  box-sizing: border-box;
}

.upBlock {
  z-index: 0;
  height: clamp(320px, 20vh, 620px);
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
}

@media (min-width: 640px) {
  .upBlock,
  .mainContainer {
    height: 450px;
  }
}
.middleBlock {
  position: absolute;
  inset: 0;
  z-index: 0;
  // background: linear-gradient(rgba(0, 0, 0, 0.61) 0%, rgba(0, 0, 0, 0.41) 100%);
}

.downBlock {
  flex: 1 1 0;
  max-width: 1540px;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  color: #fff;
}

.upBlockConteiner {
  height: 100%;
  width: 100%;
}

.upBlockImg {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  @media (min-width: 640px) {
    filter: brightness(60%);
  }
}


.downBlockConteiner {
  display: grid;
  align-content: end;
  width: 100%;
  grid-template-areas:
        "heading"
        "description"
        "lastRow";
}

.downBlockTitle {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 2.875rem;
  line-height: 3.5rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin: 0px;
  max-width: 50rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  grid-area: heading;
}

.downBlockTitleText {
  padding-inline-end: 3.5rem;
}

.downBlockDescription {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  margin-bottom: 0px;
  margin-top: 0.5rem;
  max-width: 50rem;
  max-height: 3rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  grid-area: description;
}

.downBlockDescriptionText {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  padding: 0px;
  margin: 0px;
}

.downBlockTagsBlock {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  grid-area: lastRow;
}

.downBlockTagsContainerRow {
  margin-top: 1.5rem;
  min-height: 1.5rem;
}

.downBlockTagsRow {
  opacity: 1;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  flex-wrap: wrap;
}

.infoConteiner {
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  position: relative;
  background: var(--background-color);
  // border-bottom: 1px solid #2021251f;
  // padding: 1.5rem 30px;
}

.infoConteiner::before {
  content: '';
  position: absolute;
  top: -30px; /* Смещаем вверх */
  left: 0%;
  width: 100%;
  height: 60px; /* Высота полукруга */
  background-color: white; /* Цвет совпадает с фоном */
  border-radius: 60%;
}

.infoConteinerDark {
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  position: relative;
  background: var(--background-color);
  // border-bottom: 1px solid #2021251f;
  // padding: 1.5rem 30px;
}

.infoConteinerDark::before {
  content: '';
  position: absolute;
  top: -30px; /* Смещаем вверх */
  left: 0%;
  width: 100%;
  height: 60px; /* Высота полукруга */
  background-color: black; /* Цвет совпадает с фоном */
  border-radius: 60%;
}

@media (min-width: 640px) {
  .infoConteinerShop {
    bottom: 50px;
    color: var(--background-color);
    background-color: transparent;
    font-size: 32px;
  }

}

.infoConteinerShop::before {
  content: none;
}
.infoBlock {
  max-width: 96.25rem;
  margin: auto;
  margin-top: -7%;
}
@media screen and (min-width: 640px) {
  .infoBlock {
    max-width: 100rem;
    margin-left: 176px;
  }
}

.infoRow {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 1rem;
  min-height: 0.125rem;
}

.leftBlock {
  position: relative;
  display: flex;
  align-items: stretch;
  @media (min-width: 640px) {
    font-size: 24px;
  }
}

.rightBlock {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
  gap: 1rem;
}

.leftRow {
  position: relative;
  opacity: 1;
}
.leftBlockBtn {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: 1rem 1.5rem;
}

@media screen and (max-width: 639px) {
  .leftBlockBtn {
    gap: 0.5rem;
  }
  .rightBlock{
    gap: 0.5rem;
  }

  .infoConteiner {
    // padding: 19px 16px;
  }
  .mainContainer {
    height: clamp(285px, 20vh, 620px);
    padding: 1rem 16px;
  }
  .upBlock {
    height: clamp(360px, 20vh, 620px);
  }
  .downBlockTitle {
    font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
    font-variant-ligatures: common-ligatures;
    font-size: 1.4rem;
    line-height: 2rem;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
  }
  .downBlockDescription {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    font-weight: 500;
    max-height: 3rem;
    margin-top: 0;
  }
  .downBlockDescriptionText {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: .9rem;
    line-height: 1.2rem;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    font-weight: 500;
    max-height: 3rem;
    margin-top: 0;
  }
  .downBlockTagsContainerRow {
    margin-top: 1rem;
  }
}

.mobileInfoBlock {
  width: 100%;
  // padding: 0 0;
  // padding-top: 1rem;
}
.mobileTitleBlock {
  display: flex;
  justify-content: center;
  height: 3.7rem;
  align-items: center;
}
.mobileTitle {
  z-index: 100;
  font-weight: 800;
  font-size: 2rem;
  line-height: 1.6rem;
  word-break: normal;
  display: flex;
  align-items: center;
}
.mobileDescription {
  font-weight: 400;
  font-size: 1.1rem;
}
.mobileBlockTaggleBlock {
  margin-top: 0.5rem;
  // display: flex;
  // flex-direction: row;
  // flex-wrap: wrap;
  white-space: nowrap;
  width: 100%;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  // gap: .25rem;
  // align-items: center;
  // height: 2rem;
  color: var(--cb-color-text-subdued);
  padding: 0px 0.5rem;
}
.mobileBlockTaggleRow {
  gap: 0.25rem;
  display: flex;
  align-items: center;
  color: var(--light-grey-text-color);
}
.mobileBlockTaggleRow:not(:first-child)::before {
  content: "·";
  position: relative;
  display: inline-block;
  margin: 0 0.25rem;
  white-space: nowrap;
}

.mobileBlockTaggleRowDark {
  gap: 0.25rem;
  display: flex;
  align-items: center;
  color: #8c8d8ea3;
}
.mobileBlockTaggleRowDark:not(:first-child)::before {
  content: "·";
  position: relative;
  display: inline-block;
  margin: 0 0.25rem;
  white-space: nowrap;
}

.mobileBlockTaggleFirstLine {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

.mobileBlockTaggleSecondLine {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.25rem;
}

.mobileLeftInfo {
  font-size: 1.2rem;
}
.mobileMiddleInfoBlock {
  display: flex;
  justify-content: space-between;
  margin-left: 1rem;
  align-items: center;
  width: 100%;
}
.mobileMiddleInfo {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
    font-size: 0.875rem;
    line-height: 1rem;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    font-weight: 400;
}
.mobileMiddleInfoDark {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 1rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: #8c8d8ea3
}
.mobileInfoBtn {
  color: #1b5e3b;
  // background-color: rgba(2, 172, 248, 0.1);
  height: 30px;
  padding: 0 10px;
  font-weight: 700;
  // border-radius: 5px;
}
.mobileMiddleMidInf {
  display: flex;
  flex-direction: column;
}
.mobileDownText {
  opacity: 0.5;
}
.svg {
  fill: var(--grey-text-color);
}
.svg2 {
  strong: var(--grey-text-color)
}
.svg3 {
  fill: white;
}
.smileImg {
  min-width: 0.75rem;
  min-height: 0.75rem;
  width: 1rem;
  height: 1rem;
  margin-inline-end: 0.1rem;
  fill: #202125a3;
  color: var(--grey-text-color);
}
.smileImgDark {
  min-width: 0.75rem;
  min-height: 0.75rem;
  width: 1rem;
  height: 1rem;
  margin-inline-end: 0.1rem;
  fill: #8c8d8ea3;
  color: #8c8d8ea3;
}




