.mainConteiner {
  height: 100%;
  padding: 0.5rem;
}

.createBtn {
  width: 100%;
  height: 3rem;
  background-color: var(--blue-btn-background-color);
  color: var(--blue-btn-text-color);
  border-radius: 0.5rem;
  margin: 0.5rem 0;
  font-size: 1.1rem;
  font-weight: bold;
}

//.contentBlock {
//  display: flex;
//  flex-direction: column;
//  padding: 1rem 0.5rem;
//  margin: 1rem 0;
//  height: 3vh;
//  overflow: scroll;
//}
.curierBig {
  width: 100%;
}

.curierBlock {
  display: flex;
  align-items: center;
  justify-content: start;
  height: 3rem;
  border-radius: 1rem;
  border: 1px solid rgba(169, 169, 169, 0.3);
  padding: 0 1rem;
  position: relative;
}
.nameCurier {
  font-size: 1.25rem;
  font-weight: bold;
  margin-left: 1rem;
  color: rgba(169, 169, 169, 0.9);
}
.svgBlock {
  right: 1rem;
  position: absolute;
}