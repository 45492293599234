.mainConteiner {
  margin-block-end: 2.5rem;
}

.mainBlock {
  display: grid;
  gap: 1.5rem 1rem;
  grid-template-columns: repeat(auto-fill, minmax(min(32%, 420px), 1fr));
  margin-top: 1.5rem;
}

.headerBlock {
  display: flex;
  align-items: baseline;
  gap: 0.5rem;
  color: #202125;
}

.headerText {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  max-width: 37.5rem;
  margin: 0;
  padding: 0;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

@media screen and (max-width: 1199px) {
  .mainBlock {
    grid-template-columns: repeat(2, minmax(316px, 1fr));
  }
}

@media screen and (max-width: 719px) {
  .mainBlock {
    margin-top: 0.5rem;
    grid-template-columns: 100%;
  }
}