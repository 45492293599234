.container {
  position: fixed;
  width: 100vw;
  height: 100vh;
  pointer-events: none;
}

.widget {
  position: fixed;
  top: 100px;
  right: 30px;
  width: 70px;
  height: 70px;
  background-color: #1b5e3b;
  color: #fff;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  pointer-events: auto;
  z-index: 10000000000000;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  touch-action: none;
  user-select: none;
  @media (min-width: 640px) {
    top: 143px;
  }
}

.widget::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: url('./pie.jpg');
  background-size: cover;
  border-radius: 50%;
  z-index: -1;
}

.closeButton {
  touch-action: manipulation;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 30px;
  height: 30px;
  background-color: #e74c3c;
  color: #fff;
  border: none;
  border-radius: 50%;
  font-size: 16px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 5;
}

.widgetHalfGreen:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  border-radius: 50%;
  background: linear-gradient(to bottom, transparent 50%, rgba(49,194,31,0.9) 50%);
}

.widgetGreen:after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 2;
  border-radius: 50%;
  background:  rgba(49,194,31,0.9);
}
