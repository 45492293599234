.lvl1 {
  margin: 0 auto;
  max-width: 900px;
  padding: 1.1875rem 0;
  position: relative;
}
.lvl2 {
  padding: 1.25rem 0;
  position: relative;
}
.lvl3 {
  opacity: 1;
}

.mainConteiner {
  margin-left: 30px;
  margin-right: 30px;
}

.mainBlock {
  margin: auto;
  max-width: 500px;
  text-align: center;
}

.svgBlockCont {
  left: 0;
  margin: 0 auto;
  right: 0;
  width: 90%;
  position: relative;
  padding-bottom: 58.6667%;
}

.svgBlockMain {
  position: absolute;
  width: 100%;
  opacity: 1;
}

.svgBlockM {

}

.svgBlockB {
  background: transparent;
  margin: 0px auto;
  outline: none;
  overflow: hidden;
}

.middleConteiner {
  margin-top: 1rem;
  text-align: start;
  width: 100%;
}

.middleConteinerTitle {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 2rem;
  line-height: 2.5rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  padding: 0 0 1rem;
  margin: 0;
  text-align: center;
}

.middleBlock {
  display: flex;
  flex-direction: row;
  margin-bottom: 0.5rem;
}

.middleBlockNum {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  display: flex;
  align-items: center;
  justify-content: center;
  flex: 0 0 auto;
  margin-inline-end: 1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  background-color: #1b5e3b14;
  color: #1b5e3b;
}

.downButton {
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: center;
  min-height: 1rem;
}

.btn {
  padding: 0;
  border: none;
  background: none;
  text-align: start;
  display: inline-flex;
  align-items: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  color: #1b5e3b;
  text-decoration: none;
  user-select: inherit;
  white-space: nowrap;
  cursor: pointer;
}

.mainButtonCont {
  margin: 1rem 0px;
}

.mainButton {
  width: 100%;
  padding: 1rem 1.5rem;
  background: var((--dark-grey-color));
  border: 1px solid #2021251f;
  border-radius: 0.5rem;
  cursor: pointer;
  color: var(--text-color);
}

.mainButtonContent {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.spanCont {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
}

.spanOne {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: var(--grey-text-color);
  margin-bottom: 0.25rem;
}

.spanSecond {
  font-size: 1.5rem;
  padding-top: .25rem;
}

.imgBlock {
  align-items: center;
  border: 1px solid #2021251f;
  border-radius: 10px;
  border-radius: 50%;
  display: flex;
  height: 40px;
  justify-content: center;
  width: 40px;
}

.imga {
  height: 20px;
  width: 20px;
}

.goBackButton {
  display: none;
  position: fixed;
  width: 32px;
  height: 32px;
  z-index: 9999999;
  top: 1%;
  left: 3%;
}

@media screen and (max-width: 640px) {
  .goBackButton {
    display: block;
  }
}