.mainConteiner {
  position: sticky;
  top: 4.375rem;
  left: 0;
  right: 0;
  margin-right: 0;
  padding-right: 0;
  background-color: var(--background-color);
  z-index: 9999;
}

.mainConteiner:before {
  content: "";
  height: 0.25rem;
  width: 100%;
  position: absolute;
  background-color: var(--background-color);
  top: -0.25rem;
  inset-inline-start: 0px;
  border-block-end: 1px solid var(--background-color);
}

.shadow {
  box-shadow: rgba(0, 0, 0, 0.06) 0 0 0.25rem 0, rgba(0, 0, 0, 0.12) 0 0.25rem 0.25rem 0;
}

.mainBlock {
  position: relative;
  height: 4.25rem;
  margin-block-end: 0;
  background-color: var(--background-color);
  transition: background-color 150ms linear 0s;
  z-index: 1;
}

.mainBlock:before {
  margin-block-end: 0;
  background-color: var(--background-color);
}

.conteiner {
  max-width: calc(1540px + 2* 4px);
  margin: 0 auto;
  height: 100%;
  display: grid;
  grid-template-columns: calc(100% - 19rem) 17.5rem;
  column-gap: 1.5rem;
  align-items: center;
}

.categorysConteiner {
  display: flex;
  justify-content: space-between;
}

.categorysBlock {
  height: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

@media screen and (max-width: 639px) {
  .categorysConteiner {
    clip-path: inset(0px 0px -10px);
    padding: 1.5rem 0px 2rem;
    height: 0rem;
    margin-top: -0.3125rem;
    background-color: #fff;
    transition: height 200ms ease-in-out 0s, padding 200ms ease-in-out 0s;
    overflow-x: auto;
    overscroll-behavior: none;
    scrollbar-width: none;
  }
  .categorysBlock {
    padding: 0px 1rem;
  }
  .mainConteiner {
    top: 0;
  }

  .conteiner {
    grid-template-columns: 100%;
  }
}