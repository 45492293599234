.mainConteiner {
  position: sticky;
  top: 4.375rem;
  left: 0;
  right: 0;
  margin-right: 0;
  padding-right: 0;
  background-color: white;
  z-index: 100;
}

.shadowUse {
  box-shadow: rgba(0, 0, 0, 0.06) 0 0 0.25rem 0, rgba(0, 0, 0, 0.12) 0 0.25rem 0.25rem 0;
}

.shadowUse:before {
  content: "";
  height: 3px;
  width: 100%;
  position: absolute;
  background-color: #fff;
  top: -0.25rem;
  inset-inline-start: 0;
  border-block-end: 1px solid #2021251f;
}

.mainBlock {
  position: relative;
  height: 4.25rem;
  margin-block-end: 0;
  background-color: transparent;
  transition: background-color 150ms linear 0s;
  z-index: 1;
}

.mainRow {
  max-width: 1540px;
  margin: 0 auto;
  height: 100%;
  display: grid;
  grid-template-columns: calc(100% - 19rem) 17.5rem;
  column-gap: 1.5rem;
  align-items: center;
}

.upBody {
  display: flex;
  justify-content: space-between;
}

.categorysConteiner{
  height: 100%;
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.moreBlock {
  display: flex;
  align-items: center;
  margin-inline-start: 0.75rem;
}

.moreBtn {
  border: none;
  background: none;
  text-align: start;
  display: inline-flex;
  align-items: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  color: #1b5e3b;
  text-decoration: none;
  user-select: inherit;
  white-space: nowrap;
  cursor: pointer;
  padding: 0.5rem 0.75rem;
  border-radius: 1rem;
  color: #202125;
  background-color: transparent;
}

.none {
  display: none;
}

@media screen and (max-width: 639px) {
  .upBody {
    box-shadow: rgba(0, 0, 0, 0.06) 0 0 0.25rem 0, rgba(0, 0, 0, 0.12) 0 0.25rem 0.25rem 0;
    clip-path: inset(0px 0px -10px);
    padding: 1.5rem 0 2rem;
    height: 0;
    margin-top: -0.3125rem;
    background-color: #fff;
    transition: height 200ms ease-in-out 0s, padding 200ms ease-in-out 0s;
  }
}

@media screen and (max-width: 639px) {
  .categorysConteiner {
    padding: 0 1rem;
  }
}

@media screen and (min-width: 640px) {
  .mainRow {
    padding: 0 30px;
  }

  .categorysConteiner {
    flex-grow: 1;
    overflow: hidden;
  }
}

