.mainConteiner {
  width: 100%;
  margin: 0 auto;
  padding: 3.4rem 12rem;
  min-height: calc(100vh);
  overflow: hidden;
  box-sizing: border-box;
}

.conteiner {
  margin: 0;
  padding: 0;
  display: block;
  unicode-bidi: isolate;
}

@media screen and (max-width: 639px) {
  .mainConteiner {
    padding: 1rem 16px;
  }
}
