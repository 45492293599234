.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 5px;
  @media (min-width: 641px) {
    width: 85%;
    padding: 0 50px 0 0;
  }
}

.selected {
  background-color: red;
}