.mainConteiner {
  flex: 1 1 0;
  position: relative;
  padding-block-start: 0.5rem;
}

.mainBlock {
  /* Ширина видимой части экрана за вычетом боковых отступов */
  --viewport-width: calc(100vw - 2 * var(--side-padding));

  /* Максимальная ширина контента */
  --max-content-width: 1540px;

  /* Дополнительное пространство */
  --extra-space: 20.5rem;

  /* Финальная ширина контента */
  --content-width: calc(min(var(--viewport-width), var(--max-content-width)) - var(--extra-space));
}

.fakeBlock {
  display: block;
  position: relative;
  top: -5.875rem;
  visibility: hidden;
}

.textBlock {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.5rem;
}

.title {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-variant-ligatures: common-ligatures;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin: 0;
  padding: 0;
  align-self: baseline;
}

.fakeText {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: var(--grey-text-color);
}

.categoryBlockUp {
  margin-block-start: 1.5rem;
}

.categoryBlockMid {
  overflow: visible;
}

.categoryBlockDown {
  opacity: 1;
  display: grid;
  padding-block-end: 1rem;
}
.btnMoreInfo {
  color: #1b5e3b;
  font-weight: 600;
  background-color: rgba(2, 172, 248, 0.1);
  height: 30px;
  padding: 0 10px;
  border-radius: 5px;
}

@media screen and (min-width: 0px) {
  .mainBlock {
    --product-image-height: calc(0.6666666666666666*(var(--content-width) - 1rem) / 2);
  }
  .categoryBlockMid {
    height: calc(calc(178* var(--product-image-height)) + 1268.75rem);
  }
  .categoryBlockDown {
    gap: 1rem;
    grid-template-columns: 1fr 1fr;
    height: calc(calc(12* var(--product-image-height)) + 85.5rem);
  }
}

@media screen and (min-width: 640px) {
  .categoryBlockDown {
    gap: 1rem;
    grid-template-columns: 1fr 1fr 1fr;
    height: calc(calc(12* var(--product-image-height)) + 85.5rem);
  }


}

//@media screen and (min-width: 1080px) {
//  .mainBlock {
//    --product-image-height: calc(0.6666666666666666*(var(--content-width) - 2rem) / 3);
//
//  }
//  .categoryBlockMid {
//    height: calc(calc(119* var(--product-image-height)) + 848.375rem);
//  }
//  .categoryBlockDown {
//    gap: 1rem;
//    grid-template-columns: 1fr 1fr 1fr;
//    height: calc(calc(8* var(--product-image-height)) + 57rem);
//  }
//}

@media screen and (max-width: 640px) {
  .title {
    font-size: 1.25rem;
    line-height: 1.4rem;
  }
}


