.pageContent {
  width: 100%;
  height: 100vh;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  overflow-x: auto;
  @media (max-width: 640px) {
    flex-direction: column;
    justify-content: space-between;
    height: 100vh;

  }
  @media (min-width: 641px) {
    flex-direction: row-reverse;
    justify-content: left;
  }
}