body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
//.custom-prev-arrow {
//  cursor: pointer;
//  background-color: red;
//  padding: 0;
//  border: none;
//  background-image: none;
//  background-position: initial;
//  background-size: initial;
//  background-repeat: initial;
//  background-attachment: initial;
//  background-origin: initial;
//  background-clip: initial;
//  position: relative;
//  flex-shrink: 0;
//  display: inline-flex;
//  align-items: center;
//  justify-content: center;
//  width: 2.5rem;
//  height: 2.5rem;
//  border-radius: 50%;
//  transition: background-color 120ms ease-out 0s;
//}
//
//.custom-next-arrow {
//  cursor: pointer;
//  padding: 0;
//  border: none;
//  background-image: none;
//  background-position: initial;
//  background-size: initial;
//  background-repeat: initial;
//  background-attachment: initial;
//  background-origin: initial;
//  background-clip: initial;
//  position: relative;
//  flex-shrink: 0;
//  display: inline-flex;
//  align-items: center;
//  justify-content: center;
//  width: 2.5rem;
//  height: 2.5rem;
//  border-radius: 50%;
//  transition: background-color 120ms ease-out 0s;
//  background-color: #1b5e3b14;
//
//}
//.custom-next-arrow:hover{
//  background-color: rgba(0, 157, 224, 0.18);
//}
//.custom-prev-arrow:hover{
//  background-color: rgba(0, 157, 224, 0.16);
//}