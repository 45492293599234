.mainConteiner {
  position: absolute;
  top: 0;
  width: 100%;
  height: 2.5rem;
  background-color: rgb(251, 251, 251);
  z-index: 1;
}

.mainBlock {
  position: relative;
  box-sizing: border-box;
  color: rgb(32, 33, 37);
  border-radius: 1.25rem;
  height: 2.5rem;
  backdrop-filter: none;
}

.fakeBlock {
  position: absolute;
  inset: 0;
  background: #fff;
  border-radius: 1.25rem;
}

.inputField {
  border: 0;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  position: relative;
  display: block;
  margin: 0;
  padding-top: 0;
  padding-bottom: 0;
  padding-inline: 2.5rem 1rem;
  box-sizing: border-box;
  border-radius: 1.25rem;
  color: inherit;
  height: 2.5rem;
  width: 100%;
  max-width: 100%;
  transition: background-color 70ms linear 0s;
  caret-color: #1b5e3b;
  background: rgba(32, 33, 37, 0.12);
}

.inputField:focus {
  outline: none;
  border: 2.5px solid #1b5e3b;
}

.img {
  width: 1rem;
  position: absolute;
  inset-inline-start: 1rem;
  top: 50%;
  transform: translateY(-50%);
  fill: currentcolor;
}

.mainBtn {
  position: absolute;
  inset-inline-end: 0.5rem;
  top: 50%;
  transform: translateY(-50%);
  width: 2rem;
  height: 2rem;
}

.btn {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  outline: none;
  cursor: pointer;
  color: inherit;
  transition: transform 100ms ease-in 0s;
}

.active {
  transform: scale(0);
}

.btnImg {
  width: 1rem;
  fill: currentcolor;
}