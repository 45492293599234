.mainBlock {
  grid-column: 3;
  -ms-grid-column: 3;
  height: calc(100% + 7.5rem);
  position: relative;
  top: -7.5rem;
}

.mainBlockOne {
  position: sticky;
  top: 6rem;
  width: 100%;
}

.mainBlockSecond {
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  background-color: var(--background-color);
  box-shadow: 0 0 5px 3px #bcbcbc;
  padding: 1.5rem;
}

.mainBlockText {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-variant-ligatures: common-ligatures;
  font-size: 1.75rem;
  line-height: 2.25rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  display: flex;
  padding-bottom: 1.5rem;
  justify-content: space-between;
}

.addField {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: var(--grey-text-color);
}

.btnField {
  padding: 0px;
  border: none;
  background: none;
  text-align: start;
  display: inline-flex;
  align-items: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  color: #1b5e3b;
  text-decoration: none;
  user-select: inherit;
  white-space: nowrap;
  cursor: pointer;
}

.imgField {
  fill: #1b5e3b;
  width: 1.25rem;
  height: 1.25rem;
}

.middleBlock {
  display: flex;
  flex-direction: column;
  margin: 0px;
  list-style: none;
  padding: 0px 0px 1.5rem;
  z-index: 1;
}

.upMiddleBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}

.upMiddleBlockText {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  display: block;
  margin: 0;
  padding: 0 0 0.3125rem;
}

.preMiddleBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.upMiddleBlockP {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: var(--grey-text-color);
  margin: 0;
  padding: 0 4rem 0 0;
}

.link {
  padding: 0px;
  border: none;
  background: none;
  text-align: start;
  display: inline-flex;
  align-items: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  color: #1fc70a;
  user-select: inherit;
  white-space: normal;
  cursor: pointer;
  text-decoration: none;
  display: inline;
}

.discountBlock {
  display: flex;
  flex-direction: column;
  margin: 0;
  list-style: none;
  padding: 0 0 1.5rem;
  padding: 1.5rem 0px;
  border-top: 1px solid #20212514;
}

.OrderDiscount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.OrderDiscount1 {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  display: block;
  margin: 0;
  font-weight: 700;
  padding: 0 0 0.3125rem;
}

.OrderDiscount2 {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  display: block;
  margin: 0;
  padding: 0 0 0.3125rem;
}

.OrderDiscountItem {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.OrderDiscountItem1 {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  display: flex;
  align-items: center;
  padding: 0.3125rem 0;
}

.downOrderDiscount {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}

.downOrderDiscountDt {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  display: block;
  margin: 0;
  padding: 0.3125rem 0px 0px;
}

.downOrderDiscountBtn {
  padding: 0px;
  border: none;
  background: none;
  text-align: start;
  display: inline-flex;
  align-items: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  color: #1b5e3b;
  text-decoration: none;
  user-select: inherit;
  white-space: nowrap;
  cursor: pointer;
}

.downConteiner {
  display: flex;
  flex-direction: column;
  margin: 0;
  list-style: none;
  padding: 1.5rem 0;
  border-top: 1px solid #20212514;
}

.downConteinerMain {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.defText {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  padding: 0 0 0.3125rem;
}

.fakeBlock {
  display: none;
}

.fakeBlockNo {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0px;
}

.defTextTwo {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  display: block;
  margin: 0;
}

.defTextTwoText {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: var(--grey-text-color);
  margin: 0;
}

.btnMainBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0;
}

.btnConteiner {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  display: block;
  margin: 0;
  width: 100%;
  padding: 0.3125rem 0 0;
}

.btnUpBlock {
  display: flex;
  justify-content: center;
  margin-top: 1rem;
  width: 100%;
}

.btnUpConteiner {
  flex: 1 1 0;
  background-color: var(--background-color);
  border-radius: 0.5rem;
}

.btn {
  border: none;
  position: relative;
  z-index: 0;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  width: auto;
  max-width: 100%;
  cursor: pointer;
  padding: 0px 1rem;
  min-height: 3.375rem;
  border-radius: 0.5rem;
  background: transparent;
  color: rgb(255, 255, 255);
  width: 100%;
  padding: 1rem;
}

.fakeOne {
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
  transition: background-color 120ms ease-out 0s;
  background: rgb(96, 255, 128);
}

.notFake {
  flex: 1 1 0%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 150ms ease-in 0s, color 150ms linear 0s;
  border-radius: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

.testBlock {
  align-items: center;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
}

.testTextOne {
  flex: 1;
  line-height: 1.2;
  min-height: 1rem;
}

.testTextSecond {
  font-size: .875rem;
  font-weight: 400;
  margin-top: .5rem;
}

.fantomBlock {
  display: none;
}

.fantomImg {
  width: 2.75rem;
  height: 2.75rem;
  display: inline-block;
  animation: 1500ms linear 0s infinite normal none running GLFYz;
}

@media screen and (max-width: 1024px) {
  .mainBlock {
    top: 0;
  }
}















