.mainBlock {
  height: 100%;
  width: 100%;
  padding: 0.5rem;
}

.personSubInfoBlock {
  width: 100%;
  margin-top: 1rem;
  border: 1px solid rgba(169, 169, 169, 0.35);
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 0.5rem;
}

.notHave {
  font-weight: 800;
  font-size: 1.2rem;
  color: #FF4D4F;
}

.infoSubTab {
  background-color: rgb(2, 172, 248);
  margin-top: 2rem;
  border-radius: 1.5rem;
  color: white;
  display: flex;
  flex-direction: column;
  padding: 0 2.5rem;
}
.titleRow {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 3.5rem;
  font-weight: bold;
  font-size: 1.3rem;
}
.costSub {
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  font-size: 2.2rem;
}
.infoForStore {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 2rem;
  font-weight: bold;
  font-size: 1.3rem;
}
.btnBuy {
  margin-top: 2rem;
  width: 100%;
  padding: 0 3rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: white;
  color: black;
  border-radius: 1rem;
  height: 3rem;
  font-weight: bold;
  font-size: 1.2rem;
}

.infoSubConteiner {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  align-items: flex-start;
}
.infoRow {
  font-size: 1rem;
}
.infoRowA {
  font-size: 1.15rem;
}
.active{
  color: #1fc70a;
}
.inactive {
  color: #FF4D4F;
}