.container {
  display: flex;
  align-items: center;
  gap: 0.75rem;
  margin-inline-start: 0.75rem;
}

.containerBtn {
  width: 4.5625rem;
  background-color: #2021250a;
  transition: background-color 150ms linear 0s;
  padding-top: 0.1875rem;
  padding-bottom: 0.1875rem;
  padding-inline: 0.15625rem 0.3125rem;
  backdrop-filter: none;
  height: 2.5rem;
  border-radius: 1.25rem;
  flex-shrink: 0;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;
  border: 0;
  &__Shop {
    @media (min-width: 640px) {
      background-color: var(--light-background-color);
    }
  }
  &__ShopScrolled {
    @media (min-width: 640px) {
      background-color: rgba(169, 169, 169, 0.3);
    }
  }
}

.leftImg {
  width: 2.1875rem;
  height: 2.1875rem;
  border-radius: 50%;
  box-sizing: border-box;
  border: 0.125rem solid rgb(255, 255, 255);
  fill: var(--grey-text-color);
  background-color: var(--other-background-color);
}

.rightImg {
  width: 1.5rem;
  fill: var(--text-color);
  transition: transform 70ms ease-in 0s, fill 150ms linear 0s;
}

.dropdown {
  position: absolute;
  top: 100%;
  background-color: var(--other-background-color);
  border: 1px solid #ccc;
  padding: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  left: calc(100% - 200px);
  width: 174px;
  border-radius: 10px;
  height: auto;
  z-index: 10000;
}

.dropdownShop {
  position: absolute;
  top: 100%;
  background-color: var(--other-background-color);
  border: 1px solid #ccc;
  padding: 4px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);

  width: 174px;
  border-radius: 10px;
  height: auto;
  z-index: 10000;
  left: calc(100% - 480px)
}

.dropdownConteinerBtn {
  display: block;
  align-items: center;
  padding: 4px 0;
  cursor: pointer;
}

.dropdownConteinerBtn1 {
  display: block;
  align-items: center;
  padding: 4px 0;
}

.btn {
  display: block;
  background-color: transparent;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
  height: 40px;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: var(--cb-color-text);
  border-radius: 0.5rem;
  box-sizing: border-box;
  width: 100%;
  text-align: start;
  padding: 0.5rem 1rem;
}

.dropdownConteinerBtn:nth-child(2)::after {
  content: "";
  position: absolute;
  top: 100px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: #ccc; /* цвет линии разделения */
}

.btn:hover {
  background-color: var(--background-color);
  color: var(--text-color);
}

@media screen and (max-width: 639px) {
  .container {
    height: 32px;
  }
  .containerBtn {
    height: 32px;
    width: 59px;
  }

  .leftImg {
    height: 32px;
    width: 32px;
  }
  .rightImg {
    width: 22px;
    height: 22px;
  }
  .container {
    margin: 0;
    display: flex;

  }
}
