.mainPage {
  position: relative;
  margin: 0px 0px 3rem;
  list-style-type: none;
}

.upBlock {
  margin: 0;
  padding: .5rem .625rem;
  padding: 0;
}

.titleBlock {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.03125rem;
  text-transform: none;
  color: var(--text-color);
  margin-bottom: 2rem;
}

.liBlock {
  opacity: 1;
  height: auto;
  margin-top: 0;
  border: 1px solid #2021251f;
  border-radius: 8px;
  margin-top: 4px;
  transition: box-shadow .15s ease-in-out;
  background: transparent;
  margin: 0;
  padding: 0 1.3rem;
  position: relative;
  transition: background .25s ease-out;
}

.linkBlock {
  color: inherit;
  margin: 0px -1.3rem;
  padding: 0px 1.3rem;
  transition: all 0.2s ease-out 0s;
  cursor: pointer;
  border-radius: 0.25rem;
  display: flex;
  flex: 1;
  margin: 0 -16px;
  padding: 0 16px;
}

.middleBlock {
  display: inline-block;
  margin-right: 1rem;
  margin-top: 1rem;
  margin-inline-end: 1rem;
  margin-inline-start: 0;
  position: relative;
  vertical-align: middle;
  width: 24px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 0;
}

.middleDownBlock {
  box-sizing: border-box;
  flex: 1;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.1;
  min-width: 0;
}

.middleDownCont {
  padding-top: 1.2rem;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  margin-bottom: 0.35rem;
  color: #202125;
  padding-top: 1.5rem;
  margin-bottom: 1.5rem;
  color: var(--text-color);
}

.downBlock {
  color: #202125a3;
  font-size: .8125rem;
  text-align: right;
  align-self: center;
  display: flex;
  padding: 0;
}

.downImg {
  color: #202125;
  height: 24px;
  transform: rotate(0deg);
  transition: transform .15s ease-out;
  width: 24px;
}

.imgUp {
  color: rgb(32, 33, 37);
  width: 24px;
  fill: #202125;
}

.itemConteiner {
  margin: 0 0 1rem;
  position: relative;
  margin: 0;
}

.itemConteinerBlock {
  margin: 0;
  padding: .5rem .625rem;
  padding: 0;
  color: var(--text-color);
}

.titleItemsBlock {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-weight: 500;
  font-size: 1rem;
  line-height: 1.25rem;
  letter-spacing: 0.03125rem;
  text-transform: none;
  color: #202125;
  margin-bottom: 2rem;
  color: var(--text-color);
}

.listBlockCart {
  background: var(--background-color);
  border: 1px solid #2021251f;
  border-radius: 5px;
  list-style-type: none;
  border: none;
}

.addMoreBlock {
  margin: 0 0 1rem;
  position: relative;
  margin-top: 1.5rem;
}

.addMoreUl {
  background: transparent;
  border: none;
  border-radius: 0;
  margin-bottom: 4rem;
}

.addMoreLink {
  padding: 0px;
  border: none;
  background: none;
  text-align: start;
  display: inline-flex;
  align-items: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  color: var(--cb-color-text);
  user-select: inherit;
  white-space: normal;
  cursor: pointer;
  text-decoration: none;
}

.addMoreText {
  display: inline-block;
  flex-shrink: 0;
  line-height: 0;
  margin-inline: 0px 0.5rem;
}

.addMoreSvg {
  fill: #202125;
  width: 14px;
}

.titleMiddleBlock {
  margin: 0;
  padding: .5rem .625rem;
  padding: 0;
}

.titleMiddle {
  font-feature-settings: "kern", "ss01", "ss05", "ss07";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 2.75rem;
  line-height: 2.25rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
}

.ulCash {
  position: relative;
  margin: 1rem 0 4rem 0;
  list-style-type: none;
  overflow: hidden;
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.liCash {
  background: transparent;
  margin: 0;
  padding: 0 1.3rem;
  position: relative;
  transition: background .25s ease-out;
  border: 1px solid #2021251f;
  border-radius: 8px;
  margin-top: 4px;
  transition: box-shadow .15s ease-in-out;
}

.linkBlockCash {
  color: inherit;
  margin: 0px -1.3rem;
  padding: 0px 1.3rem;
  transition: all 0.2s ease-out 0s;
  cursor: pointer;
  border-radius: 0.25rem;
  display: flex;
  flex: 1;
  margin: 0 -16px;
  padding: 0 16px;
}

.fakeCashBlock {
  margin: 0 -1.3rem;
}

.upCashBlock {
  display: inline-block;
  margin-right: 1rem;
  margin-top: 1rem;
  margin-inline-end: 1rem;
  margin-inline-start: 0;
  position: relative;
  vertical-align: middle;
  width: 24px;
  align-items: center;
  display: flex;
  justify-content: center;
  margin-top: 0;
}

.upCashBlockImg {
  height: 24px;
  width: 24px;
  fill: #202125;
}

.miggleCashBLock {
  box-sizing: border-box;
  flex: 1;
  font-size: 1.0625rem;
  font-weight: 400;
  line-height: 1.1;
  min-width: 0;
}

.miggleCashConteiner {
  padding-top: 1.2rem;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  margin-bottom: 0.35rem;
  color: var(--text-color);
}

.miggleCashConteinerText {
  display: inline-block;
}

.miggleCashBLockFake {
  color: var(--grey-text-color);
  font-size: .9375rem;
  letter-spacing: -.21px;
  line-height: 1.2;
  margin-bottom: 1.1rem;
  margin-inline-end: 1rem;
}

.downCashBLock {
  color: #202125a3;
  font-size: .8125rem;
  text-align: right;
  align-self: center;
  display: flex;
  padding: 0;
}

.downCashImg {
  color: #202125;
  height: 24px;
  transform: rotate(0deg);
  transition: transform .15s ease-out;
  width: 24px;
}

.promoBlock {
  margin-bottom: 4rem;
}

.promoBlockTitle{
  margin-bottom: 2rem;
}

.formBlock {
  margin: 1rem 0px;
  display: flex;
  width: 100%;
}

.formConteiner {
  flex: 3 1 0;
}

.formConteiner {
  position: relative;
  width: 100%;
}

.formInput {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  appearance: none;
  box-shadow: none;
  box-sizing: border-box;
  width: 100%;
  color: #202125;
  transition: border 0.2s linear 0s;
  border-radius: 0.5rem;
  border: 2px solid #2021251f;
  padding: 0.75rem 0.875rem;
  background: transparent;
}

.formLabel {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: #202125a3;
  position: absolute;
  top: 0.875rem;
  width: auto;
  right: 1rem;
  left: 1rem;
  pointer-events: none;
  transition: transform 0.15s ease-in-out 0s;
  transform-origin: left center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: start;
}

.formBtn {
  border: none;
  position: relative;
  z-index: 0;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: auto;
  max-width: 100%;
  cursor: pointer;
  padding: 0 1rem;
  min-height: 2.875rem;
  border-radius: 0.5rem;
  background: transparent;
  color: #fff;
  margin-inline: 1rem 0;
  flex: 1 1 0;
}

.formBtnBlock1 {
  position: absolute;
  inset: 0px;
  border-radius: 0.5rem;
  transition: background-color 120ms ease-out 0s;
  background: #1b5e3b;
}

.formBtnBlock2 {
  position: absolute;
  inset: 0px;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.formBtnBlock3 {
  flex: 1 1 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 150ms ease-in 0s, color 150ms linear 0s;
  border-radius: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
}

@media screen and (min-width: 1024px) {
  .titleBlock {
    font-feature-settings: "kern", "ss01", "ss05", "ss07";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
    font-variant-ligatures: common-ligatures;
    font-size: 1.75rem;
    line-height: 2.25rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
  }
  .titleItemsBlock {
    text-rendering: optimizelegibility;
    font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
    font-variant-ligatures: common-ligatures;
    font-size: 1.75rem;
    line-height: 2.25rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
  }

  .promoBlockTitle {
    font-feature-settings: "kern", "ss01", "ss05", "ss07";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
    font-variant-ligatures: common-ligatures;
    font-size: 1.75rem;
    line-height: 2.25rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
  }
}

@media screen and (max-width: 1024px) {
  .titleMiddle {
    font-feature-settings: "kern";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
    font-weight: 500;
    font-size: 1.5rem;
    line-height: 1.25rem;
    letter-spacing: 0.03125rem;
    text-transform: none;
    color: #202125;
    margin-bottom: 2rem;
  }
}