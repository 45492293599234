.videoPlayerBlock {
  height: 730px;
  margin-top: 100px;
  padding: 0 0 90px;
  position: relative;
  text-align: center;
  width: 100%;
  background-color: var(--cb-color-bg);
  background-repeat: no-repeat;
  background-image: linear-gradient(to bottom, var(--cb-color-bg), var(--cb-color-bg)), linear-gradient(to bottom, #f8f8f8, #f8f8f8);
  background-position: 0px 0px, 0px 50%;
  background-size: 100% 50%, 100%, 50%;
  box-sizing: border-box;
  padding-bottom: 90px;
}

.videoPlayerConteiner {
  height: 100%;
  margin: 0 auto;
  box-sizing: border-box;
  max-width: 1184px;
  background-color: #fff;
  box-shadow: 0px 8px 28px 0px #0000001f,0px 2px 6px 0px #0000001f,0px 0px 1px 0px #0000001f
}
.contentConteiner {
  clip-path: polygon(0 0, 100% 0, 100% 100%, 0 100%);
  display: flex;
  height: 100%;
  overflow: hidden;
  position: relative;
  text-align: left;
  text-align: start;
  width: 100%;
}

.backgroundImgBlock {
  background-image: url("https://consumer-static-assets.wolt.com/frontpage-assets/video-cover-image-4.jpg");
  background-size: 0;
  height: 100%;
  left: 0;
  position: absolute;
  top: 0;
  width: 100%;
  margin: 0;
  padding: 0;
  display: block;
  unicode-bidi: isolate;
}
.shadowBlock{
  display: none;
  height: 100%;
  width: 100%;
}

.leftContent2 {
  max-width: 50%;
  background-position: 50%;
  background-size: cover;
  display: flex;
  height: 100%;
  transition: max-width .3s ease-in;
  width: 50%;
}

.rightContent2 {
  height: 100%;
  left: 0;
  margin: 0 auto;
  pointer-events: none;
  position: absolute;
  top: 0;
  width: 100%;
  display: block;
  unicode-bidi: isolate;
  z-index: 15;
}

.fakeBlock {
  background-image: url("https://consumer-static-assets.wolt.com/frontpage-assets/video-cover-image-4.jpg");
  background-size: cover;
  background-position: 30% center;
  width: 50%;
  height: 100%;
  position: absolute;
  right: 0;
  transform: translateX(0%);
  transition: transform 690ms cubic-bezier(0.17, 0.67, 0.24, 0.99) 0s;
  z-index: 1;
}

.video {
  height: 100%;
  left: 50%;
  -o-object-fit: cover;
  object-fit: cover;
  pointer-events: none;
  position: relative;
  top: 0;
  transform: translateZ(0);
  transition: transform .69s cubic-bezier(.17,.67,.24,.99);
  width: 61%;
  overflow-clip-margin: content-box;
  overflow: clip;
}

.textContentBlock {
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: center;
  padding: 72px 96px 36px 118px;
  position: relative;
}

.helpText {
  display: block;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-variant-ligatures: common-ligatures;
  font-size: 2.875rem;
  line-height: 3.5rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin-top: 0px;
  text-align: start;
  margin-bottom: 2.5rem;
}

.textMain {
  line-height: 1.71;
  margin-bottom: 40px;
  font-size: 1rem;
  text-rendering: optimizeLegibility;
}

.h2Title {
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-variant-ligatures: common-ligatures;
  font-size: 1.25rem;
  line-height: 1.5rem;
  font-weight: 600;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin-bottom: 4.5rem;
  text-align: start;
}

.playerBtn {
  border: 0;
  background-color: white;
  color: #1b5e3b;
  cursor: pointer;
  display: inline-flex;
  font-size: 1.2rem;
  margin: 0 auto 0 0;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Roboto, Open Sans, sans-serif;
}

.videoImgBlock {
  align-items: center;
  background-color: #1b5e3b;
  border-radius: 50%;
  box-shadow: 0 0 8px 0 rgba(32,33,37,.12);
  display: flex;
  height: 3.75rem;
  justify-content: center;
  min-width: 3.75rem;
  transition: transform .15s ease-out;
  width: 3.75rem;
}

.playerBtnText {
  display: inline-flex;
  margin: auto 0 auto .75rem;
  margin-inline-end: 0;
  margin-inline-start: .75rem;
}

.playImg {
  color: #fff;
  height: 4rem;
  width: 4rem;
}

.videoPlayerConteiner {
  position: relative;
  width: 100%;
  height: 100%;
}

.videoPlayerBlock.fullscreen .videoPlayerConteiner {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 10;
}
.videoFull {
  left: 0 !important;
  width: 100% !important;
}

@media screen and (max-width: 1199px) {
  .videoPlayerBlock {
    height: 620px;
  }
  .textContentBlock{
    padding: 36px 48px 36px 59px;
  }
}

@media screen and (max-width: 1023px) {
  .videoPlayerConteiner {
    height: 550px;
  }
  .videoPlayerBlock {
    height: 550px;
  }
  .backgroundImgBlock {
    background-position: 50% 0;
    background-repeat: no-repeat;
    background-size: cover;
    width: 100%;
  }
  .fakeBlock {
    display: none;
  }
  .video {
    left: 0;
    opacity: 0;
    transition: transform .69s cubic-bezier(.17,.67,.24,.99), opacity .3s cubic-bezier(.17,.67,.24,.99);
  }
  .videoFull {
    opacity: 1 !important;
  }
  .textContentBlock{
    padding: 56px;
  }
  .h2Title {
    color: #fff;
    flex: 1 1 0;
  }
  .helpText {
    color: #fff;

  }
  .textMain {
    display: none;
  }
  .playerBtn {
    color: #fff;
    background: none;
  }
  .videoImgBlock {
    background-color: #fff;
  }
  .playerBtnText {
    display: none;
  }
  .playImg {
    color: black;
  }
  .shadowBlock{
    display: block;
    background: #000;
    opacity: 0.35;
  }
  .video {
    transform: translateZ(0);
  }
}

@media screen and (max-width: 639px) {
  .videoPlayerConteiner {
    height: 320px;
  }
  .videoPlayerBlock {
    height: 320px;
  }
  .leftContent2 {
    background: transparent;
  }
  .textContentBlock{
    padding: 32px 24px;
  }
  .h2Title {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    font-weight: 700;
  }
  .helpText {
    text-rendering: optimizelegibility;
    font-variant-ligatures: common-ligatures;
    font-size: 1.6rem;
    line-height: 2rem;
    font-weight: 600;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    margin-bottom: 20px;
  }
  .videoImgBlock {
    height: 2.5rem;
    min-width: 2.5rem;
    width: 2.5rem;
  }
}

