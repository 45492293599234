.appBannerRoot {
  background-color: #f2f3f5;
  display: flex;
  height: 758px;
  justify-content: center;
  margin-top: 100px;
  overflow: hidden;
  width: 100%;
  box-sizing: border-box;
}

.appBannerContent {
  height: 100%;
  top: 0;
  min-width: 60%;
}

.appBannerImg {
  background-size: cover;
  background-image: url("https://consumer-static-assets.wolt.com/frontpage-assets/front-cells.png");
  width: 100%;
  height: 100%;
}

.textBlockBanner{
  align-items: center;
  display: flex;
  height: 100%;
  margin: auto;
  padding: 30px;
  position: relative;
  box-sizing: border-box;
  justify-content: flex-end;
}

.contentBaner{
  box-sizing: border-box;
  z-index: 1;
  margin: 0;
  padding: 0;
  max-width: 460px;
}

.titleBaner {
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 2.875rem;
  line-height: 3.5rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin-bottom: 2.5rem;
  color: rgb(32, 33, 37);
}
.h2Text{

}

.descriptionBaner {
  box-sizing: border-box;
  word-wrap: break-word;
  color: #707174;
  line-height: 1.71;
  margin-bottom: 2.5rem;
  font-size: 1rem;
}

.mockImg1 {
  display: block;
  margin-bottom: 1rem;
  max-width: 10rem;
  width: 100%;
}

.mockImg2 {
  display: block;
  margin-bottom: 1rem;
  max-width: 10rem;
  width: 100%;
}

@media screen and (max-width: 800px){
  .contentBaner {
    max-width: 60%;
  }
  .titleBaner{

  }
}

@media screen and (max-width: 639px){
  .titleBaner{
    font-size: 2.5rem;
    line-height: 3rem;
  }
  .appBannerRoot {
    height: auto;
    padding: 0;
    padding-top: 4rem;
    flex-direction: column;
  }
  .textBlockBanner{
    max-height: 360px;
    width: 100%;
    padding: 16px 16px 1.5rem 16px;
  }
  .contentBaner {
    max-width: 100%;
  }
  .appBannerImg{
    max-width: 100%;
    height: 275px;
  }
  .descriptionBaner {
    line-height: 1.6;
    margin-bottom: 2.5rem;
    font-size: 0.8rem;
  }
  .mockImg1 {
    width: 8rem;
  }
  .mockImg2 {
    width: 8rem;
  }
}


