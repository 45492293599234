.tabsContainer {
  border-radius: 12.5rem;
  width: auto;
  background-color: var(--dark-grey-color);
  display: flex;
  justify-content: center;
  padding: 0.25rem;
  height: 3rem;
  margin: 0.1875rem 1rem 1rem;
  position: relative;
  z-index: 22;
  @media screen and (max-width: 640px) {
    height: 2.4rem;
    padding: 0.15rem;
  }
}

.tabs {
  display: flex;
  @media (max-width: 640px) {
    width: 100%;
    justify-content: space-between;
  }
}

.tabButton {
  width: 254px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;
  font-weight: 700;
  color: darkgrey;
  @media (max-width: 640px) {
    width: 50%;
    padding: 5px;
    text-decoration: none;
  }
}

.tabButton:hover {
  text-decoration: underline;
  @media (max-width: 640px) {
    text-decoration: none;
  }
}

.active {
  background-color: var(--background-color);
  font-weight: bold;
  border-radius: 12.5rem;
  color: var(--text-color);
}

.tabContent {
  margin-top: 10px;
}

.tabPanel {
  display: none;
}

.tabPanel.active {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 85vh;
  padding-top: 80px;
  @media (max-width: 850px) {
    padding-top: 5px;
  }
}

.image-wrapper {
  width: 100%;
  display: flex;
  justify-content: center;
}

.image-empty {
  width: 274px;
  height: 264px;
  background-image: url("./emptyBag.png");
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 850px) {
    background-size: contain;
    width: 170px;
    height: 170px;
  }
}

.image-order {
  width: 274px;
  height: 264px;
  background-image: url("./order.png");
  background-repeat: no-repeat;
  background-size: cover;
  @media (max-width: 850px) {
    background-size: contain;
    width: 180px;
    height: 180px;
  }
}

.title {
  max-width: 380px;
  font-size: 32px;
  line-height: 3.5rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin: 0;
  overflow-wrap: break-word;
  min-width: 0;
  text-align: center;
}

.subtitle {
  max-width: 380px;
  text-align: center;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: gray;
}

.button {
  margin-top: 20px;
  height: 46px;
  width: fit-content;
  background: 0;
  background-color: rgba(0, 157, 224, 0.4);
  color: white;
  border: 0;
  padding: 0 10px;
  border-radius: 0.5rem;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Segoe UI, Arimo, Open Sans, sans-serif;
  font-size: 1rem;
  font-stretch: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  text-rendering: optimizelegibility;
  cursor: pointer;
  &:hover {
    background-color: rgba(0, 157, 224, 0.8);
  }
  @media screen and (max-width: 640px) {
    height: 38px;
    font-size: 0.9rem;
    background-color: rgba(105, 228, 255, 0.2);
    color: #1b5e3b;
  }
}

.bodyCarts {
  padding: 1rem;
}

.footer {
  position: sticky;
  bottom: env(safe-area-inset-bottom, 0);
  left: 0;
  right: 0;
  background: transparent;
  z-index: 60;
}

.footer:before {
  content: "";
  height: env(safe-area-inset-bottom, 0);
  position: absolute;
  bottom: calc(-1* env(safe-area-inset-bottom, 0));
  left: 0;
  right: 0;
  background-color: #fff;
}

.footerConteiner {
  position: relative;
}

.footerBlock {
  padding: 1rem;
}

.footerBlockC {
  width: 100%;
  position: relative;
}

.footerBtn {
  border: none;
  position: relative;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: 100%;
  max-width: 100%;
  cursor: pointer;
  padding: 0 1rem;
  min-height: 3.375rem;
  border-radius: 0.5rem;
  background: transparent;
  color: #fff;
  z-index: 9999999;
}

.footerBtnCont {
  transition: background-color 120ms ease-out 0s;
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
}

.footerBtnBlock {
  position: absolute;
  inset: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--blue-btn-background-color);
  border-radius: 1rem;
}

.footerBtnMain {
  flex: 1 1 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 150ms ease-in 0s, color 150ms linear 0s;
  border-radius: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  color: var(--blue-btn-text-color);
  border-radius: 1rem;

}

.footerUpBlock {
  display: inline-flex;
  align-items: center;
  flex-shrink: 0;
  margin-inline: 0 1rem;
}

.footerUpSpan {
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 0.75rem;
  font-size: 0.9rem;
  line-height: 1.3rem;
  margin-inline-start: 1rem;
  background: var(--blue-btn-background-color);
  color: white;
  margin-bottom: 1px;
}

.footerDownSpan {
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  margin-inline-start: 1rem;
}

.footerDownBlock {
  flex: 1 1 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-radius: 1rem;
}

@media screen and (max-width: 640px) {
  .footerBlock {
    padding: 1rem 0;
  }
  .footerDownSpan {
    font-size: 0.9rem;
    line-height: 1.3rem;
  }
  .footerDownBlock {
    font-size: 1rem;
    line-height: 1.3rem;
  }
  .tabPanel {
    padding: 1rem;
    box-sizing: border-box;
    border-radius: 0;
  }
  .image-order {
    width: 100%;
    height: 330px;
  }
  .image-wrapper {
    display: flex;
    justify-content: center;
  }
  .image-empty {
    width: 80%;
    height: 280px;
  }
  .mainBoxBlock {
    border-bottom: 0.5px solid rgba(169, 169, 169, 0.5);
  }
  .title {
    font-size: 1.8rem;
  }
  .subtitle {
    font-size: 1rem;
  }
  .button {

  }
}
.logOut {
  display: none;
}
.conteiner {
  padding: 0 1rem;
}
.recomendationBlock {

}
.upBtnBlock {
  display: flex;
  justify-content: space-between;
  padding: 1rem;
}
.titleOrder {
  font-size: 1.4rem;
  font-weight: bold;
}
.addMoreBtn {
  color: #1b5e3b;
  font-weight: bold;
  font-size: 0.8rem;
}
.addMessBlock {
  display: flex;
  align-items: center;
  padding: 1rem;
  justify-content: space-between;
}
.blockContentAddMess {
  display: flex;
  flex-direction: column;
  max-width: 80%;
}
.titleAddMessUp {
  font-weight: bold;
}
.rightBlock {
  font-weight: bold;
  font-size: 1.6rem;
}
.titleAddMessDown {
  color: #8e8e8e;
  font-size: 0.95rem;
}
.arrowRight {
  rotate: 360deg;
}
