/* ProfileCard.module.css */

.profileCard {
  max-width: 900px;
  position: relative;
  height: 100%;
  @media (min-width: 640px) {
    margin: 0 auto;
  }
}

.mainConteiner {
  padding: 0 1rem;

  cursor: pointer;

}

.mainTitle {
  font-size: 1.5rem;
  font-weight: 750;
  padding: 0 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 0.4rem;
  height: 4rem;
}

.contentBlock {
  height: 4rem;
  border: 1px solid rgba(169, 169, 169, 0.3);
  display: flex;
  align-items: center;
  padding: 0 1rem;
  margin-top: 0.5rem;
}

.infoCont {
  display: flex;
  flex-direction: column;
  height: 2rem;
  justify-content: space-between;
  width: 100%;
  padding-inline-start: 1rem;
}
.topText {
  font-size: 0.9rem;
  font-weight: bold;
  color: #1b5e3b;
}
.downText {
  font-size: 0.7rem;
  color: #707174;
}
.bordsSvg {
  height: 22px;
  width: 22px;
  background-color: rgba(142, 142, 142, 0.3);
  border-radius: 50%;
  display: flex;
  justify-content: center;
  cursor: pointer;
}
.contInSvg {
  font-weight: 900;
  line-height: 14px;
}
.addNewAddress {
  position: absolute;
  width: 92%;
  background-color: #1b5e3b;
  border-radius: 0.5rem;
  height: 3rem;
  color: white;
  font-weight: bold;
  font-size: 1.1rem;
  bottom: 5.5rem;
  @media screen and (max-height: 700px) {
    height: 2.7rem
  }
  @media screen and (min-width: 641px) {
    width: fit-content;
    left: 40%;
    padding: 0 5rem;
  }

}

.saveNewAddress {
  width: 100%;
  background-color: #1b5e3b;
  border-radius: 0.5rem;
  height: 3rem;
  color: white;
  font-weight: bold;
  font-size: 1.1rem;
  bottom: 5.5rem;
  @media screen and (max-height: 700px) {
    height: 2.7rem
  }
  margin-bottom: 100px;
}
.newAddresConteiner {
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  width: 100%;
}
.disable {
  color: rgba(255, 255, 255, 0.6);
}

.imagesBlock {
  display: flex;
  height: 3rem;
  margin: 1rem 0;
  justify-content: space-between;
  padding-bottom: 10px;
  box-sizing: border-box;
  border-bottom: 0.5px solid var(--address-border);
}
.rowBlock {
  display: flex;
  height: 3rem;
  align-items: center;
}
.svgImage {
  height: 30px;
  width: 30px;
  color: var(--text-color);
  fill: var(--text-color);
  &__active {
    height: 30px;
    width: 30px;
    color: #1b5e3b;
    fill: #1b5e3b;
  }
}
.leftBlock {
  display: flex;
  align-items: center;
}
.textBlockImage {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}
.topText {
  font-size: 0.9rem;
  font-weight: bold;
  color: var(--text-color);
  fill: black;
  &__active {
    font-size: 0.9rem;
    font-weight: bold;
    color: #1b5e3b;
    fill: #1b5e3b;
  }
}
.downText {
  font-size: 0.7rem;
  color: #707174;
}

.IconNextBlock {
  height: 100%;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 0.5rem;
}

.IconNext {
  margin-top: 4px;
  fill: forestgreen;
}
.conteinerAddress {
  height: 100%;
}
.round {
  height: 16px;
  width: 16px;
  border-radius: 50%;
  border: 1px solid rgba(169, 169, 169, 0.3);
}
.downConteiner {
  height: 93vh;
  overflow: scroll;
  overflow-x: hidden;
  overflow-y: auto;
}
::-webkit-scrollbar {
  width: 0;
}
.optionModal {
  width: 14rem;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
  display: flex;
  flex-direction: column;
  background-color: var(--background-color);
  margin-bottom: 6rem;
  border-radius: 0.5rem;
}
.activeBtn {
  width: 100%;
  display: flex;
  font-size: 1rem;
  font-weight: bold;
  padding: 1rem 1rem;
  color: var(--text-color);
  border-bottom: 1px solid rgba(169, 169, 169, 0.3);
  &:last-child {
    border-bottom: none;
  }
}
.rowContCloseBtn {
  width: 100%;
  display: flex;
  justify-content: flex-end;
  padding: 0.4rem 0.4rem;
  border-bottom: 1px solid rgba(169, 169, 169, 0.3);

}
.closeBtn {
  background-color: var(--dark-grey-color);
  border-radius: 50%;
  height: 28px;
  width: 28px;
  font-weight: bold;
  cursor: pointer;
  color: var(--text-color);
}
.panel {
  color: var(--grey-text-color);
  font-size: 0.85rem;
  display: flex;
  justify-content: center;
  margin-right: 2rem;
  margin-top: 0.1rem;
  display: flex;
  align-items: center;
}
.shadowBack {
  position: absolute;
  z-index: 9999;
  top: 0;
  left: -1rem;
  height: 100vh;
  width: 100vw;
  background-color: rgba(142, 142, 142, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.mainStyle {
  background-color: rgba(255, 255, 255, 0.68);
  border-radius: 60px;
  height: 40px;
  width: 40px;
  z-index: 999999;
}

.mainSvg {
  fill: var(--text-color);
  margin-top: 3px;
}

.mainStyle2 {
  background-color: var(--grey-border-color);
  border-radius: 50%;
  padding: 0.4rem;
  z-index: 999999;
}

.descriptionText {
  padding-bottom: 0.5rem;
}

.deleteIcon {
  background-color: var(--grey-border-color);
  border-radius: 50%;
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 2%;
  left: 87%;
  @media (min-width: 640px) {
    position: relative;
  }
}