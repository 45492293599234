.mainBlock {
  display: flex;
  justify-content: center;
  align-items: flex-end;
  height: 40rem;
  background-color: rgb(203, 225, 173);
  overflow-x: clip;
  position: relative;
}

.container {
  position: relative;
  max-width: 75rem;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: flex-end;
}

.imageContainer {
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
  overflow-y: clip;
}

.fakeBlock {
  display: none;
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
  background-color: rgba(33, 32, 37, 0.3);
}

.image {
  top: 0;
  inset-inline-end: -25%;
  position: absolute;
  z-index: 0;
  min-height: 100%;
  width: auto;
  min-width: 62.5rem;
  object-fit: cover;
  transition: opacity 150ms ease-in-out 0s;
  display: inline-block;
  height: auto;
  max-width: 100%;
  vertical-align: middle;
  opacity: 1;
}

.shadowsBlock {
  width: 100%;
  height: 100%;
  background-color: black;
  z-index: 10;
  position: absolute;
  opacity: 0;
  display: none;
}

.contentBlock {
  position: relative;
  z-index: 11;
  width: 33.375rem;
  margin-top: 3.4375rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  height: 100%;
  padding: 0 30px;
}

.titleBlock {
  height: 16.75rem;
  position: relative;
}

.title {
  font-feature-settings: "kern", "ss01", "ss05", "ss07";
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 3.75rem;
  line-height: 4.75rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin: 0px 0px 1.5rem;
}


@media screen and (max-width: 1199px) {
  .image {
    inset-inline-end: -18.75rem;
  }
}

@media screen and (max-width: 1023px) {
  .image {
    inset-inline-end: -25rem;
  }
  .contentBlock {
    min-width: 20rem;
  }
}

@media screen and (max-width: 639px) {
  .contentBlock {
    padding: 0 16px;
    justify-content: flex-end;
  }
  .shadowsBlock {
    display: block;
    opacity: 0.25;
  }
}