.mainConteiner {
  margin-inline-start: 1rem;
  scroll-snap-align: start;
}

.mainConteiner:first-child {
  margin-left: 0;
}

.card {
  user-select: none;
  -webkit-user-drag: none;
  display: block;
  color: #1b5e3b;
  cursor: pointer;
  text-decoration: none;
}

.imgConteiner {
  z-index: 0;
  position: relative;
  border-radius: 0.5rem 0.5rem 0 0;
  overflow: hidden;
}

.imgBlock {
  position: relative;
  width: 100%;
  aspect-ratio: 1 / 1;
}

.imgBlock:hover {
  transform: scale(1.03) translateZ(0);
}

.image {
  width: 100%;
  height: 100%;
  position: absolute;
  inset: 0;
}

.img {
  display: inline-block;
  max-width: 100%;
  vertical-align: middle;
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.descriptionBlock {
  display: flex;
  padding: 1rem;
  align-items: center;
  white-space: nowrap;
  position: relative;
  background-color: var(--other-background-color);
  @media (min-width: 649px) {
    padding: 0.5rem;
  }
}

.description {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  overflow: hidden;
}

.title {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 1.125rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  text-align: start;
  margin: 0;
  text-overflow: ellipsis;
  overflow: hidden;
  color: var(--text-color);
}

.count {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: var(--text-color);
  text-align: start;
  margin: 0.7rem 0 0;
  text-overflow: ellipsis;
  overflow: hidden;
  min-height: 1.25rem;
  @media (min-width: 649px) {
    margin: 0;
  }
}

.mainCardBlock {
  overflow: hidden;
  border-radius: 0.5rem;
  background-color: var(--background-color);
  box-shadow: rgba(0, 0, 0, 0.06) 0 0 0.125rem 0px, rgba(0, 0, 0, 0.12) 0 0.125rem 0.125rem 0;
  transition: box-shadow 0.3s cubic-bezier(0.45, 0, 0.55, 1) 0s, background 100ms ease-out 0s;
}

.mainCardBlock:hover {
  transform: scale(1.03) translateZ(0);
}

@media screen and (min-width: 0px) {
  .mainConteiner {
    width: calc((100% / 3.4) - 1rem + 1rem / 3.4);
    min-width: calc((100% / 3.4) - 1rem + 1rem / 3.4);
  }
}

@media screen and (min-width: 640px) {
  .mainConteiner {
    width: calc((100% / 4) - 1rem + 1rem / 4);
    min-width: calc((100% / 4) - 1rem + 1rem / 4);
  }

  .title {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
    font-size: 0.875rem;
    line-height: 1.25rem;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    font-weight: 500;
  }

  .count {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
    font-size: 0.75rem;
    line-height: 1rem;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    font-weight: 400;
  }
}

@media screen and (min-width: 641px) {
  .title{
    font-size: 1.15rem;
    line-height: 1.4rem;
    font-weight: 500;
  }
  .count {
    font-size: 0.7rem;
    line-height: 1.5rem;
    font-weight: 500;
  }
}

@media screen and (min-width: 800px) {
  .title{
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 500;
  }
  .count {
    font-size: 0.7rem;
    line-height: 1.5rem;
    font-weight: 500;
  }
}

@media screen and (min-width: 1000px) {
  .title{
    font-size: 1.25rem;
    line-height: 1.5rem;
    font-weight: 500;
  }
  .count {
    font-size: 0.7rem;
    line-height: 1.5rem;
    font-weight: 500;
  }
  .mainConteiner {
    width: calc((100% / 5) - 1rem + 1rem / 5);
    min-width: calc((100% / 5) - 1rem + 1rem / 5);
  }
}

@media screen and (min-width: 1200px) {
  .title{
    font-size: 1.3rem;
    line-height: 1.6rem;
    font-weight: 500;
  }
  .count {
    font-size: 0.8rem;
    line-height: 1.6rem;
    font-weight: 500;
    color: #1b5e3b;;
  }
  .mainConteiner {
    width: calc((100% / 6) - 1rem + 1rem / 6);
    min-width: calc((100% / 6) - 1rem + 1rem / 6);
  }
}

@media screen and (max-width: 640px) {
  .descriptionBlock {
    padding: 0.5rem;
  }
  .mainConteiner{
    max-height: 180px;
    box-sizing: border-box;
    width: 30vw !important;
  }
  .mainCardBlock {
    width: 30vw !important;
  }
  .descriptionBlock{
    height: 60px;
  }
  .descriptionBlock{
    height: 60px !important;
    box-sizing: border-box;
  }
  .title{
    font-feature-settings: "kern";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
    font-size: 0.85rem;
    line-height: 1rem;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    font-weight: 500;
  }
  .count {
    font-feature-settings: "kern";
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
    font-size: 0.75rem;
    line-height: 0.9rem;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    font-weight: 400;
    color: rgba(169, 169, 169, 0.75);
  }
}
.skeleton {
  height: 300px;
  position: relative;
  top: -70px;
}