.titleTextRoot {
  margin: 0 auto;
  max-width: 1120px;
  padding-top: 130px;
  text-align: center;
}

.titleTextContent {
  margin: 0;
  padding: 0;
}

.title{
  font-feature-settings: "kern", "ss01", "ss05", "ss07";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: WoltHeading-Omnes, -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-variant-ligatures: common-ligatures;
  font-size: 2.875rem;
  line-height: 3.5rem;
  font-weight: 700;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  padding-bottom: 2.5rem;
}

.description {
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  font-size: 1.75rem;
  line-height: 1.43;
  display: block;
  max-width: 48rem;
  margin: 0px auto;
  text-align: center;
  color: #202125;
}

@media screen and (max-width: 639px) {
  .titleTextRoot {
    padding-top: 60px;
  }
  .titleTextContent {
    padding: 0px 1.5rem;
  }
  .title {
    font-variant-ligatures: common-ligatures;
    font-size: 1.8rem;
    line-height: 3.5rem;
    font-weight: 700;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    padding-bottom: 1.5rem;
  }

  .description {
    font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
    font-size: 1rem;
    line-height: 1.5rem;
    font-style: normal;
    font-stretch: normal;
    text-transform: none;
    font-weight: 400;
  }

}