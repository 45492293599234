.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: black;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.cameraView {
  width: 100vw;
  height: 100vh;
  object-fit: cover;
}

.overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background: rgba(0, 0, 0, 0.4);
}

.passportFrame {
  width: 95%;
  height: 40%;
  border: 4px solid green;
  border-radius: 10px;
  position: relative;
}

.text {
  position: absolute;
  top: 62%;
  font-size: 14px;
  color: white;
  background: rgba(0, 0, 0, 0.6);
  padding: 10px 20px;
  border-radius: 5px;
  text-align: center;
}

.closeBtn {
  position: absolute;
  bottom: 5%;
  padding: 12px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  background: red;
  color: white;
}
