.mainStyle {
  background-color:var(--dark-grey-color);
  border-radius: 60px;
  height: 34px;
  width: 34px;
  position: absolute;
  background: rgba(255, 255, 255, 0.5);
  z-index: 99999;
  right: 0.75rem;
  top: 1.5rem;
}
.mainSvg {
  margin-top: 4px;
  fill: var(--text-color) !important;
  rotate: (90deg);
  height: auto;
  width: auto;
}
