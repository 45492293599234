.mainConteiner {
  padding-bottom: 1.25rem;
  margin-left: -3px;
  @media (min-width: 640px) {
    padding-bottom: 2.8rem;
  }
}

.mainBlock {
  display: flex;
  justify-content: space-between;
  align-items: end;
  gap: 1rem;
}

.title {
  font-feature-settings: "kern", "ss01", "ss05", "ss07";
  font-family: "Nunito",  sans-serif, -apple-system, BlinkMacSystemFont;

  font-variant-ligatures: common-ligatures;
  font-size: 1.8rem !important;
  line-height: 2.5rem !important;
  font-weight: 900 !important;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  margin: 0;
  overflow-wrap: break-word;
  min-width: 0;
  display: flex;
  align-items: center;
  @media (min-width: 640px) {
    font-size: 3rem !important;
  }
}

.rightContent {
  display: flex;
  align-items: center;
  gap: 0.5rem;
  box-sizing: border-box;
  height: 40px;
}

.btnBlock {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  position: relative;
  display: inline-flex;
  align-items: center;
  gap: 0.5rem;
  border-radius: 0.5rem;
  color: rgb(0, 157, 224);
  outline: none;
  background: none;
  border: none;
  height: 40px;
}

.textBlock {
  display: flex;
  gap: 0.25rem;
  cursor: pointer;
  white-space: nowrap;
}

.btnContent {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  padding: 0.5rem;
  cursor: pointer;
  transition: background 100ms linear 0s, color 100ms linear 0s;
  background: rgba(0, 157, 224, 0.08);
  fill: rgb(0, 157, 224);
  height: 40px;
  width: 40px;
  box-sizing: border-box;
}

.text {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-style: normal;
  font-stretch: normal;
  font-weight: 700;
  text-transform: capitalize;
}

.imgFilter {
  width: 2.5rem;
  height: 2.5rem;
}
@media screen and (max-width: 640px) {
  .mainConteiner {
    display: flex;
    align-items: center;
    padding-bottom: 0;
  }
  .textBlock {
    display: none;
  }
  .title {
    font-size: 2.2rem;
    line-height: 2.5rem;
  }
  .btnBlock {
    display: none;
  }
  .btnContent {
    background: rgba(90, 90, 90, 0.13);
    fill: black;
  }
  .title {
    font-size: 2rem;
    font-weight: bold;
  }
}