.mainConteiner {
  position: relative;
  height: 100%;
  border-radius: 0.5rem;
  backface-visibility: hidden;
  box-sizing: border-box;
  min-width: 160px;
  @media (min-width: 640px) {
    min-height: 140px;
  }
}


.mainBlock:hover {
  transform: scale(1.03) translateZ(0);
}

.mainBlock {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  backface-visibility: hidden;
  background-color: var(--background-color);
}

.mainBlock::before {
  content: "";
  pointer-events: none;
  position: absolute;
  inset: 0;
  border-radius: inherit;
  transition: opacity 150ms cubic-bezier(0.45, 0, 0.55, 1) 0s;
  opacity: 1;
  box-shadow: rgba(0, 0, 0, 0.06) 0px 0px 0.125rem 0px, rgba(0, 0, 0, 0.12) 0px 0.125rem 0.125rem 0px;
  background-color: transparent;
}

.mainBlock::after {
  content: "";
  pointer-events: none;
  position: absolute;
  inset: 0;
  border-radius: inherit;
  transition: opacity 150ms cubic-bezier(0.45, 0, 0.55, 1) 0s;
  opacity: 0;
  box-shadow: rgba(0, 0, 0, 0.04) 0px 0px 0.375rem, rgba(0, 0, 0, 0.08) 0px 0.125rem 0.375rem;
}

@media (min-width: 640px){
  .mainBlock {
    display: flex;
    flex-direction: row-reverse;
  }
}

.mainButton {
  position: absolute;
  top: 0;
  right: 0;
  padding: 0;
  pointer-events: all;
  z-index: 50;
  @media (min-width: 640px) {
    right: 3px;
  }
}

.btnBlock {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  position: relative;
  border-start-end-radius: 0.5rem;
  border-end-start-radius: 1.5rem;
  display: inline-flex;
  background-color: #1b5e3b14;
  color: #1b5e3b;
  transition: background-color 150ms linear 0s, color 150ms linear 0s;
}

.storePageBtnBlock {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  position: relative;
  border-start-end-radius: 0.5rem;
  border-end-start-radius: 1.5rem;
  display: inline-flex;
  // background-color: #1b5e3b14;
  color: rgba(2, 172, 248, 0.1);
  transition: background-color 150ms linear 0s, color 150ms linear 0s;
}

.storePageBtnBlockDark {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  position: relative;
  border-start-end-radius: 0.5rem;
  border-end-start-radius: 1.5rem;
  display: inline-flex;
  // background-color: #1b5e3b14;
  color: rgba(2, 172, 248, 0.1);
  transition: background-color 150ms linear 0s, color 150ms linear 0s;
}

.btn {
  -webkit-tap-highlight-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  overflow: hidden;
  border-radius: 0.25rem;
  height: 2.75rem;
  z-index: 100;
}

.img {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 1.8rem;
  height: 1.8rem;
  padding: 0.5rem;
  background-color: transparent;
  fill: var(--blue-btn-text-color);
  transition: background-color 50ms linear 0s, transform 50ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  transform: scale(1);
}

.storePageImg {
  display: inline-flex;
  flex-shrink: 0;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: 2.5rem;
  height: 2.5rem;
  padding: 0.5rem;
  background-color: transparent;
  fill: #1b5e3b;
  transition: background-color 50ms linear 0s, transform 50ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
  transform: scale(1);
  @media (min-width: 640px) {
    width: 1.7rem;
    height: 1.7rem;
    background-color: #ebf7fd;
    border-radius: 0 0.7rem 0 0.7rem;
    position: absolute;
    top: 10px;
    right: 6px;

  }
}

.link {
  all: unset;
  position: absolute;
  cursor: pointer;
  inset: 0;
  border-radius: 0.5rem;
}

.mainContentBlock {
  overflow: hidden;
  width: 100%;
  border-top-left-radius: 0.5rem;
  border-top-right-radius: 0.5rem;
}
@media screen and (min-width: 640px) {
  .mainContentBlock {
    overflow: hidden;
    margin: 10px;
    width: 180px;
    height: 119px;
    border-radius: 0.7rem;

  }
}

.mainContentBlockImg {
  object-fit: cover;
  width: 100%;
  height: auto;
  aspect-ratio: 3 / 2;
  pointer-events: none;
  transform: scale(1);
  transition: transform 150ms cubic-bezier(0.45, 0, 0.55, 1) 0s;
  @media (min-width: 640px) {
    width: 190px;
  }
}

.mainImg {
  position: absolute;
  inset: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.tagConteiner {
  position: absolute;
  top: 0;
  margin: 0;
  padding: 0.75rem;
  list-style: none;
  display: flex;
  flex-flow: column wrap;
  gap: 0.5rem;
}

.downContBlock {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  padding: 0.75rem;
  opacity: 1;
  background-color: var(--other-background-color);
  border-radius:  0 0 0.5rem 0.5rem;
}
@media screen and (min-width: 640px) {
  .downContBlock {
    flex-direction: column-reverse;
    width: 305px;
  }
}

.downContMain {
  display: block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  color: #20212566;
}

.downDownContMain {
  height: 3rem;
  margin-block-end: auto;
}

.titleDownBlock {
  height: 3rem;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  color: var(--text-color);
  overflow: hidden;
  word-break: break-word;
  @media (min-width: 640px) {
    height: 22px;
    -webkit-line-clamp: 1;
    font-size: .875rem;
    font-weight: 600;
  }
}
.descriptionDownBlock {
  @media (min-width: 640px) {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    font-feature-settings: "kern";
    -webkit-font-smoothing: antialiased;
    font-weight: 400;
    font-size: 0.875rem;
    overflow: hidden;
    word-break: break-word;
    height: 40px;
    text-overflow: ellipsis;
    white-space: wrap;
    text-rendering: optimizelegibility;
    color: var(--order-grey-text-color);
  }
}

.cost {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: #1b5e3b;
  width: 100%;
}

.oldCost {
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.75rem;
  line-height: 1rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 400;
  color: var(--grey-text-color);
}

@media screen and (max-width: 640px) {
  .btnBlock {
    background-color: var(--blue-btn-background-color);
    width: 35px;
    height: 35px;
  }
  .storePageBtnBlock {
    background-color: rgb(215 243 255);
    width: 35px;
    height: 35px;
  }
  .storePageBtnBlockDark {
    background-color: rgb(42 41 45);
    width: 35px;
    height: 35px;
  }
  .btn {
    height: 24px;
    width: 24px;
    margin-top: 5px;
    margin-left: 7px;
    transform: scale(1);
  }
  .img {
    display: inline-flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 26px;
    width: 26px;
    padding: 0.5rem;
    background-color: transparent;
    transition: background-color 50ms linear 0s, transform 50ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    transform: scale(1);
  }
  .storePageImg {
    display: inline-flex;
    flex-shrink: 0;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    height: 30px;
    width: 30px;
    padding: 0.5rem;
    background-color: transparent;
    transition: background-color 50ms linear 0s, transform 50ms cubic-bezier(0.4, 0, 0.2, 1) 0s;
    transform: scale(1);
  }
  .downDownContMain {
    height: 36px;
  }
  .cost {
    font-size: 0.8rem;
    line-height: 1.2rem;
    font-weight: 600;
  }
  .titleDownBlock {
    font-size: 1rem;
    line-height: 1.2rem;
    width: 100%;
    text-overflow: ellipsis;
    height: 40px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
  }
}

$fuschia: rgba(38, 38, 255, 0.3);
$button-bg: $fuschia;
$button-text-color: #fff;
$baby-blue: #f8faff;

.animated{
  font-family: 'Helvetica', 'Arial', sans-serif;
  display: inline-block;
  font-size: 1em;
  -webkit-appearance: none;
  appearance: none;
  background-color: $button-bg;
  color: $button-text-color;
  border-radius: 4px;
  border: none;
  cursor: pointer;
  transition: transform ease-in 0.1s, box-shadow ease-in 0.25s;
  box-shadow: 0 2px 25px rgba(135, 255, 95, 0.5);
  width: 100%;

  &:focus {
    outline: 0;
  }

  &:before, &:after{
    position: absolute;
    content: '';
    display: block;
    width: 140%;
    height: 100%;
    left: -20%;
    z-index: -1000;
    transition: all ease-in-out 0.5s;
    background-repeat: no-repeat;
  }

  &:before{
    display: none;
    top: -75%;
    background-image:
            radial-gradient(circle, $button-bg 20%, transparent 20%),
            radial-gradient(circle,  transparent 20%, $button-bg 20%, transparent 30%),
            radial-gradient(circle, $button-bg 20%, transparent 20%),
            radial-gradient(circle, $button-bg 20%, transparent 20%),
            radial-gradient(circle,  transparent 10%, $button-bg 15%, transparent 20%),
            radial-gradient(circle, $button-bg 20%, transparent 20%),
            radial-gradient(circle, $button-bg 20%, transparent 20%),
            radial-gradient(circle, $button-bg 20%, transparent 20%),
            radial-gradient(circle, $button-bg 20%, transparent 20%);
    background-size: 10% 10%, 20% 20%, 15% 15%, 20% 20%, 18% 18%, 10% 10%, 15% 15%, 10% 10%, 18% 18%;
    //background-position: 0% 80%, -5% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 85% 30%;
  }

  &:after{
    display: none;
    bottom: -75%;
    background-image:
            radial-gradient(circle, $button-bg 20%, transparent 20%),
            radial-gradient(circle, $button-bg 20%, transparent 20%),
            radial-gradient(circle,  transparent 10%, $button-bg 15%, transparent 20%),
            radial-gradient(circle, $button-bg 20%, transparent 20%),
            radial-gradient(circle, $button-bg 20%, transparent 20%),
            radial-gradient(circle, $button-bg 20%, transparent 20%),
            radial-gradient(circle, $button-bg 20%, transparent 20%);
    background-size: 15% 15%, 20% 20%, 18% 18%, 20% 20%, 15% 15%, 10% 10%, 20% 20%;
    //background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }

  &:active{
    transform: scale(0.9);
    background-color: darken($button-bg, 5%);
    box-shadow: 0 2px 25px rgba(255, 0, 130, 0.2);
  }

  &.animate{
    &:before{
      display: block;
      animation: topBubbles ease-in-out 0.75s forwards;
    }
    &:after{
      display: block;
      animation: bottomBubbles ease-in-out 0.75s forwards;
    }
  }
}

@keyframes topBubbles {
  0%{
    background-position: 5% 90%, 10% 90%, 10% 90%, 15% 90%, 25% 90%, 25% 90%, 40% 90%, 55% 90%, 70% 90%;
  }
  50% {
    background-position: 0% 80%, 0% 20%, 10% 40%, 20% 0%, 30% 30%, 22% 50%, 50% 50%, 65% 20%, 90% 30%;}
  100% {
    background-position: 0% 70%, 0% 10%, 10% 30%, 20% -10%, 30% 20%, 22% 40%, 50% 40%, 65% 10%, 90% 20%;
    background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
  }
}

@keyframes bottomBubbles {
  0%{
    background-position: 10% -10%, 30% 10%, 55% -10%, 70% -10%, 85% -10%, 70% -10%, 70% 0%;
  }
  50% {
    background-position: 0% 80%, 20% 80%, 45% 60%, 60% 100%, 75% 70%, 95% 60%, 105% 0%;}
  100% {
    background-position: 0% 90%, 20% 90%, 45% 70%, 60% 110%, 75% 80%, 95% 70%, 110% 10%;
    background-size: 0% 0%, 0% 0%,  0% 0%,  0% 0%,  0% 0%,  0% 0%;
  }
}
