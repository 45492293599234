@import "../../styles/variables.scss";
.fileInputWrapper {
  position: relative;
  display: inline-block;
  margin: 5px 0 15px 0;
}

.fileInput {
  opacity: 0;
  position: absolute;
  z-index: 1;
  cursor: pointer;

}

.fileInputLabel {
  padding: 5px 10px;
  color: var(--background-color);
  border-radius: 5px;
  cursor: pointer;
  background-color: $blue-light;
  font-size: 0.9rem;
}

.fileInputLabel:hover {
  background-color: #f0f0f0;
}
.block1 {
  overflow: auto;
}
.block2 {
  overflow: auto;
}

.button-container {
  display: flex;
  justify-content: space-around;
  grid-gap: 10px;
  margin-top: 20px;
  @media (min-width: 641px) {
    width: 40%;
    justify-content: flex-start;
  }
}

.add-button {
  border: none;
  position: relative;
  z-index: 0;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: 100%;
  height: 40px;
  max-width: 60%;
  cursor: pointer;
  padding: 0px 1rem;
  border-radius: 0.5rem;
  background: var(--blue-btn-background-color);
  justify-content: center;
  color: #FFFFFF;

}
.removeButton {
  border: none;
  position: relative;
  z-index: 0;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: 100%;
  height: 40px;
  max-width: 60%;
  cursor: pointer;
  padding: 0px 1rem;
  border-radius: 0.5rem;
  background: red;
  justify-content: center;
  color: #FFFFFF;

}
.block3 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  padding: 16px;
  border-bottom: 1px solid rgba(216, 211, 219, 0.52);
  background-color: var(--background-color);
  color: var(--text-color);
  font-size: 13px;
  font-weight: 500;
  line-height: 16px;
  box-sizing: border-box;
}
.block4 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
}
.block5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  background: var(--dark-grey-color);
  border-radius: 50%;
  margin-right: 16px;
  cursor: pointer;
  -webkit-transition: 0.3s;
  transition: 0.3s;
}
.block6 {
  position: relative;
  width: 24px;
  height: 24px;
  display: inline-block;
}
.block7 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  color: var(--text-color);
  font-size: 13px;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  line-height: 16px;
}
.block8 {

}
.block9 {
  width: 6px;
  height: 6px;
  border: 1px solid #666;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
  border-left-color: transparent;
  border-bottom-color: transparent;
  margin: 0 10px 0 9px;
}
.block0 {
  text-overflow: ellipsis;
  overflow: hidden;
}


.conteiner1 {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: auto auto;
  grid-gap: 24px;
  padding: 24px;
  @media (max-width: 640px) {
    display: flex;
  }
}
.conteiner2 {
  grid-column: 2 / 7;
  grid-row: 1 / 3;
  display: grid;
  grid-gap: 24px;
}
.conteiner3 {
  display: grid;
  grid-template-columns: 1fr 5fr;
  @media (max-width: 640px) {
    display: flex;
    flex-direction: column;
  }
}
.conteiner4 {
  margin-right: 20px;
  @media (max-width: 640px) {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin:  0 0 20px 0;
  }
}
.conteiner5 {
  position: relative;
  overflow: hidden;
  width: 143px;
  height: 143px;
  display: inline-block;
  box-sizing: border-box;
}
.conteiner6 {
  display: block;
  width: 100%;
  height: 100%;
  border-radius: 0.5rem;
  background: rgba(225, 221, 227, 0.3);
  background-size: cover;
  background-origin: border-box;
  background-position: center center;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
  overflow: hidden;
  opacity: 0.5;
}
.conteiner7 {
  border: none;
  outline: none;
  background-color: transparent;
  font-size: 16px;
  line-height: 24px;
  color: var(--grey-text-color);
  padding: 0;
  display: none;
}
.conteiner8 {
  height: auto;
  position: absolute;
  top: 5%;
  left: 5%;
  pointer-events: none;
  padding: 25%;
  border: 1px dashed #716e7a;
  border-radius: 0.5rem;
  -webkit-transition: border-color 0.3s;
  transition: border-color 0.3s;
  opacity: 0.7;
  opacity: 0.3;
  background-size: cover;
}
.conteiner9 {
  font-size: 12px;
  color: var(--grey-text-color);
  margin-top: 5px;
  line-height: 18px;
  width: 143px;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  text-align: center;
}
.preview {
  height: 200px;
  width: 100%;
  //background-color: rgba(225, 221, 227, 0.3);
  padding: 4px;
  border-radius: 4px;
  border: 0.5px solid $blue-light;
  @media (min-width: 641px) {
    height: 500px;
  }
}
.main1 {
  box-sizing: border-box;
}
.main2 {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-template-rows: repeat(5, auto);
  grid-gap: 16px;
  grid-template-areas:
        'name name name name'
        'category category category category'
        'description description description description'
        'productInfo productInfo productInfo productInfo'
        'unit tax vendorCode vendorCode';
}
.main3 {
  display: grid;
  grid-template-columns: 1fr;
  background-color: rgba(225, 221, 227, 0.3);
  border-radius: 0.5rem;
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  width: 100%;
  line-height: 24px;
  padding: 7px 12px;
  grid-column-gap: 1%;
  grid-area: name;
}
.main4 {
  position: relative;
  top: 70%;
  font-size: 16px;
  font-weight: normal;
  -webkit-transition: top 0.3s, font-size 0.3s;
  transition: top 0.3s, font-size 0.3s;
  color: var(--grey-text-color);
  letter-spacing: 0.2px;
  display: block;
  white-space: nowrap;
  grid-column: 1 / 2;
  grid-row: 1;
  line-height: 16px;
  top: 0;
  font-size: 12px;
  font-weight: 500;
}
.main5 {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-column: 1 / 3;
  grid-column: 1 / 2;
}
.main6 {
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  font-size: 16px;
  line-height: 18px;
  color: var(--grey-text-color);
  padding: 0;
  line-height: 24px;
}
.input {
  display: grid;
  grid-template-columns: 1fr;
  background-color: rgba(225, 221, 227, 0.3);
  border-radius: 0.5rem;
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  width: 100%;
  line-height: 24px;
  padding: 7px 12px;
  grid-column-gap: 1%;
}

.input1 {
  grid-area: name;
}
.input2 {
  grid-area: category;
  width: 100%;
}
.input3 {
  grid-area: unit;
  box-sizing: border-box;
}
.input4 {
  grid-area: tax;
  box-sizing: border-box;
}
.input5 {
  grid-area: vendorCode;
}
.input6 {
  grid-area: description;
}
.input7 {
  grid-area: productInfo;
}
.error {
  font-size: 10px;
  color: var(--error-color);
}

.btnBlock {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background: var(--background-color);
  margin-left: 25vw;
  @media (max-width: 640px) {
    margin: 0;
    justify-content: center;
  }
}
.btnMain {
  font-family: Roboto, sans-serif;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 16px;
  text-align: center;
  background-color: transparent;
  border: none;
  padding: 10px 16px;
  border-radius: 1rem;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  width: 30%;
  padding: 12px 16px;
  border-radius: 0.5rem;
  background: var(--blue-btn-background-color);
  color: var(--blue-btn-text-color);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.modalContainer {
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.modalContainerBlock {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 20;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  height: 100%;
  overflow-y: auto;
  background: rgba(86, 80, 89, 0.8);
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
}
.productsCategoryModal {
  z-index: 30;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 636px;
  border-radius: 1rem;
  overflow: hidden;
  max-width: 90%;
  max-height: 90%;
  margin: 0 auto;
  transform: scale(1);
}
.productsCategoryTextBlock {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: justify;
  -webkit-justify-content: space-between;
  -ms-flex-pack: justify;
  justify-content: space-between;
  background: var(--background-color);
  -webkit-box-shadow: 0 1px 0 0 rgba(216, 211, 219, 0.52);
  box-shadow: 0 1px 0 0 rgba(216, 211, 219, 0.52);
  color: var(--text-color);
  font-weight: 500;
  font-family: Roboto, sans-serif;
  padding: 18px 24px;
  font-size: 18px;
  letter-spacing: 0.2px;
  line-height: 22px;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  z-index: 1;
}
.productsCategoryTextImg {
  position: relative;
  width: 24px;
  height: 24px;
  display: inline-block;
  cursor: pointer;
}
.mainBlock {
  padding: 24px;
  overflow: auto;
  background: var(--background-color);
}
.mainConteiner {
  display: grid;
  grid-template-columns: 1fr;
  background-color: var(--dark-grey-color);
  border-radius: 0.5rem;
  -webkit-transition: -webkit-box-shadow 0.3s;
  transition: -webkit-box-shadow 0.3s;
  transition: box-shadow 0.3s;
  transition: box-shadow 0.3s, -webkit-box-shadow 0.3s;
  width: 100%;
  line-height: 24px;
  padding: 7px 12px;
  grid-column-gap: 1%;
  margin-bottom: 12px;
  box-sizing: border-box;
}

.mainConteiner>div {
  margin-bottom: 10px;
}
.mainBlockLabel {
  position: relative;
  -webkit-transition: top 0.3s, font-size 0.3s;
  transition: top 0.3s, font-size 0.3s;
  color: var(--text-color);
  letter-spacing: 0.2px;
  display: block;
  white-space: nowrap;
  grid-column: 1 / 2;
  grid-row: 1;
  line-height: 16px;
  top: 0;
  font-size: 14px;
  font-weight: 500;
}
.mainBlockDown {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  grid-column: 1 / 3;
  grid-column: 1 / 2;
  &__image {
    display: flex;
    flex-direction: column;
  }
}
.mainBlockDown__image >input {
  display: none;
}

.mainBlockDownInput {
  border: none;
  outline: none;
  background-color: transparent;
  width: 100%;
  font-size: 16px;
  line-height: 18px;
  color: var(--text-color);
  padding: 0;
  line-height: 24px;
}
.mainBlockTwo {
  position: relative;
  width: 100%;
  height: 52px;
}
.mainConteinerTwo {
  position: relative;
  box-sizing: border-box;
  width: 100%;
  z-index: auto;
}
.mainConteinerTwoMid {
  -webkit-box-align: center;
  align-items: center;
  background-color: rgba(225, 221, 227, 0.3);
  border-radius: 4px;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  -webkit-box-pack: justify;
  justify-content: space-between;
  min-height: 52px;
  position: relative;
  transition: all 100ms ease 0s;
  box-sizing: border-box;
  border: none;
  overflow: hidden;
  outline: 0px !important;
}
.mainConteinerTwoMidT {
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  padding: 4px 0px 4px 12px;
  height: 100%;
  width: 100%;
}
.mainConteinerTwoTextBlock {
  position: relative;
  width: auto;
  height: auto;
  opacity: 0.5;
  top: auto;
  -webkit-transform: none;
  -ms-transform: none;
  transform: none;
}
.mainConteinerTwoText {
  font-size: 13px;
  line-height: 16px;
  color: #716e7a;
  font-weight: 500;
  letter-spacing: 0.2px;
  margin-right: 8px;
  display: block;
  font-size: 12px;
  color: #716e7a;
  letter-spacing: 0.2px;
  line-height: 16px;
  white-space: nowrap;
}
.mainConteinerTwoInput {
  background: 0px center;
  border: 0px;
  font-size: inherit;
  outline: 0px;
  padding: 0px;
  width: 1px;
  color: transparent;
  left: -100px;
  opacity: 0;
  position: relative;
  transform: scale(0);
}
.mainConteinerTwoBlockDown {
  -webkit-box-align: center;
  align-items: center;
  align-self: stretch;
  display: flex;
  flex-shrink: 0;
  box-sizing: border-box;
}
.mainConteinerTwoSpan {
  align-self: stretch;
  background-color: rgb(204, 204, 204);
  margin-bottom: 8px;
  margin-top: 8px;
  width: 1px;
  box-sizing: border-box;
  display: none;
}
.mainConteinerTwoDiv {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  height: 24px;
  width: 24px;
  margin-right: 8px;
  position: absolute;
  top: 50%;
  right: 8px;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  margin: 0;
}

.mainConteinerDown {
  margin-top: 24px;
  display: grid;
  grid-template-columns: auto auto;
  -webkit-box-align: start;
  -webkit-align-items: start;
  -ms-flex-align: start;
  align-items: start;
}
.mainBlockDownQ {
  -webkit-align-self: flex-start;
  -ms-flex-item-align: start;
  align-self: flex-start;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
  color: #716e7a;
  margin-top: 10px;
}
.mainBlockDownW {
  display: inline-block;
  position: relative;
  left: 0;
}
.mainBlockDownE {
  height: 100%;
}
.mainBlockDownR {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border-radius: 50%;
  cursor: pointer;
  margin-right: 16px;
  margin-bottom: 16px;
  margin: 0 0 0 12px;
  background-color: rgb(255, 91, 20);
}
.btnBlock {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  background: #FFFFFF;
}
.btnMain {
  font-family: Roboto, sans-serif;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: center;
  -webkit-justify-content: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 500;
  letter-spacing: 0.2px;
  line-height: 16px;
  text-align: center;
  background-color: transparent;
  border: none;
  padding: 10px 16px;
  border-radius: 4px;
  color: #0E1F40;
  -webkit-transition: all 0.3s ease;
  transition: all 0.3s ease;
  cursor: pointer;
  width: 100%;
  padding: 12px 16px;
  border-radius: 0;
  background: var(--blue-btn-background-color);
  color: var(--blue-btn-text-color);
  font-family: Roboto, sans-serif;
  font-size: 16px;
  line-height: 24px;
}

.mainBlockOne {
  //background-color: rgba(225, 221, 227, 0.3);
  padding: 4px;
  border-radius: 4px;
  border: 0.5px solid $blue-light;
  margin-bottom: 1rem;
  &__focus {
    //background-color: rgba(225, 221, 227, 0.3);
    padding: 4px;
    border-radius: 4px;
    border: 2px solid $blue-light;
  }
}

.error {
  color: red;
  font-size: 12px;
}
.addButton {
  border: none;
  position: relative;
  z-index: 0;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: 50px;
  height: 50px;

  cursor: pointer;
  padding: 0px 1rem;
  border-radius: 0.5rem;
  background: var(--blue-btn-background-color);
  justify-content: center;
  color: #FFFFFF;

}
.removeButton {
  border: none;
  position: relative;
  z-index: 0;
  user-select: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: 50px;
  height: 50px;

  cursor: pointer;
  padding: 0px 1rem;
  border-radius: 0.5rem;
  background: red;
  justify-content: center;
  color: #FFFFFF;
}