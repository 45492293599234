.buttonLogin {
  height: 46px;
  width: 60px;
  background: 0;
  color: #fff;
  border: 0;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Segoe UI, Arimo, Open Sans, sans-serif;
  font-size: .87rem;
  font-stretch: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  text-rendering: optimizelegibility;
  cursor: pointer;
  margin-right: 20px;
}

.buttonSignup {
  height: 46px;
  width: 80px;
  background: 0;
  background-color: rgba(0, 178, 255, 0.10);
  color: #0097e5;
  border: 0;
  padding: 0 10px;
  border-radius: .5rem;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Segoe UI, Arimo, Open Sans, sans-serif;
  font-size: .9rem;
  font-stretch: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  text-rendering: optimizelegibility;
  cursor: pointer;
}
.buttonSignup:hover {
  background-color: rgba(0, 178, 255, 0.15);
}

.buttonLoginUp {
  height: 46px;
  width: 60px;
  background: 0;
  color: white;
  border: 0;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Segoe UI, Arimo, Open Sans, sans-serif;
  font-size: .87rem;
  font-stretch: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  text-rendering: optimizelegibility;
  cursor: pointer;
  margin-right: 20px;
}

.buttonLoginUp:hover {
  color: #eaeaea;
}

.buttonSignupUP {
  height: 46px;
  width: 80px;
  background: 0;
  background-color: #1b5e3b;
  color: white;
  border: 0;
  padding: 0 10px;
  border-radius: .5rem;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, Segoe UI, Arimo, Open Sans, sans-serif;
  font-size: 1rem;
  font-stretch: 100%;
  font-style: normal;
  font-weight: 500;
  line-height: 1.25rem;
  text-rendering: optimizelegibility;
  cursor: pointer;
}

.buttonSignupUP:hover {
  background-color: #1b5e3b;
}
