.mainPage {
  display: block;
  unicode-bidi: isolate;
  flex: 1 1 0;
  z-index: 0;
  margin-top: 0;
}

.mapBlock {
  width: 100%;
  transform: translateY(5px);
  box-shadow: inset 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
  z-index: 99999;
}
.shadowBlock {
  width: 100%;
  box-shadow: inset 0px 10px 20px 2px rgba(0, 0, 0, 0.25);
  box-shadow: 5px 0 0 0 rgba(255, 255, 255, 0.25);
  height: 15.5rem;
  z-index: 999999;
  position: absolute;
}

.mapBlock .ymaps-2-1-79-map-copyrights-promo{
  display: none;
}

.tabsContainer {
  border-radius: 12.5rem;
  width: auto;
  background-color: rgba(32, 33, 37, 0.08);
  display: flex;
  justify-content: center;
  padding: 0.25rem;
  height: 3rem;
  position: relative;
  z-index: 22;
  margin-top: 1rem;
  @media screen and (max-width: 640px) {
    height: 2.25rem;
    padding: 0.1rem;
  }
}

.tabs {
  display: flex;
  height: 2rem;
  width: 100%;
  justify-content: space-between;
}
.tabButton {
  width: 254px;
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 10px;
  font-size: 16px;

  @media (max-width: 640px) {
    width: 50%;
    padding: 5px;
    text-decoration: none;
  }
}

.tabButton:hover {
  text-decoration: underline;
  @media (max-width: 640px) {
    text-decoration: none;
  }
}

.active {
  background-color: #fff;
  font-weight: bold;
  border-radius: 12.5rem;
  width: 100%;
}

@media screen and (max-width: 1023px) {
  .downBlock {
    display: block;
  }
}
.backer {
  display: none;
}
@media screen and (max-width: 640px) {
  .backer {
    position: fixed;
    width: 100%;
    height: 3rem;
    z-index: 9999999;
    background-color: var(--background-color);
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 1rem;

  }
}

.shopName {
  display: flex;
  font-size: 1.8rem;
  align-items: center;
  color: var(--order-text-color);
  font-weight: 500;
  margin-bottom: 1rem;
}

.title {
  font-size: 1.2rem;
  color: var(--order-text-color);
  font-weight: 400;
  margin-bottom: 0.3rem;
}
.date {
  color: var(--order-grey-text-color);
  margin-bottom: 1rem;
}


.iBlock {
  height: 40px;
  width: 40px;
  border-radius: 4rem;
  background-color: rgba(112, 113, 116, 0.2);
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.3rem;
  margin-top: 5px;
  font-weight: bold;
}

.mainConteiner {
  width: 100%;
  padding: 0 1rem;
  box-sizing: border-box;
}
.mainBoxBlock {
  margin-bottom: 2rem;
}
.imagesBlock {
  display: flex;
  height: 3rem;
  margin-top: 0.5rem;
  justify-content: space-between;
}
.rowBlock {
  display: flex;
  height: 3rem;
  align-items: center;
}
.svgImage {
  height: 30px;
  width: 30px;
  color: #1b5e3b;
  fill: #1b5e3b;
}
.leftBlock {
  display: flex;
}
.textBlockImage {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
}
.topText {
  font-size: 0.85rem;
  font-weight: bold;
}
.downText {
  font-size: 0.7rem;
  color: #707174;
}
.IconNextBlock {
  height: 100%;
  width: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.IconNext {
  rotate: (270deg);
  margin-top: 4px;
}

.img-blocks {
  display: block;
  margin: 0 auto;
  height: 3rem;
}

.imgBlocksSearch {
  display: block;
  height: 3rem;
  margin-top: 1rem;
}

.imgBlocksSearchPhone {
  display: block;
  height: 3rem;
  margin-top: 1rem;
}

.searchForm {
  position: relative;
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
  border: rgba(112, 113, 116, 0.3) 1px solid;
  background-color: rgba(188, 188, 188, 0.38);
  background-image: url("https://static-00.iconduck.com/assets.00/message-icon-512x463-tqzmxrt7.png");
  background-repeat: no-repeat;
  background-size: 30px;
  opacity: 0.65;
  text-indent: 50px;
  background-position: 10px 10px;
  outline: none;
}

.searchFormPhone {
  position: relative;
  width: 100%;
  height: 3rem;
  border-radius: 0.5rem;
  border: rgba(112, 113, 116, 0.3) 1px solid;
  background-color: rgba(188, 188, 188, 0.38);
  background-image: url("https://uxwing.com/wp-content/themes/uxwing/download/communication-chat-call/phone-call-icon.png");
  background-repeat: no-repeat;
  background-size: 25px;
  opacity: 0.65;
  text-indent: 50px;
  background-position: 10px 10px;
  outline: none;
  outline: none;
}

.paymentBlock {
  margin-top: 1.5rem;
}
.titlePayment {
  font-size: 1.3rem;
  font-weight: bold;
}

.paymentBtn {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  border: 1px solid rgba(169, 169, 169, 0.55);
  border-radius: 0.3rem;
  padding: 1rem 0.6rem;
  margin-top: 1rem;
}
.promoBtn {
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: space-between;
  padding: 1rem 0.6rem;
  margin-top: 0.5rem;
  padding-left: 0;
}
.customSvg {
  color: white;
  background-color: #1b5e3b;
  height: 1.1rem;
  width: 1.5rem;
  font-weight: bold;
  border-radius: 3px;
}
.mailLeftBlBtn {
  display: flex;
}
.paymentBtnLeftBLock {
  display: flex;
  justify-content: center;
  margin-top: 0;
  margin-inline-end: 1rem;
  margin-inline-start: 0;
  position: relative;
  vertical-align: middle;
  width: 24px;
}
.paymentBtnLeftImg {
  fill: #202125;
  height: 24px;
  margin-top: 3px;
  width: 24px;
}
.paymentBtnMiddle {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  min-width: 0;
}
.paymentBtnMiddleLeft {
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 0.9rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 600;
}
.paymentBtnMiddleRight {
  font-size: 0.75rem;
  opacity: 0.5;
}
.paymentBtnRight {
  align-self: center;
  display: flex;
  padding: 0;
  color: rgba(112, 113, 115, 0.38);
  font-size: .8125rem;
  text-align: right;
}
.paymentBtnRightImg {
  color: #202125;
  height: 24px;
  transform: rotate(0deg);
  transition: transform .15s ease-out;
  width: 24px;
}

.priceInBlock {
  margin-top: 0.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}
.priceInUp {
  font-size: 1.3rem;
  font-weight: bold;
}
.priceInDown {
  font-size: 0.9rem;
  opacity: 0.5;
}
.titleFees {
  color: #1b5e3b;
  font-weight: 500;
}
.upMiddleBlock {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0.4rem 0;
}

.upMiddleBlockText {
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 500;
  display: block;
  margin: 0;
  padding: 0 0 0.3125rem;
}
.costList {
  margin-top: 1rem;
  margin-bottom: 1rem;
}
.excludedTitle {
  font-size: 0.75rem;
  opacity: 0.5;
}

.upMiddleBlockDown {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-top: 0.4rem;
}

.excludedDeliveryBlock {
  padding-top: 0.6rem;
}

.footerBtn {
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  cursor: pointer;
  min-height: 3.375rem;
  border-radius: 0.5rem;
  width: 48%;
  color: #fff;
  bottom: 1rem;
  box-sizing: border-box;
  background-color: #1b5e3b;
}
.test {
  background-color: #1b5e3b;

}

.footerBtnCont {
  transition: background-color 120ms ease-out 0s;
  background: #1b5e3b;
  position: absolute;
  inset: 0;
  border-radius: 0.5rem;
  box-sizing: border-box;
}

.fakeDisc {
  opacity: 0.5;
  margin-right: 1rem;
  text-decoration: line-through;
}

.footerBtnBlock {
  position: absolute;
  inset: 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
}

.footerBtnMain {
  flex: 1 1 0;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  transition: opacity 150ms ease-in 0s, color 150ms linear 0s;
  border-radius: 0.5rem;
  overflow: hidden;
  text-overflow: ellipsis;
  overflow-wrap: break-word;
  box-sizing: border-box;
}
.fakeDownBlock {
  height: 4rem;
}

.good {
  background-color: #23d600;
}
.error {
  background-color: #FF4D4F;
}

.btnBlocks {
  position: fixed;
  border: none;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  font-feature-settings: "kern";
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizelegibility;
  font-family: -apple-system, BlinkMacSystemFont, Roboto, "Segoe UI", Arimo, "Open Sans", "Helvetica Neue", sans-serif;
  font-size: 1rem;
  line-height: 1.5rem;
  font-style: normal;
  font-stretch: normal;
  text-transform: none;
  font-weight: 700;
  width: 92%;
  cursor: pointer;
  min-height: 3.375rem;
  border-radius: 0.5rem;
  bottom: 1rem;
  box-sizing: border-box;
  margin-bottom: 3.5rem;
}

.buttonReviews {
  width:100%;
  height: 3rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  background-color: var(--blue-btn-background-color);
  color: var(--blue-btn-text-color);
}

.order {
  list-style-type: none;
  display: grid;
  grid-template-columns: 3fr 2fr 1fr 0.5fr;
  color: var(--order-grey-text-color);
}

.description {
  margin-bottom: 1rem;
  display: flex;
  grid-gap: 0.8rem;
  display: flex;
  align-items: center;
}
.value {
  color: var(--order-grey-text-color);
  font-size: 1.1rem;
}

.payment-type {
  display: flex;
  justify-content: space-between;
}
.valueStatus {
  font-weight: bold;
  font-size: 1.6rem;
}
.btnActive {
  width: 100%;
  height: 3rem;
  background-color: #1b5e3b;
  color: white;
  border-radius: 1rem;
  font-size: 1rem;
  font-weight: bold;
  margin-bottom: 1rem;
}
